import { differenceInMonths, differenceInYears } from 'date-fns';

import { WorkExperience } from 'src/types/domain';

export interface GetExperienceDuration {
  startYearMonth: WorkExperience['start'];
  endYearMonth: WorkExperience['end'];
  isCurrentEmployer: WorkExperience['isCurrent'];
}

export const getExperienceDuration = ({
  startYearMonth,
  endYearMonth,
  isCurrentEmployer,
}: GetExperienceDuration): string => {
  try {
    const startDate = new Date(+startYearMonth.split('-')[0], +startYearMonth.split('-')[1] - 1);
    const endDate = new Date(+endYearMonth.split('-')[0], +endYearMonth.split('-')[1] - 1);

    if (
      !startYearMonth ||
      (!endYearMonth && !isCurrentEmployer) ||
      startDate > endDate ||
      startDate > new Date() ||
      endDate > new Date()
    ) {
      throw Error();
    }

    let years = 0;
    let months = 0;

    if (!isCurrentEmployer) {
      years = differenceInYears(endDate, startDate);
      months = differenceInMonths(endDate, startDate) - differenceInYears(endDate, startDate) * 12;
    } else {
      years = differenceInYears(Date.now(), startDate);
      months =
        differenceInMonths(Date.now(), startDate) - differenceInYears(Date.now(), startDate) * 12;
    }

    const yearsText = years ? years + ' yr' : '';
    const monthsText = months ? months + ' mo' : '';
    const spacerText = yearsText && monthsText ? ' ' : '';
    return yearsText + spacerText + monthsText;
  } catch (err) {
    return '';
  }
};
