import { Auth0DecodedHash } from 'auth0-js';
import Cookies from 'js-cookie';

export const setSession = (authResult: Auth0DecodedHash): void => {
  if (authResult.expiresIn) {
    const timeNow = new Date().getTime();
    const expires = {
      at: JSON.stringify(authResult.expiresIn * 1000 + timeNow),
      created: JSON.stringify(timeNow),
    };
    localStorage.setItem('expires_at', expires.at);
    localStorage.setItem('expires_at_created_at', expires.created);
  }

  if (authResult.accessToken) {
    localStorage.setItem('access_token', authResult.accessToken);
    Cookies.set('access_token', authResult.accessToken);
  }

  if (authResult.idToken) {
    localStorage.setItem('id_token', authResult.idToken);
  }

  if (authResult.idTokenPayload) {
    localStorage.setItem('name', authResult.idTokenPayload.name);
    localStorage.setItem('email', authResult.idTokenPayload.email);
  }
};
