import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { TalentStatusCurrentlyUsed } from 'src/types/domain';
import { formatDateDistance } from 'src/utils/functions/formatDateDistance';

import { defaultStatus, statusMapper } from './utils/statusMapper';

import classes from './Status.module.css';

export interface StatusProps {
  status: TalentStatusCurrentlyUsed | null;
  ts: number | null;
}

export const Status = ({ status, ts }: StatusProps): JSX.Element => {
  let when: string;

  if (ts) {
    when = formatDateDistance(new Date(ts));
  } else {
    when = 'Uknown';
  }

  const title = (status && statusMapper[status].title) || defaultStatus.title;
  const text = (status && statusMapper[status].text) || defaultStatus.text;
  const color = (status && statusMapper[status].color) || defaultStatus.color;

  return (
    <Tooltip title={`${title} ${when}`} arrow placement='top'>
      <div className={clsx(classes.container, color)} data-testid='text-container'>
        <Text style={{ padding: '1px 0' }} text={text} typographyType={TextTypographyType.BODY_8} />
      </div>
    </Tooltip>
  );
};
