import { ThunkDispatch } from '@reduxjs/toolkit';
import { ClientError, GraphQLClient } from 'graphql-request';
import { push } from 'redux-first-history';

export const graphqlBaseQuery =
  ({ baseUrl }: { baseUrl: string }) =>
  async (
    { document, variables }: { document: string; variables: any },
    api: { dispatch: ThunkDispatch<any, any, any> }
  ) => {
    try {
      const token = localStorage.getItem('access_token');
      const requestHeaders = {
        authorization: 'Bearer ' + token,
      };

      const graphQLClient = new GraphQLClient(baseUrl, {
        fetch,
        headers: requestHeaders,
      });

      const data = await graphQLClient.rawRequest(document, variables);

      return { data };
    } catch (error) {
      if (error instanceof ClientError) {
        window.DD_RUM && window.DD_RUM.addError(error);

        if (error.response?.status === 401) {
          api.dispatch(push('/401'));
        }

        return { error: { data: error, status: error.response.status } };
      }

      window.DD_RUM && window.DD_RUM.addError(error);
      return { error: { data: error, status: 500 } };
    }
  };
