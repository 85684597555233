import { PayloadAction } from '@reduxjs/toolkit';

import { TalentModalState, TalentPromotion } from '../../../types/types';

export const setActionTypeAction = (
  state: TalentModalState,
  action: PayloadAction<TalentPromotion>
) => {
  state.action.type = action.payload;
};
