import { FC } from 'react';
import Typography from '@mui/material/Typography';

export interface BooleanProps {
  label: string;
  values: string[];
}

export const Boolean: FC<BooleanProps> = ({ label, values }) => {
  const formattedValues = values.map(
    (value, index) => `${value}${index < values.length - 1 ? ', ' : ''}`
  );

  return (
    <>
      <Typography variant='body7'>{label}:</Typography>{' '}
      <Typography variant='smallt1'>{formattedValues}</Typography>
    </>
  );
};
