import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { extendedGraphqlApiWithArchivedJobs } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { extendedGraphqlApiWithLiveJobs } from 'src/store/api/graphql/jobs/getLiveJobs';
import { MenuType } from 'src/store/types/types';
import { Job } from 'src/types/jobs';

export interface UpdateJob {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  jobId: number;
  jobType: MenuType;
}

const updateJobCallback = (data: Job[], jobId: number) => {
  if (!data) {
    return;
  }

  const jobs = [...data];
  const indexOfJob = jobs.findIndex(job => job.id === jobId);

  if (indexOfJob === -1) {
    return jobs;
  }

  const newNumberOfCandidates = jobs[indexOfJob].numberOfCandidates || 0;
  jobs[indexOfJob] = {
    ...jobs[indexOfJob],
    numberOfCandidates: newNumberOfCandidates > 1 ? newNumberOfCandidates - 1 : 0,
  };
  return jobs;
};

export const updateJob = ({ dispatch, jobId, jobType }: UpdateJob) => {
  if (jobType === MenuType.CLOSED) {
    return dispatch(
      extendedGraphqlApiWithArchivedJobs.util.updateQueryData(
        'getArchivedJobs',
        undefined,
        (data: Job[]) => updateJobCallback(data, jobId)
      )
    );
  }

  return dispatch(
    extendedGraphqlApiWithLiveJobs.util.updateQueryData('getLiveJobs', undefined, data =>
      updateJobCallback(data, jobId)
    )
  );
};
