import { switchClasses } from '@mui/material/Switch';

import { black, gray50, green30 } from '../colors/colors';

export const MuiSwitch = {
  styleOverrides: {
    root: {
      [`.${switchClasses.track}`]: {
        backgroundColor: black,
      },
      [`.${switchClasses.thumb}`]: {
        backgroundColor: gray50,
      },
      [`.${switchClasses.switchBase}.${switchClasses.checked}+.${switchClasses.track}`]: {
        backgroundColor: green30,
      },
      [`.${switchClasses.checked} .${switchClasses.thumb}`]: {
        backgroundColor: green30,
      },
    },
  },
};
