import { useSearchParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { ExportIcon } from 'src/assets/ExportIcon';
import { useAppSelector } from 'src/store/hooks/hooks';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { exportUrlCreator } from './utils/exportUrlCreator';

import classes from './ExportList.module.css';

export const ExportList = () => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);
  const [searchParams] = useSearchParams();

  if (!selectedJobId) {
    return null;
  }

  return (
    <Tooltip title={`Export list`} arrow placement='bottom'>
      <a
        href={exportUrlCreator(
          parseSearchParamsFilter(selectedJobId && +selectedJobId, searchParams)
        )}
        download
      >
        <IconButton size='medium' className={classes.export_icon} disableRipple>
          <ExportIcon />
        </IconButton>
      </a>
    </Tooltip>
  );
};
