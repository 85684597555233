import { FC, useMemo } from 'react';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { RejectReason, RejectReasonLabel } from 'src/types/domain';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

import { RejectOrAdvanceOrInterviewedTitleWrapper } from '../../styled';

interface RejectedTextProps {
  rejectedReason: RejectReason | 'OTHER';
  rejectedBy?: {
    firstName?: string;
    lastName?: string;
  };
  rejectionDate?: Date;
}

export const RejectedText: FC<RejectedTextProps> = ({
  rejectedReason,
  rejectedBy,
  rejectionDate,
}) => {
  const doesNotHaveDefaultReason = rejectedReason !== 'OTHER';

  const rejectedOnElement = useMemo(() => {
    if (rejectionDate) {
      return (
        <>
          <Text
            typographyType={TextTypographyType.BODY_1}
            color={'var(--seal-blue)'}
            text={'on'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Text
            typographyType={TextTypographyType.BODY_4}
            color={'var(--seal-blue)'}
            text={formatDateTypeO(rejectionDate)}
          />
        </>
      );
    }
  }, [rejectionDate]);

  const rejectedDueToElement = useMemo(() => {
    if (doesNotHaveDefaultReason) {
      return (
        <>
          <Text
            typographyType={TextTypographyType.BODY_1}
            color={'var(--seal-blue)'}
            text={'due to'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Text
            typographyType={TextTypographyType.BODY_4}
            color={'var(--seal-blue)'}
            text={RejectReasonLabel[rejectedReason]}
          />
        </>
      );
    }
  }, [doesNotHaveDefaultReason, rejectedReason]);

  const rejectedByElement = useMemo(() => {
    if (rejectedBy?.lastName || rejectedBy?.firstName) {
      return (
        <>
          <Text
            typographyType={TextTypographyType.BODY_1}
            color={'var(--seal-blue)'}
            text={'by'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Text
            typographyType={TextTypographyType.BODY_4}
            color={'var(--seal-blue)'}
            text={`${rejectedBy.firstName} ${rejectedBy.lastName}`}
          />
        </>
      );
    }
  }, [rejectedBy]);

  return (
    <RejectOrAdvanceOrInterviewedTitleWrapper>
      <Text typographyType={TextTypographyType.BODY_2} color={'var(--rust)'} text={'Rejected'} />
      <>
        {rejectedOnElement}

        {rejectedDueToElement}

        {rejectedByElement}
      </>
    </RejectOrAdvanceOrInterviewedTitleWrapper>
  );
};
