import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { ReactComponent as Meh } from 'src/assets/Meh.svg';
import { Spinner } from 'src/shared/components';

import { TextErrorWrapper } from '../error/TextErrorWrapper';

import classes from './LoadingPage.module.css';

interface LoadingPageProps {
  hasError: boolean;
  retryCallback?: () => void;
  textColor?: string;
}

export const LoadingPage: FC<LoadingPageProps> = ({ hasError, retryCallback, textColor }) => (
  <div
    className={classes.container}
    onClick={hasError ? retryCallback : undefined}
    style={{ cursor: hasError && retryCallback ? 'pointer' : 'auto' }}
  >
    {hasError && <Meh />}
    <div className={classes.text} data-testid='loading-page'>
      {hasError ? (
        <TextErrorWrapper>
          <Typography variant='subHeading1' sx={{ color: textColor }}>
            Something went wrong
          </Typography>
          <Typography variant='body1' align={'center'} sx={{ color: textColor }}>
            Please {retryCallback && 'click to '}try again
          </Typography>
        </TextErrorWrapper>
      ) : (
        <Spinner />
      )}
    </div>
  </div>
);
