import React, { FC } from 'react';

import { ReactComponent as Smile } from 'src/assets/Smile.svg';

import classes from './EmptyList.module.css';
export const EmptyList: FC = () => (
  <div className={classes.container}>
    <Smile />
    <div className={classes.text}>Basic information for this talent will be shown here</div>
  </div>
);
