import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import App from 'src/App';
import { AuthProvider } from 'src/contexts/AuthContext';
import classes from 'src/index.module.css';
import { renderWithFeatureFlags } from 'src/services/featureFlags/render/renderWithFeatureFlags';
import { SnackbarContent } from 'src/shared/components/SnackbarContent/SnackbarContent';
import { GlobalStyles } from 'src/shared/components/theme/mui/global/styles';
import { theme } from 'src/shared/components/theme/mui/theme';
import { history, store } from 'src/store/store';
import { ErrorFallback } from 'src/views/ErrorFallback/ErrorFallback';

import 'src/styles/styles.less';

import 'normalize.css';

export interface GtpParams {
  id: string;
  environment: {
    gtm_auth: string;
    gtm_preview: string;
  };
  dataLayer: {
    dataDogRumVersion: string;
  };
}

const gtmParams = {
  dataLayer: {
    dataDogRumVersion: process.env.VITE_DATA_DOG_VERSION,
  },
  environment: {
    gtm_auth: process.env.VITE_GTM_AUTH,
    gtm_preview: process.env.VITE_GTM_PREVIEW,
  },
  id: process.env.VITE_GTM_ID,
} as GtpParams;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

renderWithFeatureFlags({
  root,
  children: (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <AuthProvider>
          <Router history={history}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <GTMProvider state={gtmParams}>
                <SnackbarProvider
                  classes={{
                    anchorOriginTopRight: classes.SnackbarTopRight,
                  }}
                  disableWindowBlurListener={false}
                  content={(key, message) => SnackbarContent({ message, snackbarKey: key })}
                >
                  <App />
                </SnackbarProvider>
              </GTMProvider>
            </ErrorBoundary>
          </Router>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  ),
});
