import { FC } from 'react';

import { InformationContainer } from './styled';
interface InformationProps {
  name: {
    firstName: string;
    lastName: string;
  };
}

export const Information: FC<InformationProps> = ({ name }) => (
  <InformationContainer>
    {`We're waiting for ${name.firstName} ${name.lastName} to respond to our chat
      invite.`}
  </InformationContainer>
);
