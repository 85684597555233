import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { trackAction } from 'src/services/tracking';
import { extendedGraphqlApiWithLiveJobs } from 'src/store/api/graphql/jobs/getLiveJobs';
import {
  extendedGraphqlApiWithGetTalents,
  GetTalentsVariables,
} from 'src/store/api/graphql/talents/getTalents';
import { talentPromotionErrorNotification } from 'src/store/api/graphql/talents/utils/talentPromotionErrorNotification';
import { updateCandidateDetails } from 'src/store/api/graphql/talents/utils/updateCandidateDetails';
import { updateJob } from 'src/store/api/graphql/talents/utils/updateJob';
import { updateTalentList } from 'src/store/api/graphql/talents/utils/updateTalentList';
import { resetModal } from 'src/store/slices/talentModal/talentModal';
import { RootState } from 'src/store/store';
import { MenuType } from 'src/store/types/types';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { RecruiterInfo } from '../../../../../types/domain';

export const optimisticTalentPromotion = async (
  operation: 'advance' | 'reject',
  { candidateId, jobId, reason }: any,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: Promise<any>,
  state: RootState
) => {
  const searchParams = new URLSearchParams(state.router.location?.search);

  const endpointSelectParams: GetTalentsVariables = parseSearchParamsFilter(jobId, searchParams);
  const talentsResponse =
    extendedGraphqlApiWithGetTalents.endpoints.getTalents.select(endpointSelectParams)(state).data;

  const indexOfOldItem = talentsResponse?.items.findIndex(item => item.id === candidateId);

  const liveJobs =
    extendedGraphqlApiWithLiveJobs.endpoints.getLiveJobs.select(undefined)(state).data;

  const optimisticUpdateJob = updateJob({
    dispatch,
    jobId,
    jobType: liveJobs?.find(item => item.id === jobId) ? MenuType.OPEN : MenuType.CLOSED,
  });
  const optimisticUpdateTalentList = updateTalentList({
    dispatch,
    indexOfOldItem,
    newStatus: operation === 'advance' ? 'OTHER_ADVANCED' : 'OTHER_REJECTED',
    onHold: false,
    selectParams: endpointSelectParams,
  });

  const optimisticUpdateCandidateDetails = updateCandidateDetails({
    candidateId,
    dispatch,
    ...(operation === 'advance'
      ? { advancementReason: reason, onHold: false }
      : { onHold: false, rejectionReason: reason }),
  });
  dispatch(resetModal());

  try {
    const data = await queryFulfilled;

    let opportunity: Partial<RecruiterInfo> = {};

    if (operation === 'reject') {
      opportunity = data.data.data.rejectOpportunity;
    }

    if (operation === 'advance') {
      opportunity = data.data.data.advanceOpportunity;
    }

    const recruiterInfo = {
      firstName: opportunity.firstName,
      lastName: opportunity.lastName,
    };

    updateCandidateDetails({
      candidateId,
      dispatch,
      newStatus: [
        {
          created: new Date() + '',
          recruiterInfo,
        },
      ],
    });

    trackAction(`candicacy_${operation}`, {
      candidateId,
      reason,
    });
  } catch (error: any) {
    optimisticUpdateJob.undo();
    optimisticUpdateTalentList.undo();
    optimisticUpdateCandidateDetails.undo();
    const hasConflictError = Boolean(
      error?.error?.data?.response?.errors.find((error: any) => error.message === 'CONFLICT')
    );
    const operationName = operation === 'advance' ? 'Advance' : 'Reject';

    const userInfo =
      talentsResponse && indexOfOldItem !== undefined && indexOfOldItem > -1
        ? {
            firstName: talentsResponse.items[indexOfOldItem].firstName,
            lastName: talentsResponse.items[indexOfOldItem].lastName,
          }
        : undefined;
    talentPromotionErrorNotification({ dispatch, hasConflictError, operationName, userInfo });
  }
};
