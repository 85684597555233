import { URLSearchParamsInit } from 'react-router-dom';

import { FilterLabel } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { filteredFilterMenuConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/utils/filterMenuConfiguration';
import { configToSearchParamsObj } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/utils/configToSearchParamsObj';
import { searchParamsToObject } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/utils/searchParamsToObject';
import { IndexedDb } from 'src/store/indexeddb/IndexedDb';

interface SubmitHandler {
  searchParams: URLSearchParams;
  setSearchParams: (obj: URLSearchParamsInit) => void;
  filterLabel: FilterLabel;
  state: string[];
  onClose: () => void;
  hasChat: boolean | undefined;
  selectedJobId: number | null;
  indexedDb: IndexedDb;
}

export const submitHandler = ({
  searchParams,
  setSearchParams,
  filterLabel,
  state,
  onClose,
  hasChat,
  selectedJobId,
  indexedDb,
}: SubmitHandler) => {
  if (searchParams.toString().length === 0) {
    const newSearchParamsState = {
      ...configToSearchParamsObj(filteredFilterMenuConfiguration(hasChat)),
      [filterLabel]: state,
    };
    setSearchParams(newSearchParamsState);

    if (selectedJobId) {
      indexedDb.put(selectedJobId, newSearchParamsState);
    }
  } else {
    const updatedSearchParamsState = {
      ...searchParamsToObject(searchParams),
      [filterLabel]: state,
    };
    setSearchParams(updatedSearchParamsState);

    if (selectedJobId) {
      indexedDb.put(selectedJobId, updatedSearchParamsState);
    }
  }

  onClose();
};
