import { DateRange } from 'react-day-picker';
import { endOfDay, startOfDay } from 'date-fns';

import { FilterLabel } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { filteredFilterMenuConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/utils/filterMenuConfiguration';
import { configToSearchParamsObj } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/utils/configToSearchParamsObj';
import { searchParamsToObject } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/utils/searchParamsToObject';

export const updateDateSearchParams = (
  range: DateRange | undefined,
  searchParams: URLSearchParams,
  setSearchParams: (URLSearchParamsInit: Record<string, any>) => void,
  hasChat: boolean | undefined
) => {
  if (!range || !range.to || !range.from) {
    return;
  }

  if (searchParams.toString().length === 0) {
    setSearchParams({
      ...configToSearchParamsObj(filteredFilterMenuConfiguration(hasChat)),
      [FilterLabel.APPLY_ON]: [
        startOfDay(range.from).getTime().toString(),
        endOfDay(range.to).getTime().toString(),
      ],
    });
  } else {
    setSearchParams({
      ...searchParamsToObject(searchParams),
      [FilterLabel.APPLY_ON]: [
        startOfDay(range.from).getTime().toString(),
        endOfDay(range.to).getTime().toString(),
      ],
    });
  }
};
