import { touchRippleClasses } from '@mui/material';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import styled from 'styled-components';

import { gray8, gray10, gray20 } from 'src/shared/components/theme/mui/colors/colors';

export const TalentProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 1px 8px rgba(0 0 0 / 10%);
`;

export const PaperTabs = styled(Tabs)({
  flexShrink: 0,
  height: 'fit-content',
  width: '100%',
  [`& .${tabsClasses.indicator}`]: {
    display: 'none',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    borderBottom: `1px solid ${gray20}`,
    height: 'fit-content',
    paddingLeft: '30px',
    paddingTop: '20px',
  },
});

export const PaperTab = styled(Tab)({
  '&:hover': {
    backgroundColor: `${gray20}!important`,
    transition: 'background-color 0.2s linear',
  },
  'alignItems': 'center',
  'backgroundColor': gray10 + '!important',
  'border': `1px solid ${gray10}!important`,
  'borderBottom': `1px solid ${gray20}!important`,
  'borderTopLeftRadius': '5px!important',
  'borderTopRightRadius': '5px!important',
  'color': `${gray8}!important`,
  'display': 'flex',
  'fontSize': '14px',
  'fontWeight': '500!important',
  'height': 'fit-content!important',
  'justifyContent': 'start',
  'lineHeight': '16.8px!important',
  'marginTop': '2px',
  'minHeight': '0px!important',
  'padding': '12px!important',
  'translate': '0px 1px',
  'width': '156px',

  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'white!important',
    border: `1px solid ${gray20}!important`,
    borderBottom: '1px solid white!important',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    boxShadow: '0 1px 8px #0000001a!important',
    color: `${gray8}!important`,
    fontWeight: '500!important',
    padding: '12px!important',
  },
  [`& .${touchRippleClasses.root}`]: {
    borderBottomLeftRadius: '0px!important',
    borderBottomRightRadius: '0px!important',
    borderTopLeftRadius: '5px!important',
    borderTopRightRadius: '5px!important',
  },
});
