import { gray40 } from 'src/shared/components/theme/mui/colors/colors';

export const MuiChip = {
  styleOverrides: {
    label: {
      'height': 'auto',
      'lineHeight': '16px',
      'padding': '8px 10px',
      'white-space': 'normal',
    },
    outlined: {
      border: `1px solid ${gray40}`,
      borderRadius: '23px',
      height: 'auto',
      maxWidth: '408px',
      padding: '0px',
    },
  },
};
