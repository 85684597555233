import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { updateTalentList } from 'src/store/api/graphql/talents/utils/updateTalentList';
import { addNotification } from 'src/store/slices/notifications/notifications';
import { RootState } from 'src/store/store';
import { ChatStatus, Score } from 'src/types/domain';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { extendedGraphqlApiWithGetTalents, GetTalentsVariables } from '../getTalents';
import { updateCandidateDetails } from '../utils/updateCandidateDetails';

export const optimisticResetChatStatus = async ({
  dispatch,
  queryFulfilled,
  arg,
  state,
}: {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  queryFulfilled: Promise<any>;
  arg: any;
  state: RootState;
}) => {
  const jobId = state.routerParameters.jobId;

  const searchParams = new URLSearchParams(state.router.location?.search);
  const endpointSelectParams: GetTalentsVariables = parseSearchParamsFilter(jobId, searchParams);

  const talentsResponse =
    extendedGraphqlApiWithGetTalents.endpoints.getTalents.select(endpointSelectParams)(state).data;

  const indexOfOldItem = talentsResponse?.items.findIndex(item => item.id === arg.sqlId);

  try {
    await queryFulfilled;

    updateTalentList({
      chatStatus: ChatStatus.NOT_STARTED,
      dispatch,
      indexOfOldItem,
      score: Score.UNKNOWN,
      selectParams: endpointSelectParams,
    });

    updateCandidateDetails({
      candidateId: arg.sqlId,
      chatStatus: ChatStatus.NOT_STARTED,
      dispatch,
    });
  } catch {
    dispatch(
      addNotification({
        message: 'Fail to reset chat status',
        options: {
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          autoHideDuration: 3000,
          variant: 'error',
        },
      })
    );
  }

  if (talentsResponse && indexOfOldItem !== undefined && indexOfOldItem > -1) {
    const url =
      process.env.VITE_CHAT_URL +
      '/chat/application/' +
      talentsResponse.items[indexOfOldItem].neoId;
    window.open(url, '_blank');
  }
};
