import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { addNotification } from 'src/store/slices/notifications/notifications';
import { NotificationOptions } from 'src/store/types/types';
interface TalentPromotionErrorNotification {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  hasConflictError: boolean;
  operationName: 'Advance' | 'Reject';
  userInfo?: {
    firstName: string;
    lastName: string;
  };
}

export const errorNotificationOptions: NotificationOptions = {
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  autoHideDuration: 3000,
  variant: 'default',
};

export const talentPromotionErrorNotification = ({
  dispatch,
  hasConflictError,
  operationName,
  userInfo,
}: TalentPromotionErrorNotification) => {
  if (hasConflictError) {
    dispatch(
      addNotification({
        message:
          'This Applicant has already been processed by another user in the system. Please refresh this page to see an updated list of Applicants',
        options: errorNotificationOptions,
      })
    );
    return;
  }

  if (userInfo) {
    dispatch(
      addNotification({
        message: `${userInfo.firstName} ${userInfo.lastName} couldn’t be ${
          operationName === 'Advance' ? 'advanced' : 'rejected'
        }.\n Please try again`,
        options: errorNotificationOptions,
      })
    );
  }
};
