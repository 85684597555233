import { Context, createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateFunction } from 'react-router';
import { Location } from 'react-router-dom';
import { Auth0DecodedHash } from 'auth0-js';
import { push } from 'redux-first-history';

import { clearTimeoutIfSet } from 'src/components/Talent/components/TalentList/utils/clearTimeoutIfSet';
import Auth from 'src/services/auth/auth';
import { useSetFeatureFlagContext } from 'src/services/featureFlags/utils/useSetFeatureFlagContext';
import { useAppDispatch } from 'src/store/hooks/hooks';
import { addNotification } from 'src/store/slices/notifications/notifications';
import { reset } from 'src/store/slices/talentNotes/talentNotes';

export interface UserAuthInfo {
  sub: string;
  name: string;
  email: string | null;
  picture: string | null;
  nickname: string;
  updated_at: Date;
  email_verified: boolean;
}

export interface AuthProps {
  handleAuth: (location: Location, history: NavigateFunction) => void;
  login: (email: string, password: string, callback: auth0.Auth0Callback<any>) => void;
  logout: () => boolean;
  isAuthenticated: () => boolean;
  getAccessToken: () => string | null;
  userInfo: UserAuthInfo;
  checkSessionAndRefresh: () => void;
}
export const authContextDefaultValues = {
  checkSessionAndRefresh: () => {},
  getAccessToken: (): string | null => null,
  handleAuth: (_: Location, __: NavigateFunction) => {},
  isAuthenticated: (): boolean => false,
  login: (_: string, __: string, ___: auth0.Auth0Callback<any>) => {},
  logout: (): boolean => false,
  userInfo: {} as UserAuthInfo,
};

//This should never be lower then 15m since its the minimum recommendation from auth0
const TIME_BETWEEN_TOKEN_REFRESH = 900 * 1000;

export const AuthContext: Context<AuthProps> = createContext(authContextDefaultValues);

const NULL_USER_INFO: UserAuthInfo = {
  email: '',
  email_verified: false,
  name: '',
  nickname: '',
  picture: null,
  sub: '',
  updated_at: new Date(),
};

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [userInfo, setUserInfo] = useState<UserAuthInfo>(NULL_USER_INFO);
  const [isRetrievingData, setIsRetrievingData] = useState<boolean>(false);
  const logoutTimer = useRef<NodeJS.Timeout | undefined>();
  const dispatch = useAppDispatch();
  const auth = useMemo(() => new Auth(), []);

  useSetFeatureFlagContext(userInfo);

  useEffect(() => {
    if (auth.isAuthenticated() && !userInfo.email && !isRetrievingData) {
      (async () => {
        setIsRetrievingData(true);
        let data;

        try {
          data = await auth.getUserInfo();
          setUserInfo(data);
          setIsRetrievingData(false);
        } catch {
          setIsRetrievingData(false);
        }
      })();
    }
  }, [auth, isRetrievingData, userInfo.email]);

  const checkSessionAndRefresh = () => {
    const expiresAtTime = localStorage.getItem('expires_at');
    const timeSinceLastRefresh = localStorage.getItem('expires_at_created_at');

    if (!expiresAtTime || !timeSinceLastRefresh) {
      auth.logout();
      dispatch(push('/'));
      return;
    }

    const expiresAt = JSON.parse(expiresAtTime);

    if (expiresAt - new Date().getTime() > 0) {
      if (new Date().getTime() - +timeSinceLastRefresh > TIME_BETWEEN_TOKEN_REFRESH) {
        localStorage.setItem('expires_at_created_at', JSON.stringify(new Date().getTime() + 20000));
        auth.checkSessionAndRefreshToken();
      }
    } else {
      dispatch(
        addNotification({
          message: `Your session expired.\n Please log in again to pick up where you left off.`,
          options: {
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            autoHideDuration: 3000,
            variant: 'default',
          },
        })
      );
      dispatch(reset());
      auth.logout();
      dispatch(push('/'));
    }
  };

  const handleAuth = (location: Location, navigate: NavigateFunction) => {
    const initialPath = localStorage.getItem('initialPath');

    if (!auth.isAuthenticated() && /access_token|id_token|error/.test(location?.hash)) {
      auth
        .handleAuthentication()
        .then(({ idTokenPayload }) => {
          setUserInfo(idTokenPayload);
        })
        .catch(console.log)
        .finally(() => {
          navigate(initialPath || '/');
        });
    }
  };

  const login = (email: string, password: string, callback: auth0.Auth0Callback<any>) => {
    if (!auth.isAuthenticated()) {
      auth.login(email, password, (err, result: Auth0DecodedHash) => {
        callback(err, result);

        if (err) {
          return;
        }

        setUserInfo(result ? result.idTokenPayload : NULL_USER_INFO);
      });
    }
  };

  const logout = () => {
    clearTimeoutIfSet({ timeout: logoutTimer.current || null });

    if (auth.isAuthenticated()) {
      dispatch(reset());
      auth.logout();
      return true;
    }

    return false;
  };

  //TODO: Memoize `value`
  return (
    <AuthContext.Provider
      value={{
        checkSessionAndRefresh,
        getAccessToken: auth.getAccessToken,
        handleAuth,
        isAuthenticated: auth.isAuthenticated,
        login,
        logout,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
