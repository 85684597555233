import styled from 'styled-components';

import { gray60 } from 'src/shared/components/theme/mui/colors/colors';

export const InformationContainer = styled.div`
  size: 16px;
  display: flex;
  justify-content: center;

  margin-top: 16px;

  font-weight: 700;
  line-height: 19.2px;
  color: ${gray60};
`;
