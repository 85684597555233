import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  extendedGraphqlApiWithGetTalents,
  GetTalentsVariables,
} from 'src/store/api/graphql/talents/getTalents';
import { ChatStatus, RecruiterInfo, Score, TalentStatusCurrentlyUsed } from 'src/types/domain';

export interface UpdateTalentList {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  selectParams: GetTalentsVariables;
  indexOfOldItem: number | undefined;
  newStatus?: TalentStatusCurrentlyUsed;
  newStatusTimestamp?: number;
  chatStatus?: ChatStatus;
  score?: Score;
  onHold?: boolean;
  onHoldSince?: string;
  onHoldBy?: RecruiterInfo;
  hasNotes?: boolean;
}

export const updateTalentList = ({
  dispatch,
  selectParams,
  indexOfOldItem,
  newStatus,
  newStatusTimestamp,
  chatStatus,
  score,
  onHold,
  onHoldSince,
  onHoldBy,
  hasNotes,
}: UpdateTalentList) =>
  dispatch(
    extendedGraphqlApiWithGetTalents.util.updateQueryData('getTalents', selectParams, data => {
      if (indexOfOldItem === undefined || indexOfOldItem === -1) {
        return data;
      }

      const talents = [...data.items];

      const currentTalent = talents[indexOfOldItem];
      talents[indexOfOldItem] = {
        ...currentTalent,
        chatStatus: chatStatus ?? currentTalent.chatStatus,
        currentStatus: newStatus
          ? {
              ...currentTalent.currentStatus,
              status: {
                ...currentTalent.currentStatus.status,
                status: newStatus,
                ts: newStatusTimestamp || Date.now(),
              },
            }
          : currentTalent.currentStatus,
        hasNotes: hasNotes ?? currentTalent.hasNotes,
        onHold: onHold ?? currentTalent.onHold,
        onHoldBy: onHoldBy !== undefined ? onHoldBy : currentTalent.onHoldBy,
        onHoldSince: onHoldSince !== undefined ? onHoldSince : currentTalent.onHoldSince,
        score: score ?? currentTalent.score,
      };

      return {
        ...data,
        items: talents,
      };
    })
  );
