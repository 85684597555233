import {
  FilterConfiguration,
  FilterLabel,
  FilterTypes,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';

export const filterMenuConfiguration: FilterConfiguration = {
  header: 'Filter by',
  values: [
    {
      header: { filterLabel: FilterLabel.STATUS, label: 'Status' },
      type: FilterTypes.BOOLEAN,
      values: [
        { filterValue: 'NEW', label: 'In review' },
        { filterValue: 'ADVANCED', label: 'Advanced' },
        { filterValue: 'REJECTED', label: 'Rejected' },
      ],
    },
    {
      header: { filterLabel: FilterLabel.CHAT, label: 'Chat' },
      type: FilterTypes.BOOLEAN,
      values: [
        { filterValue: 'NOT_STARTED', label: 'Not started' },
        { filterValue: 'STARTED', label: 'Started' },
        { filterValue: 'COMPLETED', label: 'Completed' },
      ],
    },
    {
      header: { filterLabel: FilterLabel.SCORE, label: 'Evaluation' },
      type: FilterTypes.BOOLEAN,
      values: [
        { filterValue: 'QUALIFIED', label: 'Qualified' },
        { filterValue: 'UNQUALIFIED', label: 'Unqualified' },
        { filterValue: 'UNKNOWN', label: 'Unknown' },
      ],
    },
    {
      header: { filterLabel: FilterLabel.NOTES, label: 'Notes' },
      type: FilterTypes.BOOLEAN,
      values: [
        { filterValue: 'true', label: 'Has notes' },
        { filterValue: 'false', label: "Doesn't have notes" },
      ],
    },
    {
      header: { filterLabel: FilterLabel.ON_HOLD, label: 'On hold' },
      type: FilterTypes.BOOLEAN,
      values: [
        { filterValue: 'true', label: 'On hold' },
        { filterValue: 'false', label: 'Not on hold' },
      ],
    },
    {
      header: { filterLabel: FilterLabel.APPLY_ON, label: 'Applied date' },
      type: FilterTypes.DATE,
      values: [],
    },
  ],
};

export const filteredFilterMenuConfiguration = (hasChat: boolean | undefined) => ({
  ...filterMenuConfiguration,
  values: !hasChat
    ? filterMenuConfiguration.values.filter(
        item =>
          item.header.filterLabel !== FilterLabel.SCORE &&
          item.header.filterLabel !== FilterLabel.CHAT
      )
    : filterMenuConfiguration.values,
});
