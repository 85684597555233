import React, { ChangeEvent, FC, useState } from 'react';
import Avatar from '@mui/material/Avatar';
export interface ImageWithFallbackProps {
  imageSource: string | undefined;
  fallbackIcon: JSX.Element;
  alt?: string;
}

export const ImageWithFallback: FC<ImageWithFallbackProps> = ({
  imageSource,
  fallbackIcon,
  alt = '',
}) => {
  const [showFallbackIcon, setShowFallbackIcon] = useState<boolean>(false);

  const handleImageError = (e: ChangeEvent<HTMLImageElement>) => {
    e.target.onerror = null;
    setShowFallbackIcon(true);
  };

  if (!showFallbackIcon && imageSource) {
    return (
      <img data-testiid='default-img' onError={handleImageError} src={imageSource} alt={alt} />
    );
  }

  return <Avatar>{fallbackIcon}</Avatar>;
};
