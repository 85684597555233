export const bakedDomains: Record<string, string> = {
  'AECOM': 'aecom.com',
  'Adobe': 'adobe.com',
  'Airbnb': 'airbnb.com',
  'Autodesk': 'autodesk.com',
  'Axon Enterprise': 'axon.com',
  'Bank of America': 'bankofamerica.com',
  'Black Hills Corporation': 'blackhillscorp.com',
  'Boston University': 'bu.edu',
  'Brown & Brown': 'bbinsurance.com',
  'Ciena Corp': 'ciena.com',
  'Comcast': 'comcast.com',
  'Cornell University': 'cornell.edu',
  'Cree Inc': 'cree.com',
  'Dana Incorporated': 'dana.com',
  'Energizer Holdings Inc': 'energizerholdings.com',
  'Florida State University': 'www.fsu.edu',
  'Georgia Institute of Technology': 'gatech.edu',
  'Google': 'google.com',
  'IBM': 'ibm.com',
  'Longhorn Inc.': 'longhornic.com',
  'Media Corporation': 'libertymedia.com',
  'Microsoft': 'microsoft.com',
  'NYU': 'nyu.edu',
  'Sun Microsystems': 'oracle.com',
  'Thaca College': 'ithaca.edu',
  'University of Florida': 'ufl.edu',
  'University of Texas at Austin': 'utexas.edu',
  'University of Virginia': 'virginia.edu',
  'Wade & Wendy': 'wadeandwendy.ai',
  'William & Mary': 'wm.edu',
};
