import { Suspense, SyntheticEvent, useEffect, useState } from 'react';

import { Details } from 'src/components/Talent/components/TalentProfile/components/Details/Details';
import { Notes } from 'src/components/Talent/components/TalentProfile/components/Notes/Notes';
import { Questions } from 'src/components/Talent/components/TalentProfile/components/Questions';
import { TalentProfileHeader } from 'src/components/Talent/components/TalentProfile/components/TalentProfileHeader/TalentProfileHeader';
import {
  PaperTab,
  PaperTabs,
  TalentProfileWrapper,
} from 'src/components/Talent/components/TalentProfile/styled';
import { Spinner } from 'src/shared/components';
import { LoadingPage } from 'src/shared/components/LoadingPage/LoadingPage';
import { useGetCandidateQuery } from 'src/store/api/graphql/talents/getCandidate';
import { useAppSelector } from 'src/store/hooks/hooks';
import { lazyWithPreload } from 'src/utils/functions/lazyWithPreload';

const Resume = lazyWithPreload(() => import('src/components/Resume/Resume'));

export enum TalentProfileActiveTab {
  DETAILS = 'Details',
  RESUME = 'Resume',
  QUESTIONS = 'Questions',
  NOTES = 'Notes',
}

export function TalentProfile(): JSX.Element {
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);

  const {
    currentData: candidate,
    isLoading: isCandidateLoading,
    isError: candidateHasError,
    refetch: refetchCandidate,
  } = useGetCandidateQuery(
    {
      id: selectedTalentId && +selectedTalentId,
    },
    { skip: Boolean(!selectedTalentId) || selectedTalentId === ':talentId' }
  );

  const [activeTab, setActiveTab] = useState<TalentProfileActiveTab>(
    TalentProfileActiveTab.DETAILS
  );

  useEffect(() => {
    Resume.preload();
  }, []);

  useEffect(() => {
    setActiveTab(TalentProfileActiveTab.DETAILS);
  }, [selectedTalentId]);

  if (!candidate || isCandidateLoading) {
    return (
      <TalentProfileWrapper>
        <LoadingPage hasError={candidateHasError} retryCallback={refetchCandidate} />
      </TalentProfileWrapper>
    );
  }

  const paperTabsOnChangeHandler = (
    _: SyntheticEvent<Element, Event>,
    value: TalentProfileActiveTab
  ) => {
    setActiveTab(value);
  };

  const selectedTab = {
    [TalentProfileActiveTab.DETAILS]: <Details candidate={candidate} />,
    [TalentProfileActiveTab.RESUME]: (
      <Suspense fallback={<Spinner />}>
        <Resume
          resume={candidate.zohoInfo.resumeLink}
          name={`${candidate.person.name.firstName} ${candidate.person.name.lastName}`}
        />
      </Suspense>
    ),
    [TalentProfileActiveTab.QUESTIONS]: (
      <Questions
        chatStatus={candidate.chatStatus}
        chatSummaries={candidate.chatSummaries}
        name={candidate.person.name}
      />
    ),
    [TalentProfileActiveTab.NOTES]: <Notes notes={candidate.notes} />,
  };

  return (
    <TalentProfileWrapper>
      <TalentProfileHeader candidate={candidate} />

      <PaperTabs value={activeTab} onChange={paperTabsOnChangeHandler}>
        <PaperTab
          disableRipple={true}
          label={TalentProfileActiveTab.DETAILS}
          value={TalentProfileActiveTab.DETAILS}
        />
        <PaperTab
          disableRipple={true}
          label={TalentProfileActiveTab.RESUME}
          value={TalentProfileActiveTab.RESUME}
        />
        {candidate.job.hasChat && (
          <PaperTab
            disableRipple={true}
            label={TalentProfileActiveTab.QUESTIONS}
            value={TalentProfileActiveTab.QUESTIONS}
          />
        )}
        <PaperTab
          disableRipple={true}
          label={TalentProfileActiveTab.NOTES}
          value={TalentProfileActiveTab.NOTES}
        />
      </PaperTabs>
      <div
        style={{
          flexShrink: 1,
          height: '100%',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {selectedTab[activeTab]}
      </div>
    </TalentProfileWrapper>
  );
}
