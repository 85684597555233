import { gql } from 'graphql-request';

import { graphqlApi } from 'src/store/api/graphql/graphql';
import { ClientDetails } from 'src/store/types/types';

export const extendedApi = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    clientDetails: builder.query<ClientDetails, void>({
      query: variables => ({
        document: gql`
          query {
            clientDetails {
              chatsEnabled
              demoEnabled
            }
          }
        `,
        variables,
      }),
      transformResponse: (res): ClientDetails => res.data.clientDetails as ClientDetails,
    }),
  }),
});

export const { useClientDetailsQuery } = extendedApi;
