import { ChangeEvent, FC, useContext, useEffect } from 'react';

import { DateInput } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/DateInputs/styled';
import {
  endDateValidator,
  startDateValidator,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/DateInputs/utils/dateValidators';
import { updateInputs } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/DateInputs/utils/updateInputs';
import {
  DateContext,
  SelectedDateInput,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

export const DateInputs: FC = () => {
  const {
    range,
    selectedDateInput,
    setSelectedDateInput,
    setDate,
    setStartDate: setContextStartingDate,
    setStartDayInput,
    setStartDayInputError,
    startDayInput,
    startDayInputError,
    endDayInput,
    endDayInputError,
    setEndDayInput,
    setEndDayInputError,
    setEndDate: setContextEndDate,
    earliestDate,
  } = useContext(DateContext);

  useEffect(() => {
    updateInputs(
      range,
      setStartDayInput,
      setStartDayInputError,
      setEndDayInput,
      setEndDayInputError
    );
  }, [range, setEndDayInput, setStartDayInput, setStartDayInputError, setEndDayInputError]);

  const onStartDayChangeHandler = (
    changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStartDayInputError(false);
    const value = changeEvent.target.value;

    if (value === '') {
      setDate(undefined);
      setStartDayInputError(true);
      setStartDayInput('');
      return;
    }

    setStartDayInput(value);

    startDateValidator({
      earliestDateTimeStamp: earliestDate,
      endDate: range && range.from,
      incomingInputValue: value,
      setContextEndDate,
      setContextStartingDate,
      setEndDate: setEndDayInput,
      setEndDateError: setEndDayInputError,
      setStartDate: setStartDayInput,
      setStartDateError: setStartDayInputError,
    });
  };

  const onEndDayChangeHandler = (
    changeEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEndDayInputError(false);
    const value = changeEvent.target.value;

    if (value === '') {
      setDate(undefined);
      setEndDayInput('');
      setEndDayInputError(true);
      return;
    }

    setEndDayInput(value);

    endDateValidator({
      earliestDateTimeStamp: earliestDate,
      incomingInputValue: value,
      setContextEndDate,
      setContextStartingDate,
      setEndDate: setEndDayInput,
      setEndDateError: setEndDayInputError,
      setStartDate: setStartDayInput,
      setStartDateError: setStartDayInputError,
      startDate: range && range.from,
    });
  };

  return (
    <>
      <DateInput
        onClick={() => setSelectedDateInput(SelectedDateInput.START)}
        error={startDayInputError}
        value={startDayInput}
        placeholder='MM/DD/YYYY'
        id='startDate'
        onChange={onStartDayChangeHandler}
        /* the reason for passing as a string is due to a Mui warning that focused cant be a boolean.
           this is caused by the component the styled component is extending. */
        focused={Boolean(selectedDateInput === SelectedDateInput.START).toString()}
        onFocus={() => setSelectedDateInput(SelectedDateInput.START)}
      />{' '}
      -{' '}
      <DateInput
        placeholder='MM/DD/YYYY'
        onClick={() => setSelectedDateInput(SelectedDateInput.END)}
        value={endDayInput}
        error={endDayInputError}
        onChange={onEndDayChangeHandler}
        id='endDate'
        focused={Boolean(selectedDateInput === SelectedDateInput.END).toString()}
        onFocus={() => setSelectedDateInput(SelectedDateInput.END)}
      />
    </>
  );
};
