import { CSSProperties, FunctionComponent } from 'react';

import classes from './spinner.module.scss';

export interface SpinnerProps {
  style?: CSSProperties;
}

export const Spinner: FunctionComponent<SpinnerProps> = ({ style }) => (
  <div className={classes.container}>
    <div style={style} className={classes.spinner} />
  </div>
);
