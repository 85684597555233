import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import thunkMiddleware from 'redux-thunk';

import notificationsReducer from 'src/store/slices/notifications/notifications';
import talentNotesReducer from 'src/store/slices/talentNotes/talentNotes';

import { graphqlApi } from './api/graphql/graphql';
import { routeChange } from './middlewares/routeChange';
import chatDemoReducer from './slices/chatDemo/chatDemo';
import jobsReducer from './slices/jobs/jobs';
import routerParametersReducer from './slices/routerParameters/routerParameters';
import talentModalReducer from './slices/talentModal/talentModal';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const middlewares = [graphqlApi.middleware, routeChange, routerMiddleware, thunkMiddleware];

export const store = configureStore({
  middleware: [...middlewares],
  reducer: combineReducers({
    chatDemo: chatDemoReducer,
    jobs: jobsReducer,
    notifications: notificationsReducer,
    router: routerReducer,
    routerParameters: routerParametersReducer,
    talentModal: talentModalReducer,
    talentNotes: talentNotesReducer,
    [graphqlApi.reducerPath]: graphqlApi.reducer,
  }),
});

export const history = createReduxHistory(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
