import { createElement, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { default as MuiChip } from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import {
  Boolean,
  BooleanProps,
} from 'src/components/Talent/components/TalentList/components/Header/components/Chips/components/Item/components/Boolean/Boolean';
import {
  DateRange,
  DateRangeProps,
} from 'src/components/Talent/components/TalentList/components/Header/components/Chips/components/Item/components/DateRange/DateRange';
import { Chip } from 'src/components/Talent/components/TalentList/components/Header/components/Chips/types/types';
import { FilterTypes } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { searchParamsToObject } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/utils/searchParamsToObject';
import { blue50, gray60 } from 'src/shared/components/theme/mui/colors/colors';
import { PERSISTENCE_FILTERS_DATABASE, useIndexedDb } from 'src/store/indexeddb/IndexedDb';

const textCreatorMapper: Record<FilterTypes, FC<BooleanProps | DateRangeProps>> = {
  [FilterTypes.BOOLEAN]: Boolean,
  [FilterTypes.DATE]: DateRange,
};

export const Item: FC<Chip> = ({ label, values, config, selectedJobId }) => {
  const indexedDb = useIndexedDb(PERSISTENCE_FILTERS_DATABASE);

  const [searchParams, setSearchParams] = useSearchParams();

  const onDelete = () => {
    const searchParamsAsObj = searchParamsToObject(searchParams);
    delete searchParamsAsObj[config.header.filterLabel];

    if (selectedJobId) {
      indexedDb.put(selectedJobId, searchParamsAsObj);
    }

    setSearchParams(searchParamsAsObj);
  };

  const filterValueToValue = () => {
    if (config.type === FilterTypes.DATE) {
      return searchParams.getAll(config.header.filterLabel);
    }

    return config.values.reduce((returnedArray, configValue) => {
      if (values.find(value => value === configValue.filterValue)) {
        returnedArray.push(configValue.label);
      }

      return returnedArray;
    }, [] as string[]);
  };

  return (
    <MuiChip
      variant='outlined'
      label={createElement(textCreatorMapper[config.type], {
        label,
        values: filterValueToValue(),
      })}
      deleteIcon={
        <IconButton size='small'>
          <CloseIcon fontSize='small' sx={{ '&:hover': { color: blue50 }, 'color': gray60 }} />
        </IconButton>
      }
      onDelete={onDelete}
    />
  );
};
