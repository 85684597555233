import { menuItemClasses } from '@mui/material/MenuItem';

import {
  blue50,
  gray10,
  gray20,
  gray30,
  gray60,
  menuOutline,
} from 'src/shared/components/theme/mui/colors/colors';

export const MuiMenuItem = {
  styleOverrides: {
    divider: {
      borderBottom: `1px solid ${gray30}`,
    },
    root: {
      '&:hover': {
        backgroundColor: gray20,
      },
      'display': 'flex',
      'height': '40px',
      'justifyContent': 'space-between',
      'padding': '6px 8px 6px 12px',
      [`&.${menuItemClasses.selected}`]: {
        backgroundColor: blue50 + '!important',
        color: 'white',
      },
      [`&.${menuItemClasses.selected}:hover`]: {
        backgroundColor: gray20 + '!important',
        color: gray60,
      },
    },
  },
};
const borderRadius = '5px';

export const MuiMenu = {
  styleOverrides: {
    list: {
      '&& .MuiSvgIcon-root': {
        height: '16px',
        width: '20px',
      },
      '&& .filter_apply': {
        alignItems: 'center',
        backgroundColor: gray10,
        display: 'flex',
        height: '60px',
        padding: '7px 12px 7px 12px',
        width: '230px',
      },
      'border': `1px solid ${menuOutline}`,
      borderRadius,
      'overflow': 'hidden',
      'padding': '0px',
    },
    paper: {
      borderRadius,
    },
  },
};
