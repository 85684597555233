import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Item } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Binary/components/Item/Item';
import { removeOrAdd } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Binary/utils/removeOrAdd';
import { submitHandler } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Binary/utils/submitHandler';
import { Header } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Header/Header';
import { Subheader } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Subheader/Subheader';
import { Submit } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Submit/Submit';
import { FilterProperty } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { useGetArchivedJobsQuery } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { useGetLiveJobsQuery } from 'src/store/api/graphql/jobs/getLiveJobs';
import { useAppSelector } from 'src/store/hooks/hooks';
import { PERSISTENCE_FILTERS_DATABASE, useIndexedDb } from 'src/store/indexeddb/IndexedDb';

export interface BinaryProps {
  value: FilterProperty;
  onClose: () => void;
}

export const Binary: FC<BinaryProps> = ({ value, onClose }) => {
  const indexedDb = useIndexedDb(PERSISTENCE_FILTERS_DATABASE);
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setNewState] = useState<string[]>(
    searchParams.toString().length > 0
      ? searchParams.getAll(value.header.filterLabel)
      : value.values.map(item => item.filterValue)
  );

  const onClickHandler = (filterValue: string) => {
    setNewState(prev => removeOrAdd(prev, filterValue));
  };

  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { job: liveJob } = useGetLiveJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const { job: archivedJob } = useGetArchivedJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const selectedJob = liveJob || archivedJob;

  return (
    <>
      <Header onClose={onClose} text={value.header.label} />
      <Subheader text={'Match any:'} />
      {value.values.map(subValue => (
        <Item
          key={subValue.label}
          label={subValue.label}
          filterValue={subValue.filterValue}
          onClick={onClickHandler}
          isThisActive={Boolean(state.find(item => item === subValue.filterValue))}
        />
      ))}
      <Submit
        onClick={() =>
          submitHandler({
            filterLabel: value.header.filterLabel,
            hasChat: selectedJob?.hasChat,
            onClose,
            searchParams,
            setSearchParams,
            state,
            selectedJobId,
            indexedDb,
          })
        }
      />
    </>
  );
};
