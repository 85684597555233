import { createSlice } from '@reduxjs/toolkit';

import { TalentNotesState } from 'src/store/types/types';

import { resetAction } from './actions/reset';
import { setActiveDialogAction } from './actions/setActiveDialog';
import { setActiveInputAction } from './actions/setActiveInput';
import { setNoteAction } from './actions/setNote';

export const talentNotesInitialState: TalentNotesState = {
  activeDialog: undefined,
  activeInput: false,
  tempNote: undefined,
};

export const talentNotes = createSlice({
  initialState: talentNotesInitialState,
  name: 'talentNotes',
  reducers: {
    reset: resetAction,
    setActiveDialog: setActiveDialogAction,
    setActiveInput: setActiveInputAction,
    setNote: setNoteAction,
  },
});

export const { reset, setActiveDialog, setActiveInput, setNote } = talentNotes.actions;

export default talentNotes.reducer;
