import { CSSProperties } from 'react';
import { createTheme } from '@mui/material/styles';

import { MuiCheckbox } from 'src/shared/components/theme/mui/components/MuiCheckbox';
import { MuiChip } from 'src/shared/components/theme/mui/components/MuiChip';
import { MuiFormControlLabel } from 'src/shared/components/theme/mui/components/MuiFormControlLabel';
import { MuiIcon } from 'src/shared/components/theme/mui/components/MuiIcon';
import { MuiMenu, MuiMenuItem } from 'src/shared/components/theme/mui/components/MuiMenu';
import { MuiModal } from 'src/shared/components/theme/mui/components/MuiModal';
import { MuiOutlinedInput } from 'src/shared/components/theme/mui/components/MuiOutlinedInput';
import { MuiSelect } from 'src/shared/components/theme/mui/components/MuiSelect';
import { MuiSwitch } from 'src/shared/components/theme/mui/components/MuiSwitch';
import { MuiTab } from 'src/shared/components/theme/mui/components/MuiTab';
import { MuiTabs } from 'src/shared/components/theme/mui/components/MuiTabs';
import { MuiTooltip } from 'src/shared/components/theme/mui/components/MuiTooltip';
import { typography } from 'src/shared/components/theme/mui/typography/typography';

import { MuiAlert } from './components/MuiAlert';
import { MuiAutocomplete } from './components/MuiAutocomplete';
import { MuiButton } from './components/MuiButton';
import {
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
} from './components/MuiDialog';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body7: CSSProperties;
    subHeading1: CSSProperties;
    subHeading2: CSSProperties;
    smallt1: CSSProperties;
    smallTitle: CSSProperties;
    mini: CSSProperties;
  }
  interface TypographyVariantsOptions {
    body7?: CSSProperties;
    subHeading1: CSSProperties;
    subHeading2: CSSProperties;
    smallt1?: CSSProperties;
    smallTitle: CSSProperties;
    mini: CSSProperties;
  }
  interface Palette {
    copilot: Palette['primary'];
  }

  interface PaletteOptions {
    copilot?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    copilot: true;
  }
}
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    copilot: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    copilot: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    copilot: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body7: true;
    smallt1: true;
    smallTitle: true;
    mini: true;
    subHeading1: true;
    subHeading2: true;
  }
}

export const theme = createTheme({
  breakpoints: {},
  components: {
    MuiAlert,
    MuiAutocomplete,
    MuiButton,
    MuiCheckbox,
    MuiChip,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiFormControlLabel,
    MuiIcon,
    MuiMenu,
    MuiMenuItem,
    MuiModal,
    MuiOutlinedInput,
    MuiSelect,
    MuiSvgIcon: MuiIcon,
    MuiSwitch,
    MuiTab,
    MuiTabs,
    MuiTooltip,
  },
  palette: {
    copilot: {
      main: '#f48d29',
    },
  },
  typography,
});
