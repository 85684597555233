import * as auth0 from 'auth0-js';
import { AdfsUserProfile, Auth0DecodedHash, Auth0Error } from 'auth0-js';
import Cookies from 'js-cookie';

import { UserAuthInfo } from 'src/contexts/AuthContext';
import AUTH_CONFIG from 'src/services/auth/config';
import { setSession } from 'src/services/auth/utils/setSession';

class Auth {
  auth0 = new auth0.WebAuth({
    audience: AUTH_CONFIG.audience,
    clientID: AUTH_CONFIG.clientId,
    domain: AUTH_CONFIG.domain,
    redirectUri: window.location.origin + '/callback',
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  public login(
    email: string,
    password: string,
    callback: auth0.Auth0Callback<Record<string, string | number | undefined | boolean>>
  ): void {
    this.auth0.login({ email, password }, callback);
  }

  public handleAuthentication(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult: Auth0DecodedHash | null) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        }

        reject();
      });
    });
  }

  public resetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, response) => {
          if (err) {
            reject(err);
          }

          resolve(response);
        }
      );
    });
  }

  public logout(): void {
    /* capture logout metrics
       useMetricsApi('/logout', { userAgent: navigator.userAgent });
       Clear access token and ID token from local storage */
    localStorage.removeItem('access_token');
    Cookies.remove('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('initialPath');
    localStorage.removeItem('expires_at_created_at');
    // navigate to the home route
  }

  public isAuthenticated(): boolean {
    /* Check whether the current time is past the
       access token's expiry time */
    const time: string | null = localStorage.getItem('expires_at');

    if (time) {
      const expiresAt = JSON.parse(time);
      return new Date().getTime() < expiresAt;
    }

    return false;
  }

  public checkSessionAndRefreshToken(): void {
    this.auth0.checkSession({}, (err: any, authResult: Auth0DecodedHash) => {
      if (err) {
        this.logout();
        return;
      }

      setSession(authResult);
    });
  }

  public getUserInfo(): Promise<UserAuthInfo> {
    return new Promise((resolve, reject) => {
      const token: string | null = this.getAccessToken();

      if (token) {
        this.auth0.client.userInfo(token, (err: Auth0Error | null, user: AdfsUserProfile) => {
          if (err) {
            reject(err);
          }

          resolve({
            email: user.email || null,
            email_verified: user.email_verified || false,
            name: user.name,
            nickname: user.nickname,
            picture: user.picture || null,
            sub: user.sub,
            updated_at: new Date(user.updated_at),
          });
        });
      } else {
        reject(new Error('no token saved'));
      }
    });
  }

  public getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  private setSession(authResult: Auth0DecodedHash): void {
    setSession(authResult);
  }
}

export default Auth;
