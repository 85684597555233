import { FunctionComponent, useEffect, useState } from 'react';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import { titleCase } from 'title-case';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { Education } from 'src/types/domain';
import { formatDate } from 'src/utils/functions/dateFormatters';

import { bakedDomains } from '../../../../../../utils/bakedDomains';
import { ImageWithFallback } from '../../../../../ImageWithFallback/ImageWithFallback';

import classes from './EducationItem.module.css';
export interface EducationItemProps {
  item: Education;
}

export const degreeTypeHash: Record<string, string> = {
  "associate's degree": 'Associate',
  "bachelor's degree": "Bachelor's",
  'doctoral degree': 'Doctorate',
  'high school diploma': 'High School Diploma or GED',
  "master's degree": "Master's",
};

export const EducationItem: FunctionComponent<EducationItemProps> = ({ item }) => {
  const [imageSource, setImageSource] = useState<string | undefined>('');

  useEffect(() => {
    if (item.school.name && bakedDomains[item.school.name]) {
      setImageSource(`//logo.clearbit.com/${bakedDomains[item.school.name]}?size=38`);
    }
  }, [item.school.name, imageSource]);

  const parsedGpa = parseFloat(item.gpa).toFixed(2);

  const extraInforamtion = `${degreeTypeHash[item.degree] || item.degree} | ${titleCase(
    item.major
  )}${parsedGpa !== 'NaN' ? ` | ${parsedGpa} gpa` : ''}`;
  return (
    <div className={classes.container}>
      <div className={classes.icon_container}>
        <ImageWithFallback
          imageSource={imageSource}
          fallbackIcon={<SchoolOutlined fontSize='small' />}
        />
      </div>
      <div className={classes.text_container}>
        <Text
          typographyType={TextTypographyType.BODY_2}
          text={titleCase(item.school.name)}
          color={'var(--seal-blue)'}
        />
        <Text
          typographyType={TextTypographyType.BODY_6}
          color={'var(--seal-blue)'}
          text={extraInforamtion}
        />
        <Text
          typographyType={TextTypographyType.BODY_6}
          color={'var(--seal-blue)'}
          text={`${formatDate(item.graduation)}`}
        />
      </div>
    </div>
  );
};
