import { FunctionComponent, memo, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Header } from 'src/components/Talent/components/TalentList/components/Header/Header';
import { Spinner } from 'src/shared/components/Spinner';
import { useGetTalentsQuery } from 'src/store/api/graphql/talents/getTalents';
import { useAppSelector } from 'src/store/hooks/hooks';

import { parseSearchParamsFilter } from '../../../../utils/functions/parseSearchParamsFilter';

import { EmptyMainList } from './components/EmptyMainList/EmptyMainList';
import { VirtualizedList } from './components/Virtualized/VirtualizedList';
import { clearTimeoutIfSet } from './utils/clearTimeoutIfSet';
import { TalentListWrapper } from './styles';

export const TalentListComponent: FunctionComponent = () => {
  const fetchTimeout = useRef<NodeJS.Timeout | null>(null);
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const [searchParams] = useSearchParams();
  const { currentData: talents, isLoading: isTalentsLoading } = useGetTalentsQuery(
    parseSearchParamsFilter(selectedJobId && +selectedJobId, searchParams),
    { skip: !selectedJobId }
  );

  useEffect(() => {
    const timeoutRef = fetchTimeout.current;

    return () => {
      clearTimeoutIfSet({ timeout: timeoutRef });
    };
  }, []);

  if (!selectedJobId || isTalentsLoading || !talents) {
    return (
      <TalentListWrapper>
        <Spinner />
      </TalentListWrapper>
    );
  }

  return (
    <TalentListWrapper>
      <Header applicantsAmount={talents.totalCount} />
      {talents.totalCount > 0 && !isTalentsLoading ? (
        <VirtualizedList talents={talents} />
      ) : (
        <EmptyMainList />
      )}
    </TalentListWrapper>
  );
};

export const TalentList = memo(TalentListComponent);
