import { DateRange } from 'react-day-picker';
import {
  addDays,
  addMilliseconds,
  endOfToday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { createEarliestPossibleDate } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/utils/createEarliestPossibleDate';

export interface PredefinedRange extends DateRange {
  label: string;
}

export const predefinedRangesConfiguration = (earliestDate: number): PredefinedRange[] => [
  {
    from: undefined,
    label: 'Custom',
    to: undefined,
  },
  {
    from: startOfToday(),
    label: 'Today',
    to: endOfToday(),
  },
  {
    from: createEarliestPossibleDate(earliestDate, startOfWeek(endOfToday(), { weekStartsOn: 1 })),
    label: 'This week',
    to: addMilliseconds(endOfToday(), -1),
  },
  {
    from: createEarliestPossibleDate(earliestDate, startOfMonth(new Date())),
    label: 'This month',
    to: addMilliseconds(endOfToday(), -2),
  },
  {
    from: createEarliestPossibleDate(earliestDate, startOfYear(new Date())),
    label: 'This year',
    to: addMilliseconds(endOfToday(), -3),
  },
  {
    from: createEarliestPossibleDate(earliestDate, addDays(endOfToday(), -30)),
    label: 'Last 30 days',
    to: addMilliseconds(endOfToday(), -4),
  },
  {
    from: createEarliestPossibleDate(earliestDate, addDays(endOfToday(), -90)),
    label: 'Last 3 months',
    to: addMilliseconds(endOfToday(), -5),
  },
  {
    from: new Date(earliestDate),
    label: 'All time',
    to: endOfToday(),
  },
];
