import { PayloadAction } from '@reduxjs/toolkit';

import { RouterParametersInitialState } from '../../../types/types';

export const setParametersAction = (
  state: RouterParametersInitialState,
  action: PayloadAction<Partial<RouterParametersInitialState>>
) => {
  state.jobId = (action.payload.jobId && +action.payload.jobId) || null;
  state.listType = action.payload.listType || null;
  state.talentId = action.payload.talentId || null;
};
