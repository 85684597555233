import {
  additionalInfoPredicate,
  mustHavesPredicate,
  niceToHavePredicate,
} from 'src/components/Talent/utils';
import { ChatSummary, QuestionLabels } from 'src/types/domain';

export interface QuestionsArgsHook {
  chatSummaries: ChatSummary[];
}

export interface Section {
  label: QuestionLabels;
  items: ChatSummary[];
}

export interface QuestionsResultHook {
  sections: Section[];
  empty: boolean;
}

export const useQuestions = ({ chatSummaries }: QuestionsArgsHook): QuestionsResultHook => {
  const knockOuts = chatSummaries.filter(mustHavesPredicate);

  const niceToHave = chatSummaries.filter(niceToHavePredicate);

  const additionalInfo = chatSummaries?.filter(additionalInfoPredicate);

  const nothingIsAnswered = chatSummaries.every(summary => !summary.value && !summary.values);

  return {
    empty: nothingIsAnswered,
    sections: [
      {
        items: knockOuts,
        label: QuestionLabels.KNOCK_OUTS,
      },
      {
        items: niceToHave,
        label: QuestionLabels.NICE_TO_HAVE,
      },
      {
        items: additionalInfo,
        label: QuestionLabels.ADDITIONAL_INFO,
      },
    ],
  };
};
