import { HTMLAttributes } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Option } from 'src/components/Talent/components/TalentProfile/components/TalentActionModal/components/ReasonSelection/ReasonSelection';

export const highlightListItem = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Option,
  inputValue: string
) => {
  const matches = match(option.label, inputValue, { insideWords: true });
  const parts = parse(option.label, matches);

  return (
    <li {...props}>
      {parts.map((part, index) => (
        <span key={index} className={part.highlight ? 'highlight' : ''}>
          {part.text[0] === ' ' && <>&nbsp;</>}
          {part.text.trim()}
          {part.text[part.text.length - 1] === ' ' && <>&nbsp;</>}
        </span>
      ))}
    </li>
  );
};
