import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as Meh } from 'src/assets/Meh.svg';

export const ErrorFallback = (): JSX.Element => {
  const onClickHandler = () => {
    window.location.reload();
  };

  return (
    <div className='w-flex-centered w-full-height'>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Meh />
        <Typography variant='subHeading1'>Sorry, something went wrong</Typography>
        <Button variant='contained' onClick={onClickHandler} style={{ margin: '0 auto' }}>
          Back Home
        </Button>
      </div>
    </div>
  );
};
