import React, { FC, useContext } from 'react';
import Button from '@mui/material/Button';

import { DateContext } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';
interface ActionsProps {
  onClose: () => void;
}

export const Actions: FC<ActionsProps> = ({ onClose }) => {
  const { range, startDayInputError, endDayInputError, updateDateFilter } = useContext(DateContext);

  const applyClickHandler = () => {
    updateDateFilter();
    onClose();
  };

  return (
    <div style={{ display: 'flex', gap: '12px', justifyContent: 'end' }}>
      {' '}
      <Button variant='text' onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant='contained'
        disabled={
          startDayInputError ||
          endDayInputError ||
          !range ||
          range.from === undefined ||
          range.to === undefined
        }
        onClick={applyClickHandler}
      >
        Apply
      </Button>
    </div>
  );
};
