import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export const useSetFeatureFlagContext = (context: any) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && context?.email) {
      const LDContext = { kind: 'user', key: context?.sub || context.email, ...context };

      ldClient.identify(LDContext);
    }
  }, [ldClient, context]);
};
