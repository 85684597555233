import { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

import { SelectedDateInput } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

export const updateMonth = (
  range: DateRange | undefined,
  selectedDateInput: SelectedDateInput,
  setMonth: Dispatch<SetStateAction<Date>>
) => {
  if (!range) {
    setMonth(new Date());
  }

  try {
    range && range.from && range.from.getTime();
    range && range.to && range.to.getTime();
  } catch {
    return;
  }

  if (selectedDateInput === SelectedDateInput.START && range && range.from) {
    setMonth(range.from);
  } else if (range && range.to) {
    setMonth(range.to);
  }
};
