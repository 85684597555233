import { FunctionComponent } from 'react';

import { AdditionalInfo } from 'src/components/JobsInfoHeader/components/AdditionalInfo/AdditionalInfo';
import { LongTextSupport, Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { useGetArchivedJobsQuery } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { useGetLiveJobsQuery } from 'src/store/api/graphql/jobs/getLiveJobs';
import { useAppSelector } from 'src/store/hooks/hooks';

import {
  JobsInfoHeaderAdditionalInfo,
  JobsInfoHeaderTitle,
  JobsInfoHeaderTitleSeparator,
  JobsInfoHeaderWrapper,
} from './styled';

export const JobsInfoHeader: FunctionComponent = () => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { job: liveJob } = useGetLiveJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const { job: archivedJob } = useGetArchivedJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const selectedJob = liveJob || archivedJob;

  if (selectedJob === undefined) {
    return <></>;
  }

  const jobInfoHeaderAdditionalInfoList = [
    selectedJob.externalId,
    selectedJob.attributes.location_city,
    selectedJob.attributes.location_state,
  ];

  return (
    <JobsInfoHeaderWrapper>
      <JobsInfoHeaderTitle>
        <Text
          style={{ minWidth: 'max-content' }}
          text='Applicants'
          typographyType={TextTypographyType.TITLE}
          color='var(--blumine)'
        />
        <JobsInfoHeaderTitleSeparator />
        <LongTextSupport
          textProps={{
            color: 'var(--seal-blue)',
            typographyType: TextTypographyType.BODY_2,
          }}
          text={selectedJob.name}
        />
      </JobsInfoHeaderTitle>

      <JobsInfoHeaderTitleSeparator />

      <JobsInfoHeaderAdditionalInfo>
        <AdditionalInfo additionalInfoList={jobInfoHeaderAdditionalInfoList} />
      </JobsInfoHeaderAdditionalInfo>
    </JobsInfoHeaderWrapper>
  );
};
