import { parsePhoneNumberWithError, PhoneNumber } from 'libphonenumber-js';

import { Candidate, Education, WorkExperience } from 'src/types/domain';

export interface ExtractDetailsReturn {
  linkedInUrl: string | null;
  workExperiences: WorkExperience[] | null;
  educations: Education[] | null;
  email: string | null;
  address: string | null;
  phone: string | PhoneNumber | null;
}

export const extractDetails = (details: Candidate): ExtractDetailsReturn => {
  const linkedInUrl =
    details.person.defaultProfile && details.person.defaultProfile.network === 'linkedin'
      ? details.person.defaultProfile.url
      : null;
  const workExperiences = [
    ...details.person.workExperience.map(item => ({
      ...item,
      isCurrent: false,
    })),
  ];

  if (details.person.currentWorkExperience) {
    const indexOfCurrentWorkExperience = workExperiences.findIndex(
      exp =>
        details.person.currentWorkExperience &&
        exp.neoId === details.person.currentWorkExperience.neoId
    );

    if (indexOfCurrentWorkExperience > -1) {
      workExperiences[indexOfCurrentWorkExperience].isCurrent = true;
    }
  }

  const educations = details.person.education;
  const email = details.person.email;
  const address = details.person.address?.address || null;

  let phone: PhoneNumber | string = '';
  const phoneString = details.person.primaryPhone;

  if (phoneString) {
    try {
      phone = parsePhoneNumberWithError(phoneString, 'US');
    } catch (err) {
      phone = '';
    }
  }

  return {
    address,
    educations,
    email,
    linkedInUrl,
    phone,
    workExperiences,
  };
};
