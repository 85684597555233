import { createSlice } from '@reduxjs/toolkit';

import { RouterParametersInitialState } from '../../types/types';

import { setParametersAction } from './actions/setParameters';
import { setScrollEffectsAction } from './actions/setScrollEffects';

export const routerParametersInitialState: RouterParametersInitialState = {
  jobId: null,
  listType: null,
  scrollJob: true,
  scrollTalent: true,
  talentId: null,
};

export const routerParameters = createSlice({
  initialState: routerParametersInitialState,
  name: 'routerParameters',
  reducers: {
    setParameters: setParametersAction,
    setScrollEffects: setScrollEffectsAction,
  },
});

export const { setParameters, setScrollEffects } = routerParameters.actions;

export default routerParameters.reducer;
