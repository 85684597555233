import { gql } from 'graphql-request';

import { optimisticSetOnHoldStatus } from 'src/store/api/graphql/talents/utils/optimisticSetOnHoldStatus';
import { RootState } from 'src/store/store';

import { graphqlApi } from '../graphql';

export const extendedGraphqlApiWithSetOnHoldStatus = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    setOnHoldStatus: builder.mutation({
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) =>
        await optimisticSetOnHoldStatus({
          arg,
          dispatch,
          queryFulfilled,
          state: getState() as RootState,
        }),
      query: variables => ({
        document: gql`
          mutation ($candidateId: ID!, $onHold: Boolean) {
            updateOpportunityOnHold(id: $candidateId, onHold: $onHold) {
              actionDate
              recruiterInfo {
                firstName
                lastName
              }
            }
          }
        `,
        variables,
      }),
    }),
  }),
});

export const { useSetOnHoldStatusMutation } = extendedGraphqlApiWithSetOnHoldStatus;
