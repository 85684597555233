import { FilterLabel } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { GetTalentsVariables, InboxValues } from 'src/store/api/graphql/talents/getTalents';
import { ChatStatus, Score } from 'src/types/domain';
import { formatChatStatusParams } from 'src/utils/functions/formatChatParams';
import { formatScoreParams } from 'src/utils/functions/formatScoreParams';

import { formatBooleanParams } from './formatBooleanParams';
import { formatDateRangeParams } from './formatDateRangeParams';
import { formatInboxParams } from './formatInboxParams';

export const parseSearchParamsFilter = (
  jobId: number | null,
  searchParams: URLSearchParams
): GetTalentsVariables => ({
  filter: {
    applyOn: formatDateRangeParams(searchParams.getAll(FilterLabel.APPLY_ON)),
    chatStatus: formatChatStatusParams({
      chatStatusParams: searchParams.getAll(FilterLabel.CHAT) as ChatStatus[],
    }),
    hasNotes: formatBooleanParams(searchParams.getAll(FilterLabel.NOTES)),
    inboxes: formatInboxParams({
      talentListParams: searchParams.getAll(FilterLabel.STATUS) as InboxValues[],
    }),
    onHold: formatBooleanParams(searchParams.getAll(FilterLabel.ON_HOLD)),
    score: formatScoreParams({ score: searchParams.getAll(FilterLabel.SCORE) as Score[] }),
  },
  jobId,
});
