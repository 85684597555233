import { FC } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { InfoDescriptionAwards } from '../../styled';

interface AwardsProps {
  linkedInUrl?: string | null;
}

export const Awards: FC<AwardsProps> = ({ linkedInUrl }) => {
  if (!linkedInUrl) {
    return null;
  }

  return (
    <InfoDescriptionAwards>
      {linkedInUrl && (
        <div
          className='description--social'
          data-testid='linked-in'
          onClick={() => window.open(linkedInUrl, '_blank')}
        >
          <LinkedInIcon className='candidate-icon linkedin-icon' />
        </div>
      )}
    </InfoDescriptionAwards>
  );
};
