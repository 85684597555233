import React, { FC } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { formatDistance } from 'date-fns';

import { gray30, gray40 } from 'src/shared/components/theme/mui/colors/colors';
import { Note as NoteType } from 'src/types/domain';

interface NoteProps {
  note: NoteType;
}

export const Note: FC<NoteProps> = ({ note }) => (
  <Box
    sx={{
      border: `1px solid ${gray30}`,
      borderRadius: '5px',
      marginTop: '20px',
      padding: '16px',
      width: '100%',
    }}
  >
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={2}
        sx={{ width: '100%' }}
      >
        <Typography variant='body2'>
          {note.recruiterInfo.firstName} {note.recruiterInfo.lastName}
        </Typography>
        <Typography variant='smallt1' sx={{ color: gray40 }}>
          {formatDistance(new Date(note.created), new Date(), { addSuffix: true })}
        </Typography>
      </Stack>
      <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {note.message}
      </Typography>
    </Stack>
  </Box>
);
