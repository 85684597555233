import { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

import { predefinedRangesConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/utils/predefinedRangesConfiguration';

export const findSelectedItem = (
  range: DateRange | undefined,
  earliestDate: number,
  setSelectedIndex: Dispatch<SetStateAction<number>>
) => {
  const indexOfSelected = predefinedRangesConfiguration(earliestDate).findIndex(
    item =>
      range &&
      item.from?.getTime() === range.from?.getTime() &&
      item.to?.getTime() === range.to?.getTime()
  );

  if (indexOfSelected > -1) {
    setSelectedIndex(indexOfSelected);
  } else {
    setSelectedIndex(0);
  }
};
