import { PayloadAction } from '@reduxjs/toolkit';

import { RejectReason } from '../../../../types/domain';
import { TalentModalState } from '../../../types/types';

export const setActionReasonAction = (
  state: TalentModalState,
  action: PayloadAction<RejectReason | null>
) => {
  state.action.reason = action.payload;
};
