import { useEffect } from 'react';
import { push } from 'redux-first-history';

import { routePaths } from 'src/constants';
import { useGetArchivedJobsQuery } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { useGetLiveJobsQuery } from 'src/store/api/graphql/jobs/getLiveJobs';
import { useAppDispatch, useAppSelector } from 'src/store/hooks/hooks';
import { setSelectedMenu } from 'src/store/slices/jobs/jobs';
import { MenuType } from 'src/store/types/types';
import { Job } from 'src/types/jobs';
export interface JobsContainerHookResult {
  liveJobs?: Job[];
  archivedJobs?: Job[];
  isLoadingArchivedJobs: boolean;
  isLoadingLiveJobs: boolean;
}

export const useJobsContainer = (): JobsContainerHookResult => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const dispatch = useAppDispatch();
  const { currentData: liveJobs, isLoading: isLoadingLiveJobs } = useGetLiveJobsQuery(undefined);
  const { currentData: archivedJobs, isLoading: isLoadingArchivedJobs } =
    useGetArchivedJobsQuery(undefined);

  useEffect(() => {
    if (isLoadingLiveJobs || isLoadingArchivedJobs || !liveJobs || !archivedJobs) {
      return;
    }

    const selectedLiveJob = liveJobs.find(job => job.id === selectedJobId);
    const selectedArchivedJob = archivedJobs.find(job => job.id === selectedJobId);

    //when the job isnt found, push to first liveJob OR archvied if liveJobs are empty
    if (!selectedLiveJob && !selectedArchivedJob) {
      /* istanbul ignore else  */
      if (liveJobs.length > 0) {
        dispatch(push(routePaths.jobsWithId(liveJobs[0].id)));
        dispatch(setSelectedMenu(MenuType.OPEN));
      } else if (archivedJobs.length > 0) {
        dispatch(setSelectedMenu(MenuType.CLOSED));
        dispatch(push(routePaths.jobsWithId(archivedJobs[0].id)));
      }
      //if the job is found, set the menu type to match the found job
    } else {
      if (selectedLiveJob) {
        dispatch(setSelectedMenu(MenuType.OPEN));
      }

      if (selectedArchivedJob) {
        dispatch(setSelectedMenu(MenuType.CLOSED));
      }
    }
  }, [isLoadingLiveJobs, liveJobs, isLoadingArchivedJobs, archivedJobs, selectedJobId, dispatch]);

  return {
    archivedJobs,
    isLoadingArchivedJobs,
    isLoadingLiveJobs,
    liveJobs,
  };
};
