import { blue70 } from '../colors/colors';

export const MuiAlert = {
  styleOverrides: {
    message: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: '13px',
      justifyContent: 'space-between',
      minWidth: '175px',
      padding: '10px 12px 10px 12px',
      width: '100%',
    },
    standardInfo: {
      backgroundColor: blue70,
      borderRadius: '1px',
      color: 'white',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
      minWidth: '175px',
      padding: '0px',
    },
  },
} as const;
