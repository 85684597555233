import React, { FunctionComponent } from 'react';

import { ReactComponent as CheckCircle } from 'src/assets/CheckCircle.svg';
import { ReactComponent as RejectCircle } from 'src/assets/RejectCircle.svg';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';

import { AnswerType } from '../Question';

import classes from './QuestionWithFewAnswers.module.scss';

export interface QuestionWithFewAnswersProps {
  title: string;
  multiSelectQAndA: string[][];
}

export const QuestionWithFewAnswers: FunctionComponent<QuestionWithFewAnswersProps> = ({
  multiSelectQAndA,
  title,
}) => {
  const getIcon = (answer: string) => {
    if (answer.toLowerCase() === AnswerType.YES) {
      return <CheckCircle />;
    }

    if (answer.toLowerCase() === AnswerType.NO) {
      return <RejectCircle />;
    }

    return null;
  };

  return (
    <div className={classes['question-with-few-answers']}>
      <Text text={title + ':'} typographyType={TextTypographyType.SH_3} color={'var(--blumine)'} />
      {multiSelectQAndA.map(([question, answer]) => {
        const needSemicolon = question[question.length - 1] !== '?';

        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              gap: 14,
            }}
            key={answer + question}
          >
            {getIcon(answer)}
            <Text
              text={question + `${needSemicolon ? ':' : ''}`}
              typographyType={TextTypographyType.SH_3}
              color={'var(--blumine)'}
            />
            <Text
              text={answer}
              typographyType={TextTypographyType.BODY_1}
              color={'var(--blumine)'}
            />
          </div>
        );
      })}
    </div>
  );
};
