import React, { FC } from 'react';

import { TalentInfoSectionSeparator } from 'src/components/Resume/styles/styles';
import { Candidate } from 'src/types/domain';

import { AppliedOn } from './components/AppliedOn/AppliedOn';
import { BasicInfo } from './components/BasicInfo/BasicInfo';
import { Education } from './components/Education/Education';
import { WorkExperience } from './components/WorkExperience/WorkExperience';

import classes from './Details.module.css';
interface DetailsProps {
  candidate: Candidate;
}

export const Details: FC<DetailsProps> = ({ candidate }) => (
  <div className={classes.container}>
    <AppliedOn
      ts={
        candidate.initialStatus.created ? new Date(candidate.initialStatus.created).getTime() : null
      }
    />
    <BasicInfo candidate={candidate} />
    <WorkExperience candidate={candidate} />
    <Education candidate={candidate} />
    <TalentInfoSectionSeparator style={{ marginTop: 16 }} />
  </div>
);
