export const transformChatSummaryMultiSelect = (questionValues: string[]) => {
  let multiSelect: string[][] = [];
  let tempMultiSelectQAndA: string[] = [];

  questionValues.forEach(value => {
    tempMultiSelectQAndA.push(value);

    if (tempMultiSelectQAndA.length > 1) {
      multiSelect.push(tempMultiSelectQAndA);

      tempMultiSelectQAndA = [];
    }
  });

  multiSelect = multiSelect?.filter(
    ([q, a]) =>
      !(a.trim().toLowerCase() === 'none of these' || q.trim().toLowerCase() === 'none of these')
  );

  return multiSelect;
};
