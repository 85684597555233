import { Dispatch, FC, SetStateAction, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { addMonths, endOfToday } from 'date-fns';

import { ReactComponent as ContainedLeftArrow } from 'src/assets/ArrowLeftContained.svg';
import { ReactComponent as ContainedRightArrow } from 'src/assets/ArrowRightContained.svg';
import { DateContext } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

import classes from './Navigation.module.css';

interface NavigationProps {
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
}

export const Navigation: FC<NavigationProps> = ({ month, setMonth }) => {
  const { earliestDate } = useContext(DateContext);

  return (
    <div className={classes.bar}>
      <IconButton
        size='small'
        disabled={month < new Date(earliestDate)}
        onClick={() => {
          setMonth(addMonths(month, -1));
        }}
      >
        <ContainedLeftArrow className={'navigation-svg-icons'} />
      </IconButton>
      <IconButton
        size='small'
        disabled={addMonths(month, 1) > endOfToday()}
        onClick={() => {
          setMonth(addMonths(month, 1));
        }}
      >
        <ContainedRightArrow className={'navigation-svg-icons'} />
      </IconButton>
    </div>
  );
};
