import { autocompleteClasses } from '@mui/material/Autocomplete';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';

import {
  blue50,
  gray20,
  gray30,
  gray40,
  gray60,
  highlightText,
} from 'src/shared/components/theme/mui/colors/colors';

export const MuiAutocomplete = {
  styleOverrides: {
    groupLabel: {
      backgroundColor: gray20,
      borderLeft: `4px solid ${gray40}`,
      color: gray60,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16.8px',
      padding: '8px 10px 8px 6px',
    },
    groupUl: {
      color: gray60,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18.2px',
    },
    listbox: {
      marginTop: '-1px',
      padding: '0px 0px',
    },
    noOptions: {
      color: gray60,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18.2px',
    },
    option: {
      '& .highlight': {
        backgroundColor: highlightText,
      },
      '&:hover': {
        '& .highlight': {
          backgroundColor: blue50 + '!important',
        },
        'backgroundColor': blue50 + '!important',
        'color': 'white!important',
      },
      'borderTop': `1px solid ${gray30}`,
      'color': gray60,
      'fontSize': '14px',
      'fontWeight': 400,
      'lineHeight': '18.2px',
      [`&.${autocompleteClasses.focused}`]: {
        '& .highlight': {
          backgroundColor: blue50 + '!important',
        },
        'backgroundColor': blue50 + '!important',
        'color': 'white!important',
      },
      'padding': '8px 8px 8px 12px!important',
    },
    root: {
      '& label': {
        color: gray60,
        fontHeight: '18.2px',
        fontSize: '14px',
        fontWeight: 400,
      },
      [`& .${inputLabelClasses.shrink}`]: {
        display: 'none!important',
      },
      '& legend': {
        display: 'none!important',
      },
      [`& .${inputBaseClasses.root}`]: {
        '& fieldset': {
          border: 'none',
        },
        'backgroundColor': 'white',
        'border': '1px solid var(--cyan)',
        'fontSize': '14px',
        'fontWeight': 400,
        'lineHeight': '16px',
        'paddingLeft': '12px',
        'paddingRight': '10px',
      },
      [`& .${inputBaseClasses.root}.${inputBaseClasses.focused}`]: {
        border: '1px solid var(--river-blue)',
      },
      [`& .${inputBaseClasses.root}.${inputBaseClasses.error}`]: {
        border: '1px solid var(--rust)',
      },
      [`& .${inputBaseClasses.adornedStart}, .${inputBaseClasses.adornedEnd}`]: {
        color: 'var(--seal-blue)',
      },
      [`& .${inputBaseClasses.input}`]: {
        color: 'var(--seal-blue)',
      },
      [`& .${inputBaseClasses.input}::placeholder`]: {
        color: gray60,
      },
      [`& .${inputBaseClasses.disabled}`]: {
        [`& .${inputBaseClasses.adornedStart}`]: {
          color: 'var(--gray-cloud)',
        },
        borderColor: '#DDDDDD',
        color: 'var(--gray-cloud)',
      },
      [`& .${formHelperTextClasses.root}`]: {
        color: 'var(--seal-blue)',
        margin: 0,
        paddingTop: '15.33px',
      },
    },
  },
};
