import { PayloadAction } from '@reduxjs/toolkit';

import { RouterParametersInitialState } from 'src/store/types/types';

export interface SetScrollEffectsAction {
  scrollJob?: boolean;
  scrollTalent?: boolean;
}

export const setScrollEffectsAction = (
  state: RouterParametersInitialState,
  action: PayloadAction<SetScrollEffectsAction>
) => {
  if (action.payload.scrollJob !== undefined) {
    state.scrollJob = action.payload.scrollJob || false;
  }

  if (action.payload.scrollTalent !== undefined) {
    state.scrollTalent = action.payload.scrollTalent;
  }
};
