import { ReactNode } from 'react';
import { Root } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

export const renderWithFeatureFlags = async ({
  root,
  children,
}: {
  root: Root;
  children: ReactNode;
}) => {
  const launchDarklyClientId = process.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

  if (launchDarklyClientId === undefined) {
    root.render(children);
    return;
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
    context: {
      kind: 'user',
      anonymous: true,
      key: 'pandoselect-anonymous-1849f2c2-47fc-4d34-917c-e605c205b295',
    },
  });

  root.render(<LDProvider>{children}</LDProvider>);
};
