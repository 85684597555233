import { FC } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuItem from '@mui/material/MenuItem';

import { HeaderTypography } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Main/styled';
import {
  FilterConfiguration,
  FilterProperty,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';

export interface MainProps {
  config: FilterConfiguration;
  selectValue: (filterProperty: FilterProperty) => void;
}

export const Main: FC<MainProps> = ({ config, selectValue }) => (
  <>
    <HeaderTypography variant='body2' align='left'>
      {config.header}
    </HeaderTypography>
    {config.values.map((value, index) => (
      <MenuItem
        key={value.header.label}
        onClick={() => selectValue(value)}
        divider={index < config.values.length - 1}
      >
        {value.header.label}
        <ArrowForwardIosIcon fontSize='small' />
      </MenuItem>
    ))}
  </>
);
