import styled from 'styled-components';

export const JobsInfoHeaderWrapper = styled.div`
  z-index: var(--layer-top);
  top: 0;

  display: flex;
  align-items: center;

  height: var(--appbar-height);
  padding: 0 1.875rem;

  background-color: white;
  box-shadow: 0 1px 8px rgba(0 0 0 / 10%);
`;

export const JobsInfoHeaderTitle = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

export const JobsInfoHeaderAdditionalInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

export const JobsInfoHeaderTitleSeparator = styled.span`
  min-height: 54px !important;
  margin: 0 10.5px;
  text-align: center;
  border-left: 1px solid #ddd;
`;
