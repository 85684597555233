import { CSSProperties, useEffect, useRef, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, FixedSizeList as List } from 'react-window';

import { routePaths } from '../../../../../../constants';
import { extendedGraphqlApiWithGetCandidate } from '../../../../../../store/api/graphql/talents/getCandidate';
import { GetTalentsData } from '../../../../../../store/api/graphql/talents/getTalents';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/hooks';
import { setScrollEffects } from '../../../../../../store/slices/routerParameters/routerParameters';
import { store } from '../../../../../../store/store';
import { Talent } from '../../../../../../types/domain';
import { clearTimeoutIfSet } from '../../utils/clearTimeoutIfSet';
import { hoverPrefetch } from '../../utils/hoverPrefetch';
import { Item } from '../Item/Item';

import classes from './VirtualizedList.module.css';

export const VirtualizedList = ({ talents }: { talents: GetTalentsData }) => {
  /* ItemComponent provides ref
     if in a future we need dynamic height we can use wrapperRef.current.getBoundingClientRect().height */
  const ITEM_SIZE = 77;

  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);
  const scrollIsActive = useAppSelector(state => state.routerParameters.scrollTalent);

  const fetchTimeout = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const timeoutRef = fetchTimeout.current;

    return () => {
      clearTimeoutIfSet({ timeout: timeoutRef });
    };
  }, []);
  const [element, setElement] = useState<FixedSizeList<Talent[]> | null>(null);

  useEffect(() => {
    if (selectedTalentId) {
      const itemIndex = talents?.items?.findIndex(item => item.id === +selectedTalentId);

      if (scrollIsActive && element && itemIndex > -1) {
        element.scrollToItem(itemIndex, 'start');
        dispatch(setScrollEffects({ scrollTalent: false }));
      }
    }
  }, [scrollIsActive, selectedTalentId, element, talents?.items, dispatch]);

  const talentListItem = (index: number, talent: Talent, style: CSSProperties) => (
    <NavLink
      style={style}
      data-testid={`nav-link-${talent.id}`}
      key={talent.id}
      onMouseEnter={() => {
        fetchTimeout.current = hoverPrefetch({
          dispatch,
          fetchAction: extendedGraphqlApiWithGetCandidate.endpoints.getCandidate.initiate({
            id: talent.id,
          }),
          isNotFetched: extendedGraphqlApiWithGetCandidate.endpoints.getCandidate.select({
            id: talent.id,
          })(store.getState()).isUninitialized,
          timeout: fetchTimeout.current,
        });
      }}
      onMouseLeave={() => {
        clearTimeoutIfSet({
          timeout: fetchTimeout.current,
        });
      }}
      to={`${routePaths.jobsWithIdListTypeTalentid(
        selectedJobId,
        'talents',
        talent.id
      )}?${searchParams}`}
    >
      <Item
        key={`item-${talent.id}`}
        isLast={index === talents.totalCount - 1}
        talent={talent}
        isSelectedItem={Boolean(selectedTalentId && talent.id === +selectedTalentId)}
      />
    </NavLink>
  );
  return (
    <div className={classes.TalentTabContent__talent_list_items}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            itemKey={(index, item) => item[index].id}
            height={height}
            ref={setElement}
            width={width}
            itemData={talents.items}
            itemCount={talents.items.length}
            itemSize={ITEM_SIZE}
          >
            {({ data, index, style }) => talentListItem(index, data[index], style)}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};
