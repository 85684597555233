import { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { ChatSummary } from 'src/types/domain';

import { Question } from './components/Question/Question';
import { QuestionWithFewAnswers } from './components/QuestionWithFewAnswers/QuestionWithFewAnswers';
import { transformChatSummaryMultiSelect } from './helpers';
import { QuestionsContainerWrapper, QuestionsWrapper } from './styled';

export interface SectionProps {
  title: string;
  questionsList?: ChatSummary[];
}

export const Section: FunctionComponent<SectionProps> = ({ questionsList, title }) => (
  <QuestionsContainerWrapper>
    <Text text={title} typographyType={TextTypographyType.H_2} color={'var(--seal-blue)'} />
    <QuestionsWrapper>
      {questionsList?.map(question => {
        if (question.values) {
          return (
            <QuestionWithFewAnswers
              key={uuidv4()}
              title={question.shortLabel}
              multiSelectQAndA={transformChatSummaryMultiSelect(question.values).sort((a, b) => {
                if (a[1].toLowerCase() === 'yes') {
                  return -1;
                }

                if (b[1].toLowerCase() === 'yes') {
                  return 1;
                }

                return 0;
              })}
            />
          );
        }

        if (question.value) {
          return (
            <Question
              key={uuidv4()}
              failed={question.knockout}
              question={question.shortLabel}
              answer={question.value}
            />
          );
        }

        return null;
      })}
    </QuestionsWrapper>
  </QuestionsContainerWrapper>
);
