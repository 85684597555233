import { gql } from 'graphql-request';

import { optimisticResetChatStatus } from 'src/store/api/graphql/talents/utils/optimisticResetChatStatus';
import { RootState } from 'src/store/store';

import { graphqlApi } from '../graphql';

export const extendedGraphqlApiWithResetChatStatus = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    resetChatStatus: builder.mutation({
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) =>
        await optimisticResetChatStatus({
          arg,
          dispatch,
          queryFulfilled,
          state: getState() as RootState,
        }),
      query: variables => ({
        document: gql`
          mutation ($candidateId: ID!) {
            resetOpportunityChat(id: $candidateId)
          }
        `,
        variables,
      }),
    }),
  }),
});

export const { useResetChatStatusMutation } = extendedGraphqlApiWithResetChatStatus;
