import { FC } from 'react';
import { PhoneNumber } from 'libphonenumber-js';

import { LongTextSupport, Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';

import { DetailsColumn, TalentBasicInfoItem } from '../../../../styles';

import classes from './Content.module.css';
export interface ContentProps {
  linkedInUrl: string | null;
  email: string | null;
  address: string | null;
  phone: string | PhoneNumber | null;
}

export const Content: FC<ContentProps> = ({ email, address, phone, linkedInUrl }) => (
  <DetailsColumn className={classes.container}>
    {phone && (
      <TalentBasicInfoItem>
        <Text
          text={'Phone'}
          typographyType={TextTypographyType.BODY_2}
          color={'var(--seal-blue)'}
        />

        <a
          style={{ height: 'min-content' }}
          href={typeof phone === 'string' ? phone : phone?.getURI()}
        >
          <LongTextSupport
            textProps={{
              asLink: true,
              color: 'var(--seal-blue)',
              typographyType: TextTypographyType.BODY_6,
            }}
            text={typeof phone === 'string' ? phone : phone?.format('NATIONAL')}
          />
        </a>
      </TalentBasicInfoItem>
    )}

    {address && (
      <TalentBasicInfoItem>
        <Text
          text={'Address'}
          typographyType={TextTypographyType.BODY_2}
          color={'var(--seal-blue)'}
        />
        <LongTextSupport
          text={address}
          textProps={{
            color: 'var(--seal-blue)',
            typographyType: TextTypographyType.BODY_6,
          }}
        />
      </TalentBasicInfoItem>
    )}

    {linkedInUrl && (
      <TalentBasicInfoItem>
        <Text
          text={'LinkedIn'}
          typographyType={TextTypographyType.BODY_2}
          color={'var(--seal-blue)'}
        />
        <a target='_blank' rel='noopener noreferrer' href={linkedInUrl}>
          <LongTextSupport
            textProps={{
              asLink: true,
              color: 'var(--seal-blue)',
              typographyType: TextTypographyType.BODY_6,
            }}
            text={linkedInUrl}
          />
        </a>
      </TalentBasicInfoItem>
    )}

    {email && (
      <TalentBasicInfoItem>
        <Text
          text={'Email'}
          typographyType={TextTypographyType.BODY_2}
          color={'var(--seal-blue)'}
        />
        <a href={`mailto:${email}`}>
          <LongTextSupport
            textProps={{
              asLink: true,
              color: 'var(--seal-blue)',
              typographyType: TextTypographyType.BODY_6,
            }}
            text={email}
          />
        </a>
      </TalentBasicInfoItem>
    )}
  </DetailsColumn>
);
