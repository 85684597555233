import { Fragment, FunctionComponent } from 'react';

import { LongTextSupport } from '../../../../shared/components';
import { TextTypographyType } from '../../../../shared/components/api';

import { Dot } from './styled';

export interface AdditionalInfoProps {
  additionalInfoList: string[];
}

export const AdditionalInfo: FunctionComponent<AdditionalInfoProps> = ({ additionalInfoList }) => (
  <>
    {additionalInfoList.length > 0 &&
      additionalInfoList.map((infoItem, id) => (
        <Fragment key={id}>
          <LongTextSupport
            textProps={{
              color: 'var(--seal-blue)',
              typographyType: TextTypographyType.BODY_1,
            }}
            text={infoItem}
          />
          {additionalInfoList[id + 1] && <Dot>{'\u2B24'}</Dot>}
        </Fragment>
      ))}
  </>
);
