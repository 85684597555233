import { FC, ReactNode } from 'react';

interface TextWrapperProps {
  children?: ReactNode;
}

const TextErrorWrapper: FC<TextWrapperProps> = ({ children }) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '7px',
      overflow: 'hidden',
    }}
  >
    {children}
  </div>
);

export { TextErrorWrapper };
