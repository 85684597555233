import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from 'src/contexts/AuthContext';

export const RequireAuth = ({
  children,
  redirectTo = '/',
}: {
  children: JSX.Element;
  redirectTo?: string;
}): JSX.Element | null => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated() ? children : <Navigate to={redirectTo} />;
};
