import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import Tooltip from '@mui/material/Tooltip';

import { Text, TextProps } from '../Text';

import { getTextWidth, getWrapperWidth } from './helpers';

import classes from './LongTextSupport.module.scss';

export interface LongTextSupportProps {
  text: string;
  textProps?: Omit<TextProps, 'text'>;
  wrapper?: Pick<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'className' | 'style'
  >;
}

export const LongTextSupport = forwardRef<HTMLElement, LongTextSupportProps>(
  ({ text, textProps, wrapper }, ref) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const textRef = useRef<HTMLParagraphElement | null>(null);

    const [needTooltip, setNeedTooltip] = useState(false);
    const [isTextOnHover, setIsTextOnHover] = useState(false);

    const onMouseOverText = useCallback(() => {
      const wrapperLength = getWrapperWidth(wrapperRef);
      const textLength = getTextWidth(textRef);

      if (!wrapperLength || !textLength) {
        return;
      }

      if (textLength > wrapperLength) {
        setIsTextOnHover(true);
        setNeedTooltip(true);
      } else {
        setIsTextOnHover(false);
        setNeedTooltip(false);
      }
    }, []);

    const onMouseOutText = useCallback(() => setIsTextOnHover(false), []);

    return (
      <Tooltip ref={ref} placement={'bottom'} title={isTextOnHover && needTooltip ? text : ''}>
        <div ref={wrapperRef} className={classes['long-text-hover__wrapper']} {...wrapper}>
          <Text
            ref={textRef}
            style={{ display: 'inline' }}
            onMouseOver={onMouseOverText}
            onMouseOut={onMouseOutText}
            text={text}
            {...textProps}
          />
        </div>
      </Tooltip>
    );
  }
);

LongTextSupport.displayName = 'LongTextSupport';
