import { Dispatch } from 'react';
import { ThunkAction } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { AnyAction } from 'redux';

import { GetCandidateVariables } from 'src/store/api/graphql/talents/getCandidate';
import { Candidate } from 'src/types/domain';

import { clearTimeoutIfSet } from './clearTimeoutIfSet';
export interface HoverPrefetchParams {
  timeout: NodeJS.Timeout | null;
  isNotFetched: boolean;
  dispatch: Dispatch<any>;
  fetchAction: ThunkAction<
    QueryActionCreatorResult<
      QueryDefinition<
        GetCandidateVariables,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
        never,
        Candidate,
        'workspaceApi'
      >
    >,
    any,
    any,
    AnyAction
  >;
}

export const hoverPrefetch = ({
  timeout,
  isNotFetched,
  dispatch,
  fetchAction,
}: HoverPrefetchParams) => {
  clearTimeoutIfSet({ timeout });

  if (isNotFetched) {
    return setTimeout(() => {
      dispatch(fetchAction);
    }, 250);
  }

  return null;
};
