import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { blue50 } from 'src/shared/components/theme/mui/colors/colors';

export const HeaderTypography = styled(Typography)`
  display: flex;
  align-items: center;

  height: 40px;
  padding: 7px 2px 7px 12px;

  color: white !important;

  background-color: ${blue50};
`;
