import { NavigateFunction } from 'react-router';

import { routePaths } from 'src/constants';
import { Talent } from 'src/types/domain';

export interface HandlePrevious {
  navigate: NavigateFunction;
  indexOfThisTalent: number;
  combinedFilteredTalents: Talent[];
  selectedJobId: number;
  searchParams: string;
}

export const handlePrevious = (params: HandlePrevious) => {
  if (params.indexOfThisTalent - 1 >= 0) {
    params.navigate(
      routePaths.jobsWithIdListTypeTalentid(
        params.selectedJobId && +params.selectedJobId,
        'talents',
        params.combinedFilteredTalents[params.indexOfThisTalent - 1].id
      ) +
        '?' +
        params.searchParams
    );
  }
};
