import { createSlice } from '@reduxjs/toolkit';

import { JobsInitialState, JobSort, MenuType } from '../../types/types';

import { setFilterAction } from './actions/setFilter';
import { setSelectedMenuAction } from './actions/setSelectedMenu';

export const jobsInitialState: JobsInitialState = {
  filter: {
    search: '',
    type: JobSort.AZ,
  },
  selectedMenu: MenuType.OPEN,
};

export const jobs = createSlice({
  initialState: jobsInitialState,
  name: 'jobs',
  reducers: {
    setFilter: setFilterAction,
    setSelectedMenu: setSelectedMenuAction,
  },
});

export const { setSelectedMenu, setFilter } = jobs.actions;

export default jobs.reducer;
