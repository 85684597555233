import { blue60, gray20 } from '../colors/colors';

export const MuiDialog = {
  styleOverrides: {
    paper: {
      border: '1px solid #DDDDDD',
      minWidth: '403px',
      overflow: 'visible',
      wordBreak: 'break-all',
    },
  },
} as const;

export const MuiDialogTitle = {
  styleOverrides: {
    root: {
      color: blue60,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '18px',
      padding: '38px 25px 0px 25px',
    },
  },
};
export const MuiDialogActions = {
  styleOverrides: {
    root: {
      '& :not(:first-of-type)': {
        marginLeft: '0px',
      },
      'backgroundColor': gray20,
      'marginTop': '38px',
    },
    spacing: {
      gap: '12px',
      padding: '20px 25px',
    },
  },
};
export const MuiDialogContent = {
  styleOverrides: {
    root: {
      marginTop: '28px',
      paddingBottom: '0px',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
  },
};
