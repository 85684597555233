export enum FilterLabel {
  STATUS = 'inboxes',
  ON_HOLD = 'onHold',
  APPLY_ON = 'applyOn',
  SCORE = 'score',
  CHAT = 'chat',
  NOTES = 'notes',
}

export enum FilterTypes {
  BOOLEAN,
  DATE,
}

export interface FilterProperty {
  header: { label: string; filterLabel: FilterLabel };
  values: { label: string; filterValue: string }[];
  type: FilterTypes;
}

export interface FilterConfiguration {
  header: string;
  values: FilterProperty[];
}
