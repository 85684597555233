import { createSlice } from '@reduxjs/toolkit';

import { TalentModalState } from '../../types/types';

import { resetModalAction } from './actions/resetModalAction';
import { setActionReasonAction } from './actions/setActionReason';
import { setActionTypeAction } from './actions/setActionType';

export const talentModalInitialState: TalentModalState = {
  action: {
    reason: null,
    type: null,
  },
};

export const talentModal = createSlice({
  initialState: talentModalInitialState,
  name: 'talentModal',
  reducers: {
    resetModal: resetModalAction,
    setActionReason: setActionReasonAction,
    setActionType: setActionTypeAction,
  },
});

export const { resetModal, setActionType, setActionReason } = talentModal.actions;

export default talentModal.reducer;
