import React, { FC } from 'react';

import { TalentInfoSectionSeparator } from 'src/components/Resume/styles/styles';
import { TalentInfoSection } from 'src/components/Talent/components/TalentInfoSection/TalentInfoSection';
import { extractDetails } from 'src/components/Talent/utils/extractDetails';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { Candidate } from 'src/types/domain';

import { Content } from './components/Content/Content';
import { EmptyList } from './components/EmptyList/EmptyList';
export interface BasicInfoProps {
  candidate: Candidate;
}

export const BasicInfo: FC<BasicInfoProps> = ({ candidate }) => {
  const { phone, address, linkedInUrl, email } = extractDetails(candidate);

  const hasDataToShow = phone || address || linkedInUrl || email;
  return (
    <>
      <TalentInfoSectionSeparator style={{ marginTop: 16 }} />
      <TalentInfoSection
        itemHeader={
          <Text
            text={'Basic info'}
            color={'var(--blumine)'}
            typographyType={TextTypographyType.SH_3}
            style={{ marginLeft: 0 }}
          />
        }
        itemBody={
          hasDataToShow ? (
            <Content phone={phone} address={address} linkedInUrl={linkedInUrl} email={email} />
          ) : (
            <EmptyList />
          )
        }
      />
    </>
  );
};
