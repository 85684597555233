import React, { FC, memo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { LoadingPage } from 'src/shared/components/LoadingPage/LoadingPage';
import { useGetTalentsQuery } from 'src/store/api/graphql/talents/getTalents';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { useAppSelector } from '../../store/hooks/hooks';
import { PERSISTENCE_FILTERS_DATABASE, useIndexedDb } from '../../store/indexeddb/IndexedDb';

import { TalentList } from './components/TalentList/TalentList';
import { TalentProfile } from './components/TalentProfile/TalentProfile';
import { navigateIfNotFound } from './utils/navigateIfNotFound';

import classes from './TalentContainer.module.css';

const TalentContainerComponent: FC = () => {
  const navigate = useNavigate();
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);

  const indexedDb = useIndexedDb(PERSISTENCE_FILTERS_DATABASE);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    currentData: talents,
    isFetching: isTalentsLoading,
    isError: getTalentsFailed,
    refetch: refetchTalents,
  } = useGetTalentsQuery(parseSearchParamsFilter(selectedJobId, searchParams), {
    skip: !selectedJobId,
  });

  useEffect(() => {
    const restoreSearchParamsFromIndexedDb = async () => {
      if ((await indexedDb.get('use_persisted_talent_filters')) && selectedJobId) {
        indexedDb.delete('use_persisted_talent_filters');
        await indexedDb.get(selectedJobId).then((savedSearchParams: any) => {
          if (!savedSearchParams) {
            return;
          }

          setSearchParams(savedSearchParams);
        });
      }
    };

    restoreSearchParamsFromIndexedDb();
  }, [indexedDb, selectedJobId, setSearchParams]);

  useEffect(() => {
    if (talents && selectedTalentId) {
      navigateIfNotFound({
        combinedFilteredTalentList: talents.items,
        isEverythingLoaded: !isTalentsLoading,
        navigate,
        searchParams: searchParams.toString(),
        selectedJobId,
        selectedTalentId: +selectedTalentId,
      });
    }
  }, [talents, selectedJobId, navigate, selectedTalentId, isTalentsLoading, searchParams]);

  if (getTalentsFailed || isTalentsLoading) {
    return <LoadingPage hasError={getTalentsFailed} retryCallback={refetchTalents} />;
  }

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.list_container, {
          'w-disable-cursor': isTalentsLoading,
        })}
      >
        <TalentList />
      </div>

      <div className={classes.info_container}>
        {selectedTalentId !== ':talentId' && !isTalentsLoading && (
          <TalentProfile key={selectedTalentId} />
        )}
      </div>
    </div>
  );
};

export const TalentContainer = memo(TalentContainerComponent);
