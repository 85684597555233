import qs from 'qs';

import { api_config } from 'src/constants';
import { GetTalentsVariables } from 'src/store/api/graphql/talents/getTalents';

export const exportUrlCreator = (talentListParams: GetTalentsVariables) => {
  const filter = talentListParams.filter as Record<string, any>;
  const noNullsfilter: Record<string, any> = Object.keys(filter).reduce(
    (obj, key) => (filter[key] !== null ? { ...obj, [key]: filter[key] } : obj),
    {} as Record<string, any>
  );

  const params = qs.stringify(noNullsfilter, { allowDots: true, arrayFormat: 'comma' });
  return `${api_config.api}jobs/${talentListParams.jobId}/talentsExport?${params}`;
};
