import React, { FC } from 'react';

import { TalentInfoSectionSeparator } from 'src/components/Resume/styles/styles';
import { TalentInfoSection } from 'src/components/Talent/components/TalentInfoSection/TalentInfoSection';
import { extractDetails } from 'src/components/Talent/utils/extractDetails';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { Candidate } from 'src/types/domain';

import { Content } from './components/Content/Content';

export interface EducationProps {
  candidate: Candidate;
}

export const Education: FC<EducationProps> = ({ candidate }) => {
  const { educations } = extractDetails(candidate);

  if (!educations || educations.length === 0) {
    return null;
  }

  return (
    <>
      <TalentInfoSectionSeparator />
      <TalentInfoSection
        itemHeader={
          <Text
            color={'var(--blumine)'}
            typographyType={TextTypographyType.SH_3}
            text={'Education history'}
          />
        }
        itemBody={<Content educations={educations} />}
      />
    </>
  );
};
