import styled from 'styled-components';

import { blue10 } from 'src/shared/components/theme/mui/colors/colors';

export const UnansweredQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${blue10};
  border-radius: 5px;
  padding: 7px 12px;
  width: fit-content;
  margin-bottom: 24px;
`;
