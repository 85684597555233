import { nanoid, PayloadAction } from '@reduxjs/toolkit';

import { Notification, NotificationsState } from 'src/store/types/types';

export const addNotificationAction = (
  state: NotificationsState,
  action: PayloadAction<Notification>
) => {
  state.notifications = [...state.notifications, { key: nanoid(), ...action.payload }];
};
