import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoDescriptionAwards = styled.div`
  display: flex;
  gap: 13px;
  margin-top: 8px;
`;

export const InfoActions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const RejectOrAdvanceOrInterviewedTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;
