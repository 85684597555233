export const formatBooleanParams = (params: string[]): boolean | null => {
  if (params.length === 2) {
    return null;
  }

  if (params.length === 1) {
    return params[0] === 'true';
  }

  return null;
};
