import { FC } from 'react';
import Button from '@mui/material/Button';

import { SubmitContainer } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/styled';

import classes from './Submit.module.css';
interface SubmitProps {
  onClick: () => void;
}

export const Submit: FC<SubmitProps> = ({ onClick }) => (
  <SubmitContainer>
    <Button variant='contained' className={classes.submit} onClick={onClick}>
      Apply
    </Button>
  </SubmitContainer>
);
