import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckCircle } from 'src/assets/CheckCircle.svg';
import { ReactComponent as RejectCircle } from 'src/assets/RejectCircle.svg';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';

export interface QuestionProps {
  answer: string;
  question: string;
  failed: boolean | null;
}

const QuestionWrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;

export enum AnswerType {
  YES = 'yes',
  NO = 'no',
}

export const Question: FunctionComponent<QuestionProps> = ({ answer, question, failed }) => {
  const renderIcon = () => {
    if (typeof failed === 'boolean') {
      if (!failed) {
        return <CheckCircle />;
      }

      return <RejectCircle />;
    }

    return null;
  };

  const needSemicolon = question && question[question.length - 1] !== '?';
  const text = question ? question + `${needSemicolon ? ':' : ''}` : 'Label Placeholder:';
  const icon = renderIcon();

  return answer ? (
    <QuestionWrapper data-testid='question-container'>
      {icon}
      <div
        style={{
          display: 'flex',
          flexDirection: icon === null ? 'column' : 'row',
          gap: 5,
        }}
      >
        <Text text={text} typographyType={TextTypographyType.SH_3} color={'var(--blumine)'} />
        <Text text={answer} typographyType={TextTypographyType.BODY_1} color={'var(--blumine)'} />
      </div>
    </QuestionWrapper>
  ) : null;
};
