import { gql } from 'graphql-request';

import { pessimisticGetCandidate } from 'src/store/api/graphql/talents/utils/pessimisticGetCandidate';
import { RootState } from 'src/store/store';
import { Candidate } from 'src/types/domain';

import { graphqlApi } from '../graphql';

export interface CandidateWithSql extends Candidate {
  sqlId: number;
}

export interface GetCandidateData {
  opportunityBySqlId: CandidateWithSql;
}

export interface GetCandidateResponse {
  data: GetCandidateData;
}

export interface GetCandidateVariables {
  id: number | null | '';
}

export const extendedGraphqlApiWithGetCandidate = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getCandidate: builder.query<Candidate, GetCandidateVariables>({
      onQueryStarted: (variables, { dispatch, getState, queryFulfilled }) => {
        pessimisticGetCandidate(variables, {
          dispatch,
          queryFulfilled,
          state: getState() as RootState,
        });
      },
      query: variables => ({
        document: gql`
          query opportunityBySqlId($id: ID!) {
            opportunityBySqlId(id: $id) {
              chatStatus
              sqlId
              neoId
              onHold
              onHoldSince
              onHoldBy {
                firstName
                lastName
              }
              feedback {
                advancementReason
                rejectionReason
              }
              notes {
                recruiterInfo {
                  firstName
                  lastName
                }
                message
                created
              }
              job {
                hasChat
              }
              statuses {
                status
                created
                recruiterInfo {
                  firstName
                  lastName
                }
              }
              initialStatus {
                created
              }
              zohoInfo {
                resumeLink
                workAuthorization
              }
              chatSummaries {
                neoId
                type
                name
                value
                shortLabel
                questionsTabSection
                values
                knockout
              }
              person {
                defaultProfile {
                  network
                  url
                }
                name {
                  firstName
                  lastName
                }
                address {
                  address
                }
                primaryPhone
                email
                education {
                  major
                  graduation
                  gpa
                  degree
                  school {
                    name
                  }
                }
                workExperience {
                  neoId
                  title
                  start
                  end
                  company
                  description
                }
                currentWorkExperience {
                  neoId
                  title
                  start
                  end
                  company
                  description
                }
                education {
                  school {
                    name
                  }
                  gpa
                  degree
                  graduation
                }
              }
            }
          }
        `,
        variables,
      }),
      transformResponse: (response: GetCandidateResponse) => {
        const candidate = {
          ...response.data.opportunityBySqlId,
          id: +response.data.opportunityBySqlId.sqlId,
        };
        return candidate;
      },
    }),
  }),
});

export const { useGetCandidateQuery, useLazyGetCandidateQuery } =
  extendedGraphqlApiWithGetCandidate;
