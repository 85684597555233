import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
a{
  cursor: pointer;

  color: #225a78;
  text-decoration: none;

  background-color: transparent;
  outline: none;

  transition: color .3s;

  text-decoration-skip: objects;
}

a:any-link{
	cursor: pointer;
	color: -webkit-link;
}


html,
body{
  width:100%;
  height:100%;

  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5715;
  color:#000000a6;

	background-color: #fff;
}

*,
*::before,
*::after{
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  overflow: hidden;
  background: none;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #B3B4B6;    /* color of the scroll thumb */
  border-width: 0 ;  /* creates padding around scroll thumb */
  border-radius: 22px;       /* roundness of the scroll thumb */
}
`;
