import { AnyAction } from 'redux';
import { push } from 'redux-first-history';
import { ThunkDispatch } from 'redux-thunk';

import { store } from 'src/store/store';

export const optimisticGetTalents = async ({
  dispatch,
  queryFulfilled,
}: {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  queryFulfilled: unknown;
}) => {
  try {
    await queryFulfilled;
  } catch (error: any) {
    const errorMessage = error?.error?.data?.message;

    const invalidFilter =
      typeof errorMessage === 'string' &&
      errorMessage.includes(`Variable 'filter' has an invalid value:`);

    if (invalidFilter) {
      dispatch<any>(push(store.getState().router.location?.pathname || ''));
    }
  }
};
