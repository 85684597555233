import { BaseSyntheticEvent, FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { ReactComponent as FilterIcon } from 'src/assets/FilterIcon.svg';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';

import { FilterMenu } from './components/FilterMenu/FilterMenu';

import classes from './Filter.module.css';

interface FilterProps {
  applicantsAmount: number;
}

export const Filter: FC<FilterProps> = ({ applicantsAmount }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleClick = (event: BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(
          classes.filter_row_always,
          classes.hover_item,
          anchorEl && classes.filter_row
        )}
        data-testid='filter-container'
      >
        <Text
          typographyType={TextTypographyType.SH_3}
          color={'var(--blumine)'}
          text={`Showing ${applicantsAmount.toLocaleString()} applicant${
            applicantsAmount === 1 ? '' : 's'
          }`}
        />
        <Tooltip title={'Filter list'} arrow placement='bottom'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FilterIcon />
          </div>
        </Tooltip>
      </div>
      {anchorEl && <FilterMenu anchorPoint={anchorEl} onClose={handleClose} />}
    </>
  );
};
