import React, { ChangeEvent, FC } from 'react';
import { OutlinedInput, outlinedInputClasses, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { ReactComponent as ExclamationError } from 'src/assets/ExclamationError.svg';
import { blue70, red10 } from 'src/shared/components/theme/mui/colors/colors';
import { useAddNoteMutation } from 'src/store/api/graphql/talents/addNote';
import { useGetCandidateQuery } from 'src/store/api/graphql/talents/getCandidate';
import { useAppDispatch, useAppSelector } from 'src/store/hooks/hooks';
import { reset, setActiveInput, setNote } from 'src/store/slices/talentNotes/talentNotes';

export const NewNote: FC = () => {
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);

  //the only reason this exists is because we are using sqlIds everywhere else except the add note mutation. This will change in the near future where we will migrate to using neoIds for everything. But until then this has to stay in place.
  const { currentData: candidate } = useGetCandidateQuery(
    {
      id: selectedTalentId && +selectedTalentId,
    },
    { skip: Boolean(!selectedTalentId) || selectedTalentId === ':talentId' }
  );

  const tempNote = useAppSelector(state => state.talentNotes.tempNote);
  const activeInput = useAppSelector(state => state.talentNotes.activeInput);
  const dispatch = useAppDispatch();
  const [saveNote, { isLoading: isSavingNote, isError: isSavingErrored }] = useAddNoteMutation();
  const shouldInputBeExpanded = Boolean(tempNote) || activeInput;

  const onFocusHandler = () => {
    dispatch(setActiveInput(true));
  };

  const onBlurHandler = () => {
    dispatch(setActiveInput(false));
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setNote(e.target.value));
  };

  const onSaveHandler = () => {
    if (candidate && tempNote) {
      saveNote({
        message: tempNote,
        opportunityId: candidate.neoId,
        sqlId: candidate.id,
      });
    }
  };

  const onCancelHandler = () => {
    dispatch(reset());
  };

  return (
    <Box sx={{ padding: '30px 24px 12px 24px' }}>
      <OutlinedInput
        data-testid={'textfield-new-note'}
        sx={{
          height: !shouldInputBeExpanded ? '40px' : '98px',
          width: '100%',
          [`&.${outlinedInputClasses.focused}`]: {
            height: `98px`,
          },
        }}
        placeholder={'Enter a note'}
        label={'Enter a note'}
        alt-text={'Enter a note'}
        multiline
        minRows={shouldInputBeExpanded ? 4 : 1}
        maxRows={4}
        value={tempNote || ''}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
      />
      {shouldInputBeExpanded && (
        <Stack direction='row' sx={{ paddingTop: '12px' }} gap={'10px'}>
          <Button variant='contained' disabled={!tempNote || isSavingNote} onClick={onSaveHandler}>
            Save
          </Button>
          <Button disabled={isSavingNote} variant='outlined' onClick={onCancelHandler}>
            Cancel
          </Button>
          {isSavingErrored && (
            <Stack
              alignItems='center'
              direction='row'
              sx={{ backgroundColor: red10, borderRadius: '5px', padding: '7px 10px 7px 10px' }}
              gap={'8px'}
              data-testid='newNote-saving-error'
            >
              <ExclamationError style={{ height: '18px', width: '18px' }} />
              <Typography variant='body1' color={blue70}>
                Your note couldn&apos;t be saved. Please try again.
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};
