import { gray60, mainFont } from 'src/shared/components/theme/mui/colors/colors';

export const typography = {
  body1: {
    color: gray60,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18.2px',
  },

  body2: {
    color: gray60,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18.2px',
  },
  body7: {
    color: gray60,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
  },
  button: {
    fontSize: '14px',
    fontWeight: '600',
    height: '32px',
    lineHeight: '18.2px',
    textTransform: 'none',
  },
  fontFamily: mainFont,
  mini: {
    color: gray60,
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '13.2px',
  },
  smallTitle: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
  },
  smallt1: {
    color: gray60,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
  },
  subHeading1: {
    color: gray60,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.2px',
  },
  subHeading2: {
    color: gray60,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.2px',
  },
} as const;
