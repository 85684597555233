import { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

import { SelectedDateInput } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

export const setDate = (
  selectedDate: Date | undefined,
  selectedDateInput: SelectedDateInput,
  range: DateRange | undefined,
  setRange: Dispatch<SetStateAction<DateRange | undefined>>,
  setSelectedDateInput: Dispatch<SetStateAction<SelectedDateInput>>
) => {
  if (!selectedDate) {
    setRange({ from: undefined, to: undefined });
    return;
  }

  if (selectedDateInput === SelectedDateInput.START) {
    if (range && range.to && selectedDate > range.to) {
      setRange({ from: selectedDate, to: undefined });
      setSelectedDateInput(SelectedDateInput.END);
      return;
    }

    setRange({ from: selectedDate, to: range?.to });
    setSelectedDateInput(SelectedDateInput.END);
  } else {
    if (range && range.from && selectedDate < range.from) {
      setRange({ from: selectedDate, to: undefined });
      return;
    }

    setRange({ from: range?.from, to: selectedDate });
    setSelectedDateInput(SelectedDateInput.START);
  }
};
