import { Dispatch, SetStateAction } from 'react';
import { startOfDay } from 'date-fns';

import { isDayOutOfBounds } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Calendar/utils/isDayOutOfBounds';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

const dateRegexDot = /([0-9]{2,}).([0-9]{2,}).([0-9]{4,})/;
const dateRegexSlash = /([0-9]{2,})\/([0-9]{2,})\/([0-9]{4,})/;
const dateRegexFullDate =
  /((jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec) [0-9]{2,}, ?[0-9]{4,})/i;

export interface StartDateValidatorArgs {
  incomingInputValue: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setStartDateError: (state: boolean) => void;
  setEndDate: Dispatch<SetStateAction<string>>;
  setEndDateError: (state: boolean) => void;
  endDate: Date | undefined;
  setContextStartingDate: (day: Date | undefined) => void;
  setContextEndDate: (day: Date | undefined) => void;
  earliestDateTimeStamp: number;
}

export const startDateValidator = ({
  earliestDateTimeStamp,
  incomingInputValue,
  endDate,
  setStartDate,
  setContextStartingDate,
  setContextEndDate,
  setStartDateError,
  setEndDate,
  setEndDateError,
}: StartDateValidatorArgs) => {
  if (
    incomingInputValue.match(dateRegexDot) ||
    incomingInputValue.match(dateRegexSlash) ||
    incomingInputValue.match(dateRegexFullDate)
  ) {
    try {
      const parsedInputValue = Date.parse(incomingInputValue);
      const incomingDate = new Date(parsedInputValue);
      const earliestDate = startOfDay(new Date(earliestDateTimeStamp));
      const outOfBoundsError = new Error('Out of Bounds');

      if (isDayOutOfBounds(incomingDate, new Date(earliestDate))) {
        throw outOfBoundsError;
      }

      if (!endDate || incomingDate > endDate) {
        setEndDate('');
        setContextEndDate(undefined);
        setEndDateError(false);
      }

      setStartDate(formatDateTypeO(incomingDate));
      setContextStartingDate(incomingDate);
      setStartDateError(false);
    } catch {
      setStartDateError(true);
    }
  } else {
    setStartDateError(true);
  }
};

export interface EndDateValidatorArgs {
  incomingInputValue: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setStartDateError: (state: boolean) => void;
  setEndDate: Dispatch<SetStateAction<string>>;
  setEndDateError: (state: boolean) => void;
  startDate: Date | undefined;
  setContextStartingDate: (day: Date | undefined) => void;
  setContextEndDate: (day: Date | undefined) => void;
  earliestDateTimeStamp: number;
}

export const endDateValidator = ({
  earliestDateTimeStamp,
  incomingInputValue,
  setContextEndDate,
  setContextStartingDate,
  setEndDateError,
  setStartDate,
  setEndDate,
  setStartDateError,
  startDate,
}: EndDateValidatorArgs) => {
  if (
    incomingInputValue.match(dateRegexDot) ||
    incomingInputValue.match(dateRegexSlash) ||
    incomingInputValue.match(dateRegexFullDate)
  ) {
    try {
      const parsedInputValue = Date.parse(incomingInputValue);
      const incomingDate = new Date(parsedInputValue);
      const earliestDate = new Date(earliestDateTimeStamp);
      const outOfBoundsError = new Error('Out of Bounds');

      if (isDayOutOfBounds(incomingDate, new Date(earliestDate))) {
        throw outOfBoundsError;
      }

      if (!startDate || incomingDate < startDate) {
        setEndDate('');
        setContextEndDate(undefined);
        setEndDateError(false);
        setStartDate(formatDateTypeO(incomingDate));
        setContextStartingDate(incomingDate);
        setStartDateError(false);
      } else {
        setEndDate(formatDateTypeO(incomingDate));
        setEndDateError(false);
        setContextEndDate(incomingDate);
      }
    } catch {
      setEndDateError(true);
    }
  } else {
    setEndDateError(true);
  }
};
