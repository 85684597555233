import { FunctionComponent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import { useAdvanceTalentMutation } from 'src/store/api/graphql/talents/advanceTalent';
import { useRejectTalentMutation } from 'src/store/api/graphql/talents/rejectTalent';
import { useSetOnHoldStatusMutation } from 'src/store/api/graphql/talents/setOnHoldStatus';
import { useAppDispatch } from 'src/store/hooks/hooks';
import { setActionType } from 'src/store/slices/talentModal/talentModal';
import { TalentPromotion } from 'src/store/types/types';
import { Talent } from 'src/types/domain';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

import { ActionsWrapper } from './styled';

type TelentInfo = Pick<Talent, 'id' | 'onHold' | 'onHoldSince' | 'onHoldBy'> & {
  hasChat: boolean;
};

export interface ActionsProps {
  showActionButtons: boolean;
  talentInfo: TelentInfo;
}

export const Actions: FunctionComponent<ActionsProps> = ({ showActionButtons, talentInfo }) => {
  const dispatch = useAppDispatch();

  const [setOnHoldStatus, { isLoading: isSetOnHoldLoading }] = useSetOnHoldStatusMutation();

  const { isLoading: isAdvanceTalentLoading } = useAdvanceTalentMutation({
    fixedCacheKey: 'advance-talent-mutation' + talentInfo.id,
  })[1];

  const { isLoading: isRejectTalentLoading } = useRejectTalentMutation({
    fixedCacheKey: 'reject-talent-mutation' + talentInfo.id,
  })[1];

  const isTalentPromotionLoading = isAdvanceTalentLoading || isRejectTalentLoading;
  let onHoldTooltip = '';

  if (isTalentPromotionLoading) {
    onHoldTooltip = 'Disabled while status change is in progress';
  } else if (talentInfo.onHoldSince) {
    onHoldTooltip = `On hold since ${formatDateTypeO(new Date(talentInfo.onHoldSince))} by ${
      talentInfo.onHoldBy?.firstName
    } ${talentInfo.onHoldBy?.lastName}`;
  }

  const talentPromotionReject = () => {
    dispatch(setActionType(TalentPromotion.REJECT));
  };

  const talentPromotionAdvance = () => {
    dispatch(setActionType(TalentPromotion.ADVANCE));
  };

  return (
    <ActionsWrapper>
      {showActionButtons && (
        <>
          <Button
            color='error'
            variant='outlined'
            endIcon={<CloseIcon />}
            onClick={talentPromotionReject}
          >
            Reject
          </Button>
          <Button
            color='success'
            variant='contained'
            endIcon={<DoneIcon />}
            onClick={talentPromotionAdvance}
          >
            Advance
          </Button>
        </>
      )}
      <Tooltip title={onHoldTooltip} arrow placement='top'>
        <FormControlLabel
          sx={{ minWidth: '117px' }}
          componentsProps={{ typography: { whiteSpace: 'nowrap' } }}
          control={
            <Switch
              checked={talentInfo.onHold}
              disabled={isSetOnHoldLoading || isTalentPromotionLoading}
              onChange={e =>
                setOnHoldStatus({
                  candidateId: talentInfo.id,
                  onHold: e.target.checked,
                })
              }
              name='onHold'
            />
          }
          label='On hold'
        />
      </Tooltip>
    </ActionsWrapper>
  );
};
