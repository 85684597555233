import { gql } from 'graphql-request';

import { Job } from 'src/types/jobs';

import { graphqlApi } from '../graphql';

interface GetArchivedJobsResponse {
  data: { archiveJobs: Job[] };
}

export const extendedGraphqlApiWithArchivedJobs = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getArchivedJobs: builder.query<Job[], undefined>({
      query: () => ({
        document: gql`
          {
            archiveJobs {
              id
              name
              externalId
              numberOfCandidates
              attributes {
                location_country
                location_state
                location_city
              }
              hasChat
            }
          }
        `,
        variables: {},
      }),
      transformResponse: (response: GetArchivedJobsResponse) => response.data.archiveJobs,
    }),
  }),
});

export const { useGetArchivedJobsQuery } = extendedGraphqlApiWithArchivedJobs;
