import { PayloadAction } from '@reduxjs/toolkit';

import { JobsInitialState, SetFilterParams } from '../../../types/types';

export const setFilterAction = (
  state: JobsInitialState,
  action: PayloadAction<SetFilterParams>
) => {
  if (action.payload.search !== undefined) {
    state.filter.search = action.payload.search;
  }

  if (action.payload.type !== undefined) {
    state.filter.type = action.payload.type;
  }
};
