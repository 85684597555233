import { predefinedRangesConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/utils/predefinedRangesConfiguration';

export const initialRangeValue = (dates: string[], earliestPossibleDate: number) => {
  const defaultPick = predefinedRangesConfiguration(earliestPossibleDate).find(
    item => item.label === 'Last 30 days'
  );
  const range = {
    from: (defaultPick && defaultPick.from) || undefined,
    to: (defaultPick && defaultPick.to) || undefined,
  };

  try {
    const from = new Date(+dates[0]);
    const to = new Date(+dates[1]);

    if (!from.getTime() || !to.getTime()) {
      throw new Error('invalid dates');
    }

    range.from = from;
    range.to = to;

    return range;
  } catch {
    return range;
  }
};
