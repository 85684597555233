import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { useAdvanceTalentMutation } from 'src/store/api/graphql/talents/advanceTalent';
import { useGetCandidateQuery } from 'src/store/api/graphql/talents/getCandidate';
import { useRejectTalentMutation } from 'src/store/api/graphql/talents/rejectTalent';
import { useAppDispatch, useAppSelector } from 'src/store/hooks/hooks';
import { resetModal } from 'src/store/slices/talentModal/talentModal';

import { ReasonSelection } from './components/ReasonSelection/ReasonSelection';

export const TalentActionModal = () => {
  const dispatch = useAppDispatch();
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);
  const selectedReason = useAppSelector(state => state.talentModal.action.reason);
  const talentModalActionType = useAppSelector(state => state.talentModal.action.type);
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { currentData: candidateDetails } = useGetCandidateQuery(
    {
      id: selectedTalentId && +selectedTalentId,
    },
    { skip: Boolean(!selectedTalentId) }
  );
  const [advanceUser] = useAdvanceTalentMutation({
    fixedCacheKey: 'advance-talent-mutation' + selectedTalentId,
  });
  const [rejectUser] = useRejectTalentMutation({
    fixedCacheKey: 'reject-talent-mutation' + selectedTalentId,
  });

  const shouldShowDialog = Boolean(talentModalActionType);

  if (!shouldShowDialog || !candidateDetails) {
    return null;
  }

  const handleCloseDialog = () => dispatch(resetModal());

  const promoteAction = () => {
    if (isAdvance) {
      advanceUser({
        candidateId: candidateDetails.id,
        jobId: Number(selectedJobId),
        notes: '',
        reason: 'OTHER',
      });
      return;
    }

    rejectUser({
      candidateId: candidateDetails.id,
      jobId: Number(selectedJobId),
      notes: '',
      reason: selectedReason || 'OTHER',
    });
  };

  const isAdvance: boolean = talentModalActionType === 'advance';
  const promotionText: string = isAdvance ? 'Advance' : 'Reject';

  return (
    <Dialog
      open={shouldShowDialog}
      onClose={handleCloseDialog}
      aria-labelledby={`${talentModalActionType} action dialog`}
    >
      <DialogTitle>
        {promotionText} {candidateDetails.person.name.firstName}{' '}
        {candidateDetails.person.name.lastName}?
      </DialogTitle>
      {!isAdvance && <ReasonSelection />}
      <DialogActions>
        <Button variant='outlined' onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant='contained' onClick={promoteAction} autoFocus>
          {promotionText} applicant
        </Button>
      </DialogActions>
    </Dialog>
  );
};
