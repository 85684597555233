import { FC, useContext, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { findSelectedItem } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/utils/findSelectedItem';
import { predefinedRangesConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/utils/predefinedRangesConfiguration';
import { DateContext } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

export const DateMenu: FC = () => {
  const { range, setRange, earliestDate } = useContext(DateContext);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    findSelectedItem(range, earliestDate, setSelectedIndex);
  }, [range, earliestDate]);

  return (
    <>
      {predefinedRangesConfiguration(earliestDate).map((item, index) => (
        <MenuItem
          key={item.label}
          divider={true}
          onClick={() => {
            setRange({ from: item.from, to: item.to });
          }}
          selected={index === selectedIndex}
        >
          {item.label}
        </MenuItem>
      ))}
    </>
  );
};
