export const MuiIcon = {
  styleOverrides: {
    fontSizeLarge: {
      height: '25px!important',
      width: '25px!important',
    },
    fontSizeSmall: {
      height: '17px',
      width: '17px',
    },
  },
};
