import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { blue30, gray60, menuOutline } from 'src/shared/components/theme/mui/colors/colors';

export const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      '& legend': {
        display: 'none!important',
      },
      [`& span svg`]: {
        fontSize: 17,
      },
      '&:hover': {
        border: `1px solid ${menuOutline}`,
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          border: `0px solid ${menuOutline}!important`,
        },
      },
      'backgroundColor': 'white',
      [`&.${outlinedInputClasses.focused}`]: {
        border: `1px solid ${blue30}!important`,
      },
      'border': `1px solid ${menuOutline}`,
      'height': '40px',
      [`& .${outlinedInputClasses.input}`]: { color: gray60 },

      [`& .${outlinedInputClasses.notchedOutline}`]: {
        border: `0px solid ${menuOutline}!important`,
      },
      'paddingRight': '10px',
    },
  },
};
