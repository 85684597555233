import React, { FC } from 'react';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

import classes from './AppliedOn.module.css';

export interface AppliedOnProps {
  ts: number | null;
}

export const AppliedOn: FC<AppliedOnProps> = ({ ts }) => {
  /* The check for the timestamp is here to hide the Candidacies with wronly small timestamps.
     Those candidacies were created by pando-integration due to a bug, which is resolved now
       but the wrong timestamps are still in the database, so we have to temporarily hide them
       to avoid production database modification.
     This check can be removed eventually. */
  const theOldestAppliedDatePossibleAsUnixMillis = new Date('2010-01-01').getTime();

  if (!ts || ts < theOldestAppliedDatePossibleAsUnixMillis) {
    return null;
  }

  return (
    <div className={classes.container} data-testid='applied-on'>
      <Text
        text={`Applied: `}
        typographyType={TextTypographyType.BODY_1}
        color={'var(--seal-blue)'}
      />
      <Text
        text={`${formatDateTypeO(new Date(ts))}`}
        typographyType={TextTypographyType.BODY_2}
        color={'var(--seal-blue)'}
      />
    </div>
  );
};
