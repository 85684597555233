export const createEarliestPossibleDate = (
  earliestDateTimestamp: number,
  dateToCompare: Date
): Date => {
  if (dateToCompare < new Date(earliestDateTimestamp)) {
    return new Date(earliestDateTimestamp);
  }

  return dateToCompare;
};
