import { FC } from 'react';
import MuiAlert from '@mui/material/Alert';
import { SnackbarKey } from 'notistack';

import { ReactComponent as ErrorIcon } from 'src/assets/ExclamationError.svg';

interface SnackbarContentProps {
  snackbarKey: SnackbarKey;
  message: any;
}

export const SnackbarContent: FC<SnackbarContentProps> = ({ snackbarKey, message }) => {
  const splitMessage = (message && typeof message === 'string' && message.split('.')) || [];

  return (
    <MuiAlert key={snackbarKey} icon={false} elevation={6} variant='standard' severity='info'>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        {splitMessage.length >= 2 ? (
          <>
            {splitMessage[0]}. <br data-testid='line-break' /> {splitMessage[1]}.
          </>
        ) : (
          message + '.'
        )}
      </div>
      <ErrorIcon />
    </MuiAlert>
  );
};
