import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StartDemoPayload {
  id: null | string;
  sqlId: null | number;
}

interface State {
  candidateId: null | string;
  candidateSqlId: null | number;
}

export const chatDemo = createSlice({
  initialState: {
    candidateId: null,
    candidateSqlId: null,
  } as State,
  name: 'chatDemo',
  reducers: {
    finishDemo: state => {
      state.candidateId = null;
      state.candidateSqlId = null;
    },
    startDemo: (state, action: PayloadAction<StartDemoPayload>) => {
      state.candidateId = action.payload.id;
      state.candidateSqlId = action.payload.sqlId;
    },
  },
});

export const { startDemo, finishDemo } = chatDemo.actions;

export default chatDemo.reducer;
