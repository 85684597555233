import React, { FC } from 'react';

import { TalentInfoSectionSeparator } from 'src/components/Resume/styles/styles';
import { TalentInfoSection } from 'src/components/Talent/components/TalentInfoSection/TalentInfoSection';
import { extractDetails } from 'src/components/Talent/utils/extractDetails';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { Candidate } from 'src/types/domain';

import { Content } from './components/Content/Content';
export interface WorkExperienceProps {
  candidate: Candidate;
}

export const WorkExperience: FC<WorkExperienceProps> = ({ candidate }) => {
  const { workExperiences } = extractDetails(candidate);

  if (!workExperiences || workExperiences.length === 0) {
    return null;
  }

  return (
    <>
      <TalentInfoSectionSeparator />
      <TalentInfoSection
        itemHeader={
          <Text
            color={'var(--blumine)'}
            typographyType={TextTypographyType.SH_3}
            text={'Work history'}
          />
        }
        itemBody={<Content workExperiences={workExperiences} />}
      />
    </>
  );
};
