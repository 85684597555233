import styled from 'styled-components';

export const TalentListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 1px 8px 0 #0000001a;
`;
