import { gql } from 'graphql-request';

import { Job } from 'src/types/jobs';

import { graphqlApi } from '../graphql';

export interface GetLiveJobsData {
  liveJobs: Job[];
}

export interface GetLiveJobsResponse {
  data: GetLiveJobsData;
}

export const extendedGraphqlApiWithLiveJobs = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getLiveJobs: builder.query<Job[], undefined>({
      query: () => ({
        document: gql`
          {
            liveJobs {
              id
              name
              externalId
              numberOfCandidates
              attributes {
                location_country
                location_state
                location_city
              }
              hasChat
            }
          }
        `,
        variables: {},
      }),
      transformResponse: (response: GetLiveJobsResponse) => response.data.liveJobs,
    }),
  }),
});

export const { useGetLiveJobsQuery } = extendedGraphqlApiWithLiveJobs;
