import styled from 'styled-components';

export const PaginatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  gap: 0.625rem;
  align-items: center;
  justify-content: end;

  min-width: 180px;
  height: 20px;
`;

export const PaginatorSeparator = styled.span`
  height: 24px;
  margin: 0 0.25rem;
  padding: 0.125rem 0;
  border-left: 1px solid var(--gray);
`;

export const PaginatorNumber = styled.span`
  font-size: 0.6875rem;
  line-height: 0.8125rem;
`;

export const PaginationArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationArrowWrapper = styled.div<{ disabled: boolean }>`
  display: flex;

  :hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;
