import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSearchParams } from 'react-router-dom';

import { dateSorter } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/utils/dateSorter';
import { initialDayInput } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/utils/initialDayInput';
import { initialRangeValue } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/utils/initialRangeValue';
import { updateDateSearchParams } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/utils/updateDateSearchParams';
import { FilterLabel } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { useGetArchivedJobsQuery } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { useGetLiveJobsQuery } from 'src/store/api/graphql/jobs/getLiveJobs';
import { useAppSelector } from 'src/store/hooks/hooks';

import { resetRange as resetRangeUtil } from './utils/resetRange';
import { setDate as setDateUtil } from './utils/setDate';

export enum SelectedDateInput {
  START,
  END,
}

interface DateContextState {
  range: DateRange | undefined;
  setRange: (range: DateRange | undefined) => void;
  rangeMatch: boolean;
  updateDateFilter: () => void;
  resetRange: () => void;
  setDate: (selectedDate: Date | undefined) => void;
  selectedDateInput: number;
  setSelectedDateInput: (selectDateInput: SelectedDateInput) => void;
  setStartDate: (selectedDate: Date | undefined) => void;
  setEndDate: (selectedDate: Date | undefined) => void;
  startDayInput: string;
  startDayInputError: boolean;
  setStartDayInput: Dispatch<SetStateAction<string>>;
  setStartDayInputError: Dispatch<SetStateAction<boolean>>;
  endDayInput: string;
  endDayInputError: boolean;
  setEndDayInput: Dispatch<SetStateAction<string>>;
  setEndDayInputError: Dispatch<SetStateAction<boolean>>;
  earliestDate: number;
}

export const defaultValue: DateContextState = {
  earliestDate: 0,
  endDayInput: '',
  endDayInputError: false,
  range: {
    from: undefined,
    to: undefined,
  },
  rangeMatch: false,
  resetRange: () => undefined,
  selectedDateInput: 0,
  setDate: () => undefined,
  setEndDate: () => undefined,
  setEndDayInput: () => undefined,
  setEndDayInputError: () => undefined,
  setRange: () => undefined,
  setSelectedDateInput: () => undefined,
  setStartDate: () => undefined,
  setStartDayInput: () => undefined,
  setStartDayInputError: () => undefined,
  startDayInput: '',
  startDayInputError: false,
  updateDateFilter: () => undefined,
};

//this is Jan 01, 2020, an arbitiery date that product decided works for our use
const earliestPossibleDate = 1577862000000;

export const DateContext = createContext(defaultValue);

interface DateProviderProps {
  children?: ReactNode;
}

export const DateProvider: FC<DateProviderProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dates = searchParams.getAll(FilterLabel.APPLY_ON).sort(dateSorter);
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { job: liveJob } = useGetLiveJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const { job: archivedJob } = useGetArchivedJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const selectedJob = liveJob || archivedJob;
  const [selectedDateInput, setSelectedDateInput] = useState<SelectedDateInput>(
    SelectedDateInput.START
  );
  const [rangeMatch, setRangeMatch] = useState<boolean>(false);

  const [range, setRange] = useState<DateRange | undefined>(() =>
    initialRangeValue(dates, earliestPossibleDate)
  );

  const [startDayInput, setStartDayInput] = useState<string>(() => initialDayInput(range, 'from'));
  const [startDayInputError, setStartDayInputError] = useState<boolean>(false);
  const [endDayInput, setEndDayInput] = useState<string>(() => initialDayInput(range, 'to'));
  const [endDayInputError, setEndDayInputError] = useState<boolean>(false);

  const setRangeHandler = (dateRange: DateRange | undefined) => {
    setRange(dateRange);
    setSelectedDateInput(SelectedDateInput.START);
  };

  const setDate = (selectedDate: Date | undefined) => {
    setDateUtil(selectedDate, selectedDateInput, range, setRange, setSelectedDateInput);
  };

  const setStartDate = (newStartDate: Date | undefined) => {
    setRange(prev => ({
      from: newStartDate,
      to: prev?.to,
    }));
  };

  const setEndDate = (newEndDate: Date | undefined) => {
    setRange(prev => ({
      from: prev?.from,
      to: newEndDate,
    }));
  };

  const updateDateFilter = () => {
    updateDateSearchParams(range, searchParams, setSearchParams, selectedJob?.hasChat);
  };

  const resetRange = () => {
    resetRangeUtil(dates, setRange);
  };

  useEffect(() => {
    if (
      dates.find(item => +item === range?.to?.getTime()) &&
      dates.find(item => +item === range?.from?.getTime())
    ) {
      setRangeMatch(true);
    } else {
      setRangeMatch(false);
    }
  }, [dates, range]);

  return (
    <DateContext.Provider
      value={{
        earliestDate: earliestPossibleDate,
        endDayInput,
        endDayInputError,
        range,
        rangeMatch,
        resetRange,
        selectedDateInput,
        setDate,
        setEndDate,
        setEndDayInput,
        setEndDayInputError,
        setRange: setRangeHandler,
        setSelectedDateInput,
        setStartDate,
        setStartDayInput,
        setStartDayInputError,
        startDayInput,
        startDayInputError,

        updateDateFilter,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
