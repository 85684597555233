import { gray40 } from 'src/shared/components/theme/mui/colors/colors';

export const MuiSelect = {
  styleOverrides: {
    select: {
      borderBottom: '0px!important',
      color: gray40,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
};
