import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { HeaderContainer } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/styled';

interface HeaderProps {
  onClose: () => void;
  text: string;
}

export const Header: FC<HeaderProps> = ({ onClose, text }) => (
  <HeaderContainer>
    <Typography variant='body2' align='left' sx={{ color: 'white' }}>
      {text}
    </Typography>
    <IconButton size='small' onClick={onClose}>
      <CloseIcon
        fontSize='large'
        sx={{
          '&:hover': {
            opacity: '0.6',
          },
          'color': 'white',
        }}
      />
    </IconButton>
  </HeaderContainer>
);
