import { format } from 'date-fns';

export function formatDate(date: string | null): string {
  if (!date) {
    return '';
  }

  try {
    const parsedDate = new Date(+date.split('-')[0], +date.split('-')[1] - 1);
    return format(parsedDate, 'MMM yyyy');
  } catch (err) {
    return '';
  }
}

export function formatDateTypeO(date: Date): string {
  try {
    return format(date, 'MMM dd, yyyy');
  } catch (err) {
    return 'Invalid Date';
  }
}
