import { FilterConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';

export const configToSearchParamsObj = (
  filterMenuConfiguration: FilterConfiguration
): Record<string, string[]> => {
  const searchParamsAsObject: Record<string, string[]> = {};
  filterMenuConfiguration.values.forEach(configValue => {
    searchParamsAsObject[configValue.header.filterLabel] = configValue.values.map(
      configValuesValue => configValuesValue.filterValue
    );
  });
  return searchParamsAsObject;
};
