import { gql } from 'graphql-request';

import { graphqlApi } from 'src/store/api/graphql/graphql';
import { optimisticTalentPromotion } from 'src/store/api/graphql/talents/utils/optimisticTalentPromotion';
import { RootState } from 'src/store/store';

export const extendedGraphqlApiWithRejectTalent = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    rejectTalent: builder.mutation({
      onQueryStarted: (variables, { dispatch, queryFulfilled, getState }) =>
        optimisticTalentPromotion(
          'reject',
          variables,
          dispatch,
          queryFulfilled,
          getState() as RootState
        ),
      query: variables => ({
        document: gql`
          mutation ($candidateId: ID!, $reason: String!, $notes: String) {
            rejectOpportunity(id: $candidateId, reason: $reason, notes: $notes) {
              firstName
              lastName
            }
          }
        `,
        variables,
      }),
    }),
  }),
});

export const { useRejectTalentMutation } = extendedGraphqlApiWithRejectTalent;
