import { svgIconClasses } from '@mui/material/SvgIcon';

import { gray40 } from 'src/shared/components/theme/mui/colors/colors';

export const MuiCheckbox = {
  styleOverrides: {
    root: {
      color: gray40,
      height: '24px',
      width: '24px',
      [`&& .${svgIconClasses.root}`]: {
        height: '22px',
        width: '22px',
      },
    },
  },
};
