import styled from 'styled-components';

import { ItemProps } from '../Item';

export const ItemWrapper = styled.div<Pick<ItemProps, 'isSelectedItem' | 'isLast'>>`
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 24px 12px 0 20px;

  background-color: ${props => (props.isSelectedItem ? 'var(--azureish-white)' : 'white')};
  border-bottom-right-radius: ${props => (props.isLast ? '8px' : 0)};
  border-bottom-left-radius: ${props => (props.isLast ? '8px' : 0)};

  :hover {
    cursor: pointer;
    background-color: ${props =>
      props.isSelectedItem ? 'var(--azureish-white)' : 'var(--light-gray)'};
  }

  ::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: ${props => (props.isSelectedItem ? '3px' : 0)};
    height: 100%;

    background-color: var(--water-welt);
  }
`;

export const ItemDivider = styled.span<Pick<ItemProps, 'isSelectedItem'>>`
  box-sizing: content-box;
  height: 29px;

  ::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    border-bottom: ${props =>
      props.isSelectedItem ? '0 solid var(--gray)' : '1px solid var(--gray)'};
  }
`;
