import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Actions } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Actions/Actions';
import { Calendar } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Calendar/Calendar';
import { DateInputs } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/DateInputs/DateInputs';
import { DateContext } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';
import { gray10, gray30 } from 'src/shared/components/theme/mui/colors/colors';

interface DatePickerProps {
  onClose: () => void;
}

export const DatePicker: FC<DatePickerProps> = ({ onClose }) => {
  const { rangeMatch } = useContext(DateContext);

  return (
    <Grid
      sx={{ width: '480px' }}
      container
      direction='column'
      justifyContent='space-between'
      alignItems='stretch'
      component={Box}
    >
      <Grid item component={Box} sx={{ padding: '16px 20px' }}>
        <DateInputs />
      </Grid>
      <Grid
        item
        component={Box}
        sx={{
          borderBottom: `1px solid ${gray30}`,
          margin: '0px 20px',
        }}
      ></Grid>
      <Grid item component={Box} sx={{ height: '270px', padding: '16px 20px' }}>
        <Calendar />
      </Grid>
      {!rangeMatch && (
        <Grid
          item
          component={Box}
          sx={{
            backgroundColor: gray10,
            padding: '16px 20px',
          }}
        >
          <Actions onClose={onClose} />
        </Grid>
      )}
    </Grid>
  );
};
