export const blue10 = '#E1F2F6';
export const blue30 = '#3bb0c9';
export const blue50 = '#30789F';
export const blue60 = '#225A78';
export const blue70 = '#242C37';
export const green30 = '#77992E';
export const black = '#000000';
export const hoverGreen = '#96c03b';
export const red = '#B42F08';
export const red10 = '#F8D0C8';
export const gray8 = '#494949';
export const gray10 = '#F8F8F8';
export const gray20 = '#EEEEEE';
export const gray30 = '#DDDDDD';
export const gray40 = '#B3B4B6';
export const gray50 = '#FAFAFA';
export const gray60 = '#485465';
export const mainFont = 'Open Sans';
export const highlightText = '#C0E6FF';
export const menuOutline = '#809baf';
