import { ChatSummary, QuestionLabels } from '../../../types/domain';

export const mustHavesPredicate = (summary: ChatSummary) =>
  summary.questionsTabSection === QuestionLabels.KNOCK_OUTS ||
  (!summary.questionsTabSection && summary.knockout !== null && summary.type !== 'multi');

export const niceToHavePredicate = (summary: ChatSummary) =>
  summary.questionsTabSection === QuestionLabels.NICE_TO_HAVE ||
  (!summary.questionsTabSection && summary.knockout === null && summary.type === 'multi');

export const additionalInfoPredicate = (summary: ChatSummary) =>
  summary.questionsTabSection === QuestionLabels.ADDITIONAL_INFO ||
  (!summary.questionsTabSection && summary.knockout === null && !!summary.value);
