import React, { FC } from 'react';

import { Education } from 'src/types/domain';

import { EducationItem } from './components/EducationItem/EducationItem';

import classes from './Content.module.css';
export interface ContentProps {
  educations: Education[];
}

export const Content: FC<ContentProps> = ({ educations }) => (
  <div className={classes.container}>
    {educations.map((edu, index) => (
      <EducationItem key={'edu' + index} item={edu} />
    ))}
  </div>
);
