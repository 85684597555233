import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Item } from 'src/components/Talent/components/TalentList/components/Header/components/Chips/components/Item/Item';
import { searchParamsToChipConfig } from 'src/components/Talent/components/TalentList/components/Header/components/Chips/utils/searchParamsToChipConfig';

interface ChipsProps {
  selectedJobId?: number;
}

export const Chips: FC<ChipsProps> = ({ selectedJobId }) => {
  const [searchParams] = useSearchParams();

  const items = useMemo(() => searchParamsToChipConfig(searchParams), [searchParams]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Box sx={{ padding: '0px 24px 24px 24px' }}>
      <Grid container direction='column' rowSpacing={'6px'}>
        {items.map(item => (
          <Grid item key={item.label}>
            <Item
              selectedJobId={selectedJobId}
              label={item.label}
              values={item.values}
              config={item.config}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
