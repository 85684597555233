export const MuiTabs = {
  styleOverrides: {
    flexContainer: {
      gap: '10px',
      height: '25px',
    },
    indicator: {
      backgroundColor: 'white',
    },
    root: {
      minHeight: '0px',
    },
  },
};
