interface AuthConfig {
  domain: string;
  clientId: string;
  audience: string;
}

const AUTH_CONFIG: AuthConfig = {
  audience: 'https://repo.wadeandwendy.chat',
  clientId: process.env.VITE_AUTH0_CLIENT_ID as string,
  domain: process.env.VITE_AUTH0_DOMAIN as string,
};

export default AUTH_CONFIG;
