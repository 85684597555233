import React, { FC } from 'react';

import { JobsInfoHeader } from 'src/components/JobsInfoHeader/JobsInfoHeader';
import { TalentContainer } from 'src/components/Talent/TalentContainer';
import { Spinner } from 'src/shared/components';
import { useAppSelector } from 'src/store/hooks/hooks';
import { useJobsContainer } from 'src/views/JobsContainer/hooks/useJobsContainer';

export const JobsContent: FC = () => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { isLoadingArchivedJobs, isLoadingLiveJobs } = useJobsContainer();

  const isLoadingJobs = isLoadingArchivedJobs && isLoadingLiveJobs;

  if (!selectedJobId) {
    return null;
  }

  return (
    <div className='JobsContainer__info-wrapper' data-testid='jobs-content'>
      <JobsInfoHeader />
      <div className='JobsContainer__info'>{isLoadingJobs ? <Spinner /> : <TalentContainer />}</div>
    </div>
  );
};
