import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setOnHoldStatusErrorNotification } from 'src/store/api/graphql/talents/utils/setOnHoldStatusErrorNotification';
import { updateTalentList } from 'src/store/api/graphql/talents/utils/updateTalentList';
import { RootState } from 'src/store/store';
import { RecruiterActionResult } from 'src/types/domain';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { extendedGraphqlApiWithGetTalents, GetTalentsVariables } from '../getTalents';
import { updateCandidateDetails } from '../utils/updateCandidateDetails';

export const optimisticSetOnHoldStatus = async ({
  dispatch,
  queryFulfilled,
  arg,
  state,
}: {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  queryFulfilled: Promise<any>;
  arg: any;
  state: RootState;
}) => {
  const jobId = state.routerParameters.jobId;

  const searchParams = new URLSearchParams(state.router.location?.search);
  const endpointSelectParams: GetTalentsVariables = parseSearchParamsFilter(jobId, searchParams);

  const talentsResponse =
    extendedGraphqlApiWithGetTalents.endpoints.getTalents.select(endpointSelectParams)(state).data;

  const indexOfOldItem = talentsResponse?.items.findIndex(item => item.id === arg.candidateId);

  try {
    const res = await queryFulfilled;
    const result: RecruiterActionResult = res.data.data.updateOpportunityOnHold;

    updateTalentList({
      dispatch,
      indexOfOldItem,
      onHold: arg.onHold,
      onHoldBy: result?.recruiterInfo ?? null,
      onHoldSince: result?.actionDate ?? null,
      selectParams: endpointSelectParams,
    });

    updateCandidateDetails({
      candidateId: arg.candidateId,
      dispatch,
      onHold: arg.onHold,
      onHoldBy: result?.recruiterInfo ?? null,
      onHoldSince: result?.actionDate ?? null,
    });
  } catch {
    const userInfo =
      talentsResponse && indexOfOldItem !== undefined && indexOfOldItem > -1
        ? {
            firstName: talentsResponse.items[indexOfOldItem].firstName,
            lastName: talentsResponse.items[indexOfOldItem].lastName,
          }
        : undefined;
    setOnHoldStatusErrorNotification({ dispatch, onHold: arg.onHold, userInfo });
  }
};
