export enum ExcludedCustomFreeResponsesKey {
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  VETERAN_STATUS = 'veteran status',
  DISABILITY_STATUS = 'disability status',
}

export const routePaths = {
  callback: () => '/callback',
  jobs: () => '/jobs',
  jobsWithId: (jobId?: number) => `/jobs/${jobId ? jobId : ':jobId'}`,
  jobsWithIdListTypeTalentid: (
    jobId?: number | null,
    listType?: string,
    talentId?: number | string | null
  ) =>
    `/jobs/${jobId ? jobId : ':jobId'}/${listType ? listType : ':listType'}/${
      talentId ? talentId : ':talentId'
    }`,
  settings: () => '/settings',
};

export const api_config = {
  analytics_api: process.env.VITE_BASE_URL + '/api/v1/analytics/',
  api: process.env.VITE_BASE_WORKSPACE_URL + '/',
  graphql: process.env.VITE_BASE_URL + '/graphql',
  metrics_api: process.env.VITE_BASE_URL + '/api/v1/metrics',
  users_api: process.env.VITE_BASE_URL + '/api/v1/users',
};
