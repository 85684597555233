import { gql } from 'graphql-request';

import { optimisticGetTalents } from 'src/store/api/graphql/talents/utils/optimisticGetTalents';
import { ChatStatus, DateRange, Score, Talent } from 'src/types/domain';

import { graphqlApi } from '../graphql';

export interface GetTalentsData {
  items: Talent[];
  totalCount: number;
}

export interface GetTalentsResponse {
  data: {
    jobsTalents: GetTalentsData;
  };
}
export enum InboxValues {
  NEW = 'NEW',
  ADVANCED = 'ADVANCED',
  REJECTED = 'REJECTED',
}

export interface GetTalentsVariables {
  jobId: number | null;
  filter: {
    inboxes: InboxValues[];
    score: Score[] | null;
    onHold: boolean | null;
    applyOn: DateRange | null;
    chatStatus: ChatStatus[] | null;
    hasNotes: boolean | null;
  };
}

export const extendedGraphqlApiWithGetTalents = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    getTalents: builder.query<GetTalentsData, GetTalentsVariables>({
      keepUnusedDataFor: 1,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await optimisticGetTalents({ dispatch, queryFulfilled });
      },
      query: variables => ({
        document: gql`
          query jobsTalents($jobId: Int!, $filter: JobTalentsFilter!) {
            jobsTalents(jobId: $jobId, filter: $filter) {
              totalCount
              items {
                id
                neoId
                currentEmployer {
                  title
                }
                score
                chatStatus
                currentStatus {
                  id
                  status {
                    status
                    ts
                  }
                }
                firstName
                lastName
                hasNotes
                onHold
                onHoldSince
                onHoldBy {
                  firstName
                  lastName
                }
              }
            }
          }
        `,
        variables,
      }),
      transformResponse: (response: GetTalentsResponse): { items: Talent[]; totalCount: number } =>
        response.data.jobsTalents,
    }),
  }),
});

export const { useGetTalentsQuery } = extendedGraphqlApiWithGetTalents;
