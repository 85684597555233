import { FC, useContext } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { Wrapper } from 'src/components/Callback/styled';
import { AuthContext } from 'src/contexts/AuthContext';
import { Spinner } from 'src/shared/components/Spinner';

export type CallbackParams = { location: Location; navigate: any };

export const Callback: FC = () => {
  const { handleAuth } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  handleAuth(location, navigate);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};
