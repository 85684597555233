import styled from 'styled-components';

export const QuestionsContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
