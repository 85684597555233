import styled from 'styled-components';

import { ActionsProps } from '../Actions';

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 0.9375rem;
  align-items: center;
  flex-grow: 1;
`;

const dynamicActionsWrapperStyleFn = ({
  showActionButtons,
}: Pick<ActionsProps, 'showActionButtons'>) => !showActionButtons && 'none';

export const DynamicActionsWrapper = styled(ActionsWrapper)`
  pointer-events: ${dynamicActionsWrapperStyleFn};
  display: ${dynamicActionsWrapperStyleFn};
`;
