import { FC, HTMLAttributes, SyntheticEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAppDispatch } from 'src/store/hooks/hooks';
import { setActionReason } from 'src/store/slices/talentModal/talentModal';
import { RejectReason, RejectReasonLabel, RejectReasons } from 'src/types/domain';
import { highlightListItem } from 'src/utils/functions/highlightListItem';
export interface Option {
  label: RejectReasonLabel;
  value: RejectReason;
}

const options: Option[] = RejectReasons.map(rejectReason => ({
  label: RejectReasonLabel[rejectReason],
  value: rejectReason,
}));

export const ReasonSelection: FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useAppDispatch();

  const onChangeHandler = (
    _: SyntheticEvent<Element, Event>,
    option: Option | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      dispatch(setActionReason(null));
    }

    if (option && option.value) {
      dispatch(setActionReason(option.value));
    }
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option) =>
    highlightListItem(props, option, inputValue);

  return (
    <DialogContent sx={{ width: '100%' }}>
      <Typography variant='body2' sx={{ marginBottom: '10px' }}>
        Reason
      </Typography>
      <Autocomplete
        disablePortal
        fullWidth
        onChange={onChangeHandler}
        options={options}
        popupIcon={<ExpandMoreIcon fontSize='medium' />}
        onInputChange={(_, value) => setInputValue(value)}
        renderInput={params => <TextField {...params} label='Select reason' size='small' />}
        renderOption={handleRenderOption}
      />
    </DialogContent>
  );
};
