import { FunctionComponent } from 'react';

import { Info } from 'src/components/Talent/components/TalentProfile/components/TalentProfileHeader/components/Info/Info';
import { Candidate } from 'src/types/domain';

import { TalentActionModal } from '../TalentActionModal';

import { TalentProfileHeaderContainer } from './styled';

export interface TalentProfileHeaderProps {
  candidate: Candidate;
}

export const TalentProfileHeader: FunctionComponent<TalentProfileHeaderProps> = ({ candidate }) => (
  <>
    <TalentActionModal />
    <TalentProfileHeaderContainer>
      <Info candidate={candidate} />
    </TalentProfileHeaderContainer>
  </>
);
