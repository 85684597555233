import { FunctionComponent } from 'react';

import { Chips } from 'src/components/Talent/components/TalentList/components/Header/components/Chips/Chips';

import { useAppSelector } from '../../../../../../store/hooks/hooks';

import { ExportList } from './components/ExportList/ExportList';
import { Filter } from './components/Filter/Filter';

import classes from './Header.module.css';
export interface HeaderProps {
  applicantsAmount: number;
}

export const Header: FunctionComponent<HeaderProps> = ({ applicantsAmount }) => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  if (!selectedJobId) {
    return null;
  }

  return (
    <div className={classes.header} data-testid='container'>
      <div className={classes.top_row}>
        <Filter applicantsAmount={applicantsAmount} />
        <div className={classes.hover_item}>
          <ExportList />
        </div>
      </div>
      <Chips selectedJobId={selectedJobId} />
    </div>
  );
};
