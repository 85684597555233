import styled from 'styled-components';

import { blue50, gray10, gray30 } from 'src/shared/components/theme/mui/colors/colors';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 40px;
  padding: 7px 2px 7px 12px;

  color: white;

  background-color: ${blue50};
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 40px;

  color: white;

  border-bottom: 1px solid ${gray30};
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;

  width: 230px;
  height: 60px;
  padding: 7px 12px 7px 12px;

  background-color: ${gray10};
`;
