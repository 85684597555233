import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  extendedGraphqlApiWithGetTalents,
  GetTalentsVariables,
} from 'src/store/api/graphql/talents/getTalents';
import { updateTalentList } from 'src/store/api/graphql/talents/utils/updateTalentList';
import { RootState } from 'src/store/store';
import { Status } from 'src/types/domain';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

export const pessimisticGetCandidate = (
  variables: { id: number | null | '' },
  {
    dispatch,
    queryFulfilled,
    state,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    queryFulfilled: Promise<any>;
    state: RootState;
  }
) => {
  queryFulfilled
    .then(data => {
      const promotionState =
        (data.data.feedback.advancementReason && 'OTHER_ADVANCED') ||
        (data.data.feedback.rejectionReason && 'OTHER_REJECTED');

      if (promotionState) {
        const latestEvent = data.data.statuses.reduce(
          (lastestTimeValue: number, status: Status) =>
            new Date(status.created).getTime() > lastestTimeValue
              ? new Date(status.created).getTime()
              : lastestTimeValue,
          0
        );
        const jobId = state.routerParameters.jobId;

        const searchParams = new URLSearchParams(state.router.location?.search);
        const endpointSelectParams: GetTalentsVariables = parseSearchParamsFilter(
          jobId,
          searchParams
        );

        const talentsResponse =
          extendedGraphqlApiWithGetTalents.endpoints.getTalents.select(endpointSelectParams)(
            state
          ).data;

        const indexOfOldItem = talentsResponse?.items.findIndex(item => item.id === variables.id);

        updateTalentList({
          dispatch,
          indexOfOldItem,
          newStatus: promotionState,
          newStatusTimestamp: latestEvent,
          selectParams: endpointSelectParams,
        });
      }
    })
    .catch(() => {
      //no reason to handle this since this is a pessimistic update
    });
};
