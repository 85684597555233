import React, { FC } from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { ReactComponent as EmptyNotesImage } from 'src/assets/EmptyNotes.svg';

export const EmptyNotes: FC = () => (
  <Stack direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '44px' }}>
    <Typography variant='smallTitle'>Be the first to leave a note about this applicant</Typography>
    <Typography variant='subHeading2' sx={{ marginTop: '8px' }}>
      Share feedback, log your attempts to contact them, or suggest next steps
    </Typography>
    <EmptyNotesImage style={{ marginTop: '24px' }} />
  </Stack>
);
