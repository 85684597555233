import { matchPath } from 'react-router-dom';

import { setActiveDialog } from 'src/store/slices/talentNotes/talentNotes';

import { routePaths } from '../../constants';
import { finishDemo } from '../slices/chatDemo/chatDemo';
import { setParameters } from '../slices/routerParameters/routerParameters';

export function routeChange(store: any) {
  return function wrapDispatch(next: any) {
    return function handleAction(action: any) {
      if (action?.type === '@@router/LOCATION_CHANGE') {
        if (store.getState().talentNotes.tempNote && action.payload.location.pathname) {
          store.dispatch(setActiveDialog(action.payload.location.pathname));

          return;
        }

        if (store.getState().chatDemo.candidateId) {
          store.dispatch(finishDemo());
        }

        if (action.payload?.location?.pathname) {
          let matchParams;
          Object.values(routePaths).forEach(route => {
            const match = matchPath(route(), action.payload?.location?.pathname);

            if (match) {
              matchParams = match.params;
            }
          });

          if (matchParams) {
            store.dispatch(setParameters(matchParams));
          } else {
            store.dispatch(setParameters({}));
          }
        }
      }

      return next(action);
    };
  };
}
