import React, { FunctionComponent } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

import {
  PaginationArrowsWrapper,
  PaginationArrowWrapper,
  PaginatorNumber,
  PaginatorSeparator,
  PaginatorWrapper,
} from './styled';

export interface PaginationProps {
  currentListLength: number;
  currentIndex: number;
  itemLabel?: string;
  handlePrevious: () => void;
  handleNext: () => void;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  handlePrevious,
  currentIndex,
  currentListLength,
  handleNext,
  itemLabel,
}) => {
  const isPrevButtonDisabled = currentIndex === 0;
  const isNextButtonDisabled =
    (currentListLength && currentIndex === currentListLength - 1) || false;

  return (
    <PaginatorWrapper>
      <PaginatorNumber>
        {itemLabel} {(currentIndex + 1).toLocaleString()} of {currentListLength.toLocaleString()}
      </PaginatorNumber>

      <PaginationArrowsWrapper>
        <PaginationArrowWrapper disabled={isPrevButtonDisabled}>
          <IconButton
            data-testid='left-arrow'
            onClick={handlePrevious}
            disabled={isPrevButtonDisabled}
            size={'small'}
            sx={{ padding: 0 }}
          >
            <KeyboardArrowLeftIcon
              htmlColor={isPrevButtonDisabled ? 'var(--stem-grey)' : 'var(--blumine)'}
            />
          </IconButton>
        </PaginationArrowWrapper>

        <PaginatorSeparator />
        <PaginationArrowWrapper disabled={isNextButtonDisabled}>
          <IconButton
            data-testid='right-arrow'
            onClick={handleNext}
            disabled={isNextButtonDisabled}
            size={'small'}
            sx={{ padding: 0 }}
          >
            <KeyboardArrowRightIcon
              htmlColor={isNextButtonDisabled ? 'var(--stem-grey)' : 'var(--blumine)'}
            />
          </IconButton>
        </PaginationArrowWrapper>
      </PaginationArrowsWrapper>
    </PaginatorWrapper>
  );
};
