import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface TalentInfoSectionProps {
  itemHeader?: string | ReactNode;
  itemBody: ReactNode;
}

const TalentInfoSectionHeader = styled.div`
  margin-bottom: 18px;
`;

export const TalentInfoSection: FunctionComponent<TalentInfoSectionProps> = ({
  itemHeader,
  itemBody,
}) => (
  <div className='TalentInfoSection'>
    {itemHeader && <TalentInfoSectionHeader>{itemHeader}</TalentInfoSectionHeader>}
    {itemBody}
  </div>
);
