import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';

import { ReactComponent as InformationIcon } from 'src/assets/Information.svg';
import { TalentInfoSectionSeparator } from 'src/components/Resume/styles/styles';
import { UnansweredQuestionContainer } from 'src/components/Talent/components/TalentProfile/components/Questions/styled';
import { blue70 } from 'src/shared/components/theme/mui/colors/colors';
import { ChatStatus, ChatSummary } from 'src/types/domain';

import { Information } from './components/Information/Information';
import { Section } from './components/Section/Section';
import { useQuestions } from './hooks';

import classes from './Questions.module.css';

export interface QuestionsProps {
  chatStatus: ChatStatus;
  chatSummaries: ChatSummary[];
  name: {
    firstName: string;
    lastName: string;
  };
}

export const Questions: FunctionComponent<QuestionsProps> = ({
  chatStatus,
  chatSummaries,
  name,
}) => {
  const { sections } = useQuestions({
    chatSummaries,
  });

  return (
    <div className={classes.container}>
      {chatStatus === ChatStatus.STARTED && (
        <UnansweredQuestionContainer>
          <InformationIcon />
          <Typography sx={{ color: blue70 }} variant='body1'>
            {chatSummaries.length > 0
              ? `This applicant hasn't answered all the questions`
              : `This applicant started the chat but hasn't answered any questions`}
          </Typography>
        </UnansweredQuestionContainer>
      )}
      {chatStatus === ChatStatus.NOT_STARTED ? (
        <Information name={name} />
      ) : (
        <>
          {sections.map((section, index) =>
            section.items.length > 0 ? (
              <div key={section.label}>
                <Section title={section.label} questionsList={section.items} />
                {index < sections.length - 1 && <TalentInfoSectionSeparator />}
              </div>
            ) : null
          )}
        </>
      )}
    </div>
  );
};
