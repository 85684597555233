export const MuiFormControlLabel = {
  styleOverrides: {
    label: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18.2px',
      marginLeft: '6px',
    },
    root: {
      margin: '0px',
    },
  },
};
