import { FC } from 'react';
import Typography from '@mui/material/Typography';

import { formatDateTypeO } from 'src/utils/functions/dateFormatters';
export interface DateRangeProps {
  label: string;
  values: string[];
}

export const DateRange: FC<DateRangeProps> = ({ label, values }) => {
  let sortedValues: string[] = [];

  if (values.length === 2) {
    if (values[0] > values[1]) {
      sortedValues = [values[1], values[0]];
    } else {
      sortedValues = values;
    }

    sortedValues = sortedValues.map(item => formatDateTypeO(new Date(+item)));
  } else {
    sortedValues = ['Invalid Date', 'Invalid Date'];
  }

  const formattedValues = `${sortedValues[0]} - ${sortedValues[1]}`;
  return (
    <>
      <Typography variant='body7'>{label}:</Typography>{' '}
      <Typography variant='smallt1'>{formattedValues}</Typography>
    </>
  );
};
