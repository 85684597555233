import React, { FC } from 'react';

import { WorkHistoryWrapper } from 'src/components/Talent/components/TalentProfile/components/Details/styles';
import { WorkExperience } from 'src/types/domain';

import { Item } from './components/Item/Item';
export interface ContentProps {
  workExperiences: WorkExperience[];
}

export const Content: FC<ContentProps> = ({ workExperiences }) => (
  <WorkHistoryWrapper>
    {workExperiences.map((exp, index) => (
      <Item key={'exp' + index} item={exp} />
    ))}
  </WorkHistoryWrapper>
);
