import { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

export const updateInputs = (
  range: DateRange | undefined,
  setStartDayInput: Dispatch<SetStateAction<string>>,
  setStartDayInputError: Dispatch<SetStateAction<boolean>>,
  setEndDayInput: Dispatch<SetStateAction<string>>,
  setEndDayInputError: Dispatch<SetStateAction<boolean>>
) => {
  if (range && range.from) {
    try {
      range.from.getTime();
      setStartDayInput(formatDateTypeO(range.from));
      setStartDayInputError(false);
    } catch {
      setStartDayInput('');
      setStartDayInputError(true);
    }
  } else {
    setStartDayInput('');
  }

  if (range && range.to) {
    try {
      range.to.getTime();
      setEndDayInput(formatDateTypeO(range.to));
      setEndDayInputError(false);
    } catch {
      setEndDayInput('');
      setEndDayInputError(true);
    }
  } else {
    setEndDayInput('');
  }
};
