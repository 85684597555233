import styled from 'styled-components';

export const TalentProfileHeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding: 20px 22px 0 30px;

  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
