import { gql } from 'graphql-request';

import { pessimisticNewNote } from 'src/store/api/graphql/talents/utils/pessimisticNewNote';
import { RootState } from 'src/store/store';

import { graphqlApi } from '../graphql';

export const graphqlApiWithAddNote = graphqlApi.injectEndpoints({
  endpoints: builder => ({
    addNote: builder.mutation({
      onQueryStarted: async (variables, { dispatch, queryFulfilled, getState }) =>
        await pessimisticNewNote(variables, {
          dispatch,
          queryFulfilled,
          state: getState() as RootState,
        }),
      query: variables => ({
        document: gql`
          mutation addNote($opportunityId: ID!, $message: String!) {
            addNote(opportunityId: $opportunityId, message: $message) {
              created
              message
              recruiterInfo {
                firstName
                lastName
              }
            }
          }
        `,
        variables,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useAddNoteMutation } = graphqlApiWithAddNote;
