export enum TextTypographyType {
  TITLE = 'title',
  H_1 = 'h_1',
  H_2 = 'h_2',
  SH_1 = 'sh_1',
  SH_2 = 'sh_2',
  SH_3 = 'sh_3',
  BODY_1 = 'body_1',
  BODY_2 = 'body_2',
  BODY_3 = 'body_3',
  BODY_4 = 'body_4',
  BODY_5 = 'body_5',
  BODY_6 = 'body_6',
  BODY_7 = 'body_7',
  BODY_8 = 'body_8',
}
