import { FC, useContext, useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';

import { Navigation } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Calendar/components/Navigation/Navigation';
import { isDayOutOfBounds } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Calendar/utils/isDayOutOfBounds';
import { updateMonth } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/components/Calendar/utils/updateMonth';
import { DateContext } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';

import './Calendar.css';
const today = new Date();

export const Calendar: FC = () => {
  const { range, setDate, selectedDateInput, earliestDate } = useContext(DateContext);
  const [month, setMonth] = useState<Date>(today);

  useEffect(() => {
    updateMonth(range, selectedDateInput, setMonth);
  }, [range, selectedDateInput]);

  return (
    <>
      <Navigation month={month} setMonth={setMonth} />
      <DayPicker
        numberOfMonths={2}
        month={month}
        mode={'range'}
        selected={range}
        disabled={(day: Date) => isDayOutOfBounds(day, new Date(earliestDate))}
        formatters={{
          formatWeekdayName: x => x.toLocaleString('en-us', { weekday: 'short' })[0],
        }}
        onSelect={(_: DateRange | undefined, selectedDate: Date) => {
          setDate(selectedDate);
        }}
        disableNavigation={true}
      />
    </>
  );
};
