import { FC } from 'react';

import { SubheaderTitle } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Subheader/styled';
import { SubHeaderContainer } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/styled';
interface SubheaderProps {
  text: string;
}

export const Subheader: FC<SubheaderProps> = ({ text }) => (
  <SubHeaderContainer>
    <SubheaderTitle variant='body7' align='left'>
      {text}
    </SubheaderTitle>
  </SubHeaderContainer>
);
