import { touchRippleClasses } from '@mui/material';
import { tabClasses } from '@mui/material/Tab';
export const MuiTab = {
  styleOverrides: {
    root: {
      fontSize: '14px',
      fontWeight: 400,
      justifyContent: 'start',
      lineHeight: '18.2px',
      padding: '0px 0px 8px 0px',
      [`&.${tabClasses.selected}`]: { fontWeight: '700' },
      [`& .${touchRippleClasses.root}`]: {
        borderRadius: '5px',
      },
    },
  },
};
