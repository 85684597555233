import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { addNotification } from 'src/store/slices/notifications/notifications';
import { NotificationOptions } from 'src/store/types/types';

export const setOnHoldStatusErrorNotificationOptions: NotificationOptions = {
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  autoHideDuration: 3000,
  variant: 'default',
};

export const setOnHoldStatusErrorNotification = ({
  dispatch,
  userInfo,
  onHold,
}: {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  userInfo?: {
    firstName: string;
    lastName: string;
  };
  onHold: boolean;
}) => {
  if (userInfo) {
    const message = `${userInfo.firstName} ${userInfo.lastName} couldn’t be ${
      !onHold ? 'removed' : 'placed'
    } on hold.\n Please try again`;
    dispatch(
      addNotification({
        message,
        options: setOnHoldStatusErrorNotificationOptions,
      })
    );
  }
};
