import { blue50, blue60, green30, hoverGreen, red } from '../colors/colors';

export const MuiButton = {
  styleOverrides: {
    containedPrimary: {
      '&:hover': {
        backgroundColor: blue50,
        boxShadow: 'none',
      },
      'backgroundColor': blue60,
      'boxShadow': 'none',
    },
    containedSuccess: {
      '&:hover': {
        backgroundColor: hoverGreen,
      },
      'backgroundColor': green30,
    },
    endIcon: {
      marginLeft: '6px',
    },
    outlinedError: {
      borderColor: red,
      borderWidth: '1px',
      color: red,
    },
    outlinedPrimary: {
      '&:hover': {
        borderColor: blue50,
        color: blue50,
      },
      'borderColor': blue60,
      'color': blue60,
    },
    root: {
      '&:hover': {
        boxShadow: 'none',
      },
      'boxShadow': 'none',
      'padding': '5px 10px 5px 10px',
    },
    textPrimary: {
      color: blue50,
    },
  },
};
