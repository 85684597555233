import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import styled, { ThemedStyledProps } from 'styled-components';

import { blue30, gray60, menuOutline, red } from 'src/shared/components/theme/mui/colors/colors';

interface DateInputProps extends InputBaseProps {
  focused: string;
}

const getDateInputBorder = (props: ThemedStyledProps<InputBaseProps & DateInputProps, any>) => {
  if (props.error) {
    return '1px solid white !important';
  }

  if (props.focused !== 'true') {
    return `1px solid ${menuOutline}`;
  }

  return '1px solid white';
};

const getDateInputOutline = (props: ThemedStyledProps<InputBaseProps & DateInputProps, any>) => {
  if (props.error) {
    return `3px solid ${red} !important`;
  }

  if (props.focused === 'true') {
    return `3px solid ${blue30} !important`;
  }

  return '1px solid white';
};

export const DateInput = styled(InputBase)<DateInputProps>(props => ({
  '& .MuiInputBase-input': {
    alignItems: 'center',
    border: getDateInputBorder(props),
    borderRadius: 4,
    color: gray60,
    display: 'flex',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    maxHeight: '40px',
    maxWidth: '95px',
    outline: getDateInputOutline(props),
    padding: '8px 8px 8px 12px',
    position: 'relative',
  },
  '+ .Mui-error .MuiInputBase-input': {
    outline: `3px solid ${blue30}`,
  },
  '+ .Mui-focused .MuiInputBase-input': {
    outline: `3px solid ${blue30}`,
  },
  'label + &': {
    marginTop: 2,
  },
}));
