import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { gray30 } from 'src/shared/components/theme/mui/colors/colors';

export const SubheaderTitle = styled(Typography)`
  display: flex;
  align-items: center;

  height: 40px;
  padding: 7px 12px 7px 12px;

  border-bottom: 1px solid ${gray30};
`;
