import { TextTypographyType } from '../api';

import classes from './Text.module.css';

export const getTextTypographyClassname = (type: TextTypographyType | undefined): string => {
  switch (type) {
    case TextTypographyType.BODY_1:
      return classes['text__typography-type--body1'];

    case TextTypographyType.BODY_2:
      return classes['text__typography-type--body2'];

    case TextTypographyType.BODY_3:
      return classes['text__typography-type--body3'];

    case TextTypographyType.BODY_4:
      return classes['text__typography-type--body4'];

    case TextTypographyType.BODY_5:
      return classes['text__typography-type--body5'];

    case TextTypographyType.BODY_6:
      return classes['text__typography-type--body6'];

    case TextTypographyType.BODY_7:
      return classes['text__typography-type--body7'];

    case TextTypographyType.BODY_8:
      return classes['text__typography-type--body8'];

    case TextTypographyType.H_1:
      return classes['text__typography-type--h1'];

    case TextTypographyType.H_2:
      return classes['text__typography-type--h2'];

    case TextTypographyType.SH_1:
      return classes['text__typography-type--sh1'];

    case TextTypographyType.SH_2:
      return classes['text__typography-type--sh2'];

    case TextTypographyType.SH_3:
      return classes['text__typography-type--sh3'];

    case TextTypographyType.TITLE:
      return classes['text__typography-type--title'];

    default:
      return '';
  }
};
