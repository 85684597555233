import { NavigateFunction } from 'react-router';

import { routePaths } from 'src/constants';
import { Talent } from 'src/types/domain';

export interface NavigateIfNotFound {
  isEverythingLoaded: boolean;
  combinedFilteredTalentList: Talent[];
  selectedTalentId: number | null;
  selectedJobId: number | null;
  searchParams: string | undefined;
  navigate: NavigateFunction;
}

export const navigateIfNotFound = ({
  isEverythingLoaded,
  combinedFilteredTalentList,
  selectedTalentId,
  selectedJobId,
  searchParams,
  navigate,
}: NavigateIfNotFound): void => {
  if (isEverythingLoaded && selectedJobId) {
    if (
      combinedFilteredTalentList &&
      combinedFilteredTalentList.length > 0 &&
      !combinedFilteredTalentList.find(
        talent => selectedTalentId && talent.id === +selectedTalentId
      )
    ) {
      navigate(
        routePaths.jobsWithIdListTypeTalentid(
          selectedJobId,
          'talents',
          combinedFilteredTalentList[0].id
        ) + `?${searchParams}`
      );
    } else if (combinedFilteredTalentList.length === 0) {
      navigate(
        routePaths.jobsWithIdListTypeTalentid(selectedJobId, 'talents', ':talentId') +
          `?${searchParams}`
      );
    }
  }
};
