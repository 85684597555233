import { useEffect, useRef } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from 'src/store/hooks/hooks';
import { removeNotification } from 'src/store/slices/notifications/notifications';

export const useNotifier = () => {
  const displayed = useRef<string[]>([]);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(state => state.notifications.notifications);
  const { enqueueSnackbar } = useSnackbar();

  const storeDisplayed = (keyToAdd: string) => {
    displayed.current = [...displayed.current, keyToAdd];
  };

  const removeDisplayed = (keyToDelete: string) => {
    displayed.current = [...displayed.current.filter(key => key !== keyToDelete)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options }) => {
      if (displayed.current.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (_, key: SnackbarKey) => {
          if (typeof key === 'string') {
            dispatch(removeNotification({ key }));
            removeDisplayed(key);
          }
        },
      });
      storeDisplayed(key);
    });
  }, [notifications, enqueueSnackbar, dispatch]);
};
