import React, { FC, useMemo } from 'react';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

import { RejectOrAdvanceOrInterviewedTitleWrapper } from '../../styled';

interface AdvancedTextProps {
  advancedBy?: {
    firstName?: string;
    lastName?: string;
  };
  advancementDate?: Date;
}

export const AdvancedText: FC<AdvancedTextProps> = ({ advancementDate, advancedBy }) => {
  const advancedOnElement = useMemo(() => {
    if (advancementDate) {
      return (
        <>
          <Text
            typographyType={TextTypographyType.BODY_1}
            color={'var(--seal-blue)'}
            text={'on'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Text
            typographyType={TextTypographyType.BODY_4}
            color={'var(--seal-blue)'}
            text={formatDateTypeO(advancementDate)}
            style={{ wordBreak: 'break-all' }}
          />
        </>
      );
    }
  }, [advancementDate]);

  const advancedByElement = useMemo(() => {
    if (advancedBy?.firstName || advancedBy?.lastName) {
      return (
        <>
          <Text
            typographyType={TextTypographyType.BODY_1}
            color={'var(--seal-blue)'}
            text={'by'}
            style={{ whiteSpace: 'nowrap' }}
          />
          <Text
            typographyType={TextTypographyType.BODY_4}
            color={'var(--seal-blue)'}
            text={`${advancedBy.firstName} ${advancedBy.lastName}`}
            style={{ wordBreak: 'break-all' }}
          />
        </>
      );
    }
  }, [advancedBy]);

  return (
    <RejectOrAdvanceOrInterviewedTitleWrapper>
      <Text
        typographyType={TextTypographyType.BODY_2}
        color={'var(--dark-green)'}
        text={'Advanced'}
      />
      <>
        {advancedOnElement}

        {advancedByElement}
      </>
    </RejectOrAdvanceOrInterviewedTitleWrapper>
  );
};
