import { FunctionComponent, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Actions } from 'src/components/Talent/components/TalentProfile/components/TalentProfileHeader/components/Info/components/Actions/Actions';
import { ChatActions } from 'src/components/Talent/components/TalentProfile/components/TalentProfileHeader/components/Info/components/ChatActions/ChatActions';
import { Pagination } from 'src/components/Talent/components/TalentProfile/components/TalentProfileHeader/components/Info/components/Pagination/Pagination';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { extendedGraphqlApiWithGetCandidate } from 'src/store/api/graphql/talents/getCandidate';
import { useGetTalentsQuery } from 'src/store/api/graphql/talents/getTalents';
import { useAppDispatch, useAppSelector } from 'src/store/hooks/hooks';
import { store } from 'src/store/store';
import { Candidate } from 'src/types/domain';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

import { AdvancedText } from './components/AdvancedText/AdvancedText';
import { Awards } from './components/Awards/Awards';
import { RejectedText } from './components/RejectedText/RejectedText';
import { handleNext } from './utils/handleNext';
import { handlePrevious } from './utils/handlePrevious';
import { InfoActions, InfoWrapper } from './styled';

export interface InfoProps {
  candidate: Candidate;
}

export const Info: FunctionComponent<InfoProps> = ({ candidate }) => {
  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);
  const selectedTalentId = useAppSelector(state => state.routerParameters.talentId);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentData: talents } = useGetTalentsQuery(
    parseSearchParamsFilter(selectedJobId, searchParams),
    { skip: !selectedJobId }
  );

  const indexOfThisTalent = useMemo(
    () => talents?.items.findIndex(talent => selectedTalentId && talent.id === +selectedTalentId),
    [selectedTalentId, talents]
  );
  const dispatch = useAppDispatch();

  const candidateStatus = useMemo(() => {
    const statuses = [...candidate.statuses];

    return statuses
      .filter(item => item.status !== 'SUBMITTED_TO_CLIENT')
      .sort((a, b) => {
        const dateA = Number(new Date(a.created));
        const dateB = Number(new Date(b.created));

        return dateB - dateA;
      })[0];
  }, [candidate.statuses]);

  useEffect(() => {
    if (talents && indexOfThisTalent !== undefined) {
      const isNotTheLastTalent = indexOfThisTalent + 1 < talents?.totalCount;
      const talentDoesExist = indexOfThisTalent > -1;
      const isNextNotAlreadyFetched =
        talentDoesExist &&
        isNotTheLastTalent &&
        extendedGraphqlApiWithGetCandidate.endpoints.getCandidate.select({
          id: talents.items[indexOfThisTalent + 1].id,
        })(store.getState()).isUninitialized;

      if (talentDoesExist && isNotTheLastTalent && isNextNotAlreadyFetched) {
        dispatch<any>(
          extendedGraphqlApiWithGetCandidate.endpoints.getCandidate.initiate({
            id: talents.items[indexOfThisTalent + 1].id,
          })
        );
      }
    }
  }, [dispatch, selectedTalentId, talents, indexOfThisTalent]);

  const showActionButtons = !(
    candidate?.feedback?.advancementReason || candidate?.feedback?.rejectionReason
  );
  const linkedInUrl = '';

  if (!selectedJobId || !talents || indexOfThisTalent === undefined) {
    return <></>;
  }

  const handlerParams = {
    combinedFilteredTalents: talents.items,
    indexOfThisTalent,
    navigate,
    searchParams: searchParams.toString() || '',
    selectedJobId,
  };

  const candidateWorkTitle =
    candidate.person.workExperience &&
    candidate.person.workExperience.length > 0 &&
    candidate.person.workExperience[0].title;

  return (
    <InfoWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text
          text={`${candidate.person.name.firstName} ${candidate.person.name.lastName}`}
          typographyType={TextTypographyType.H_2}
          color={'var(--seal-blue)'}
          style={{
            width: '100%',
            wordBreak: 'break-all',
          }}
        />
        <Pagination
          itemLabel='applicant'
          currentListLength={talents.totalCount}
          currentIndex={indexOfThisTalent}
          handleNext={() => handleNext(handlerParams)}
          handlePrevious={() => handlePrevious(handlerParams)}
        />
      </div>

      {candidateWorkTitle && (
        <Text
          style={{ marginTop: '10px' }}
          text={candidateWorkTitle}
          color={'var(--seal-blue)'}
          typographyType={TextTypographyType.BODY_6}
        />
      )}
      <Awards linkedInUrl={linkedInUrl} />
      <InfoActions>
        {(candidate?.feedback?.advancementReason || candidate?.feedback?.rejectionReason) && (
          <div>
            {candidate?.feedback?.advancementReason && (
              <AdvancedText
                advancedBy={{
                  firstName: candidateStatus?.recruiterInfo?.firstName,
                  lastName: candidateStatus?.recruiterInfo?.lastName,
                }}
                advancementDate={candidateStatus ? new Date(candidateStatus.created) : undefined}
              />
            )}
            {candidate?.feedback?.rejectionReason && (
              <RejectedText
                rejectedBy={{
                  firstName: candidateStatus?.recruiterInfo?.firstName,
                  lastName: candidateStatus?.recruiterInfo?.lastName,
                }}
                rejectionDate={candidateStatus ? new Date(candidateStatus.created) : undefined}
                rejectedReason={candidate.feedback?.rejectionReason}
              />
            )}
          </div>
        )}
        <Actions
          talentInfo={{
            hasChat: candidate.job.hasChat,
            id: candidate.id,
            onHold: candidate.onHold,
            onHoldBy: candidate.onHoldBy,
            onHoldSince: candidate.onHoldSince,
          }}
          showActionButtons={showActionButtons}
        />
        <ChatActions
          talentInfo={{
            chatStatus: candidate.chatStatus,
            hasChat: candidate.job.hasChat,
            id: candidate.id,
            neoId: candidate.neoId,
          }}
        />
      </InfoActions>
    </InfoWrapper>
  );
};
