import { PayloadAction } from '@reduxjs/toolkit';

import { TalentNotesState } from 'src/store/types/types';

export const setActiveDialogAction = (
  state: TalentNotesState,
  action: PayloadAction<string | undefined>
) => {
  state.activeDialog = action.payload;
};
