import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Callback } from 'src/components/Callback/Callback';
import { RequireAuth } from 'src/components/RequireAuth/RequireAuth';
import { Spinner } from 'src/shared/components';
import { JobsContent } from 'src/views/JobsContainer/components/JobsContent/JobsContent';
import { useNotifier } from 'src/views/JobsContainer/hooks/useNotifier';

const AuthCheck = lazy(() =>
  import('src/views/AuthCheck/AuthCheck').then(module => ({
    default: module.AuthCheck,
  }))
);
const HomeLayout = lazy(() =>
  import('src/views/HomeLayout/HomeLayout').then(module => ({
    default: module.HomeLayout,
  }))
);
const JobsContainer = lazy(() =>
  import('src/views/JobsContainer/JobsContainer').then(module => ({
    default: module.JobsContainer,
  }))
);
const FourOhFour = lazy(() =>
  import('src/components/FourOhFour').then(module => ({
    default: module.FourOhFour,
  }))
);
const FourOhOne = lazy(() =>
  import('src/components/FourOhOne').then(module => ({
    default: module.FourOhOne,
  }))
);

export default function App(): JSX.Element {
  const { pathname } = useLocation();
  useNotifier();
  useEffect(() => {
    if (!pathname.includes('callback')) {
      localStorage.setItem('initialPath', pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <Suspense fallback={<Spinner />}>
            <AuthCheck />
          </Suspense>
        }
      />
      <Route
        path='callback'
        element={
          <Suspense fallback={<Spinner />}>
            <Callback />
          </Suspense>
        }
      />
      <Route
        path='jobs'
        element={
          <RequireAuth>
            <Suspense fallback={<Spinner />}>
              <HomeLayout />
            </Suspense>
          </RequireAuth>
        }
      >
        <Route index element={<JobsContainer />} />
        <Route path=':jobId' element={<JobsContainer />}>
          <Route path=':listType/:talentId' element={<JobsContent />} />
        </Route>
      </Route>
      <Route path='401' element={<FourOhOne />} />
      <Route path='*' element={<FourOhFour />} />
    </Routes>
  );
}
