import { AdvanceReason, RejectReason } from '../../types/domain';

export enum TalentPromotion {
  ADVANCE = 'advance',
  REJECT = 'reject',
}

export enum JobSort {
  AZ = 'A-Z',
  OPEN_DATE = 'Open Date',
}
export enum MenuType {
  OPEN = 'open',
  CLOSED = 'closed',
}
export interface ChangeTalentState {
  jobId: number;
  candidateId: number;
  advancementReason: AdvanceReason | null;
  notes: string;
  rejectionReason: RejectReason | 'OTHER' | null;
}

export interface JobsInitialState {
  selectedMenu: MenuType;
  filter: {
    type: JobSort;
    search: string;
  };
}
export interface TalentModalState {
  action: {
    type: TalentPromotion | null;
    reason: RejectReason | null;
  };
}
export interface SetFilterParams {
  type?: JobSort | undefined;
  search?: string | undefined;
}
export interface RouterParametersInitialState {
  jobId: number | null;
  listType: string | null;
  talentId: string | number | null;
  scrollTalent: boolean;
  scrollJob: boolean;
}
export enum Headers {
  Authorization = 'authorization',
}

export interface NotificationWithKey extends Notification {
  key: string;
}

export interface NotificationOptions {
  variant: 'warning' | 'error' | 'success' | 'info' | 'default';
  anchorOrigin: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'bottom';
  };
  autoHideDuration: number;
}
export interface Notification {
  message: string;
  options: NotificationOptions;
}

export interface NotificationsState {
  notifications: NotificationWithKey[];
}

export interface ClientDetails {
  chatsEnabled: boolean;
  demoEnabled: boolean;
}

export interface TalentNotesState {
  tempNote: string | undefined;
  activeInput: boolean;
  activeDialog: string | undefined;
}
