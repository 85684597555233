import { PayloadAction } from '@reduxjs/toolkit';

import { NotificationsState } from 'src/store/types/types';

export interface RemoveNotificationAction {
  key: string;
}

export const removeNotificationAction = (
  state: NotificationsState,
  action: PayloadAction<RemoveNotificationAction>
) => {
  state.notifications = state.notifications.filter(item => item.key !== action.payload.key);
};
