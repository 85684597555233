import { FC } from 'react';
import Button from '@mui/material/Button';

import { ReactComponent as JobChatIcon } from 'src/assets/JobChat.svg';
import { useClientDetailsQuery } from 'src/store/api/graphql/clientsDetails';
import { useResetChatStatusMutation } from 'src/store/api/graphql/talents/resetCandidacyChat';
import { ChatStatus, Talent } from 'src/types/domain';

import { ChatActionsWrapper } from './styled';

type TelentInfo = Pick<Talent, 'id' | 'neoId' | 'chatStatus'> & {
  hasChat: boolean;
};

export interface ChatActionsProps {
  talentInfo: TelentInfo;
}

export const ChatActions: FC<ChatActionsProps> = ({ talentInfo }) => {
  const { data: clientsDetails } = useClientDetailsQuery();
  const [resetChatStatus, { isLoading: isResetingChatStatus }] = useResetChatStatusMutation();

  const showChatBtns = talentInfo.hasChat && clientsDetails?.demoEnabled;

  const handleResetAndOpenChat = () => {
    if (talentInfo.chatStatus !== ChatStatus.NOT_STARTED) {
      const res = confirm('Please confirm chat reset'); // eslint-disable-line no-alert

      if (!res) {
        return;
      }
    }

    resetChatStatus({
      candidateId: talentInfo.neoId,
      sqlId: talentInfo.id,
    });
  };

  return (
    <>
      {showChatBtns && (
        <ChatActionsWrapper>
          <Button
            data-testid='open-chat-btn'
            onClick={handleResetAndOpenChat}
            variant='contained'
            color='error'
            endIcon={<JobChatIcon />}
            disabled={isResetingChatStatus}
          >
            Reset & Open Chat
          </Button>
        </ChatActionsWrapper>
      )}
    </>
  );
};
