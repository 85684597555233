import { WorkExperience } from 'src/types/domain';
import { formatDate } from 'src/utils/functions/dateFormatters';

import { getExperienceDuration } from './getExperienceDuration';

export interface GetWorkDuration {
  startYearMonth: WorkExperience['start'];
  endYearMonth: WorkExperience['end'];
  isCurrentEmployer: WorkExperience['isCurrent'];
}

export const getWorkDuration = ({
  startYearMonth,
  endYearMonth,
  isCurrentEmployer,
}: GetWorkDuration) => {
  const formattedStartingDate = formatDate(startYearMonth);
  const formattedEndDate = isCurrentEmployer ? 'Present' : formatDate(endYearMonth);
  const expDuration = getExperienceDuration({
    endYearMonth,
    isCurrentEmployer,
    startYearMonth,
  });

  return (
    formattedStartingDate +
    (formattedStartingDate && formattedEndDate && ' — ') +
    formattedEndDate +
    ((formattedStartingDate || formattedEndDate) && expDuration && ' | ') +
    expDuration
  );
};
