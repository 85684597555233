import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { extendedGraphqlApiWithGetCandidate } from 'src/store/api/graphql/talents/getCandidate';
import { ChatStatus, RecruiterInfo, RejectReason, Status } from 'src/types/domain';

export interface UpdateCandidateDetails {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  candidateId: number;
  advancementReason?: 'OTHER';
  rejectionReason?: RejectReason | 'OTHER' | null;
  newStatus?: Status[];
  chatStatus?: ChatStatus;
  onHold?: boolean;
  onHoldSince?: string;
  onHoldBy?: RecruiterInfo;
}

export const updateCandidateDetails = ({
  dispatch,
  candidateId,
  advancementReason,
  rejectionReason,
  chatStatus,
  onHold,
  onHoldSince,
  onHoldBy,
  newStatus,
}: UpdateCandidateDetails) =>
  dispatch(
    extendedGraphqlApiWithGetCandidate.util.updateQueryData(
      'getCandidate',
      { id: candidateId },
      draft => {
        const updatedCandidate = { ...draft };

        updatedCandidate.onHold = onHold ?? updatedCandidate.onHold;
        updatedCandidate.onHoldSince =
          onHoldSince !== undefined ? onHoldSince : updatedCandidate.onHoldSince;
        updatedCandidate.onHoldBy = onHoldBy !== undefined ? onHoldBy : updatedCandidate.onHoldBy;
        updatedCandidate.statuses = newStatus
          ? [...updatedCandidate.statuses, ...newStatus]
          : updatedCandidate.statuses;
        updatedCandidate.feedback = {
          advancementReason: advancementReason ?? updatedCandidate.feedback.advancementReason,
          notes: '',
          rejectionReason: rejectionReason ?? updatedCandidate.feedback.rejectionReason,
        };
        updatedCandidate.chatStatus = chatStatus ?? updatedCandidate.chatStatus;

        if (updatedCandidate.chatStatus === ChatStatus.NOT_STARTED) {
          updatedCandidate.chatSummaries = [];
        }

        return updatedCandidate;
      }
    )
  );
