import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { api_config } from 'src/constants';

import { graphqlBaseQuery } from './utils/graphqlBaseQuery';

export const graphqlApi = createApi({
  baseQuery: graphqlBaseQuery({
    baseUrl: api_config.graphql,
  }),
  // tagTypes: ['Candidate', 'Talents'],
  endpoints: () => ({}),

  reducerPath: 'graphqlApi',
});
