import { DateRange } from 'react-day-picker';
import { isDate } from 'date-fns';

import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

export const initialDayInput = (range: DateRange | undefined, selector: keyof DateRange) => {
  if (!range || !range[selector]) {
    return '';
  }

  try {
    if (!isDate(range[selector])) {
      throw Error('Invalid date');
    }

    const formattedDate = formatDateTypeO(range[selector] as Date);
    return formattedDate;
  } catch {
    return '';
  }
};
