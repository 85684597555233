import { DateRange } from 'src/types/domain';

export const formatDateRangeParams = (params: string[]): DateRange | null => {
  if (params.length === 2) {
    return {
      endDate: parseInt(params[1]),
      startDate: parseInt(params[0]),
    };
  }

  return null;
};
