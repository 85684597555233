import { CSSProperties, forwardRef, HTMLAttributes, ReactElement } from 'react';
import clsx from 'clsx';

import { TextTypographyType } from '../api';

import { getTextTypographyClassname } from './utils';

import classes from './Text.module.css';

export interface TextProps
  extends Pick<HTMLAttributes<HTMLParagraphElement>, 'onMouseOver' | 'onMouseOut' | 'onClick'> {
  typographyType?: TextTypographyType | undefined;
  text?: string | ReactElement;
  color?: string;
  style?: CSSProperties;
  className?: string;
  asLink?: boolean;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      typographyType = TextTypographyType.BODY_1,
      text = '',
      color,
      style,
      className,
      asLink,
      ...props
    },
    ref
  ) => (
    <p
      style={{ color, ...style }}
      className={clsx(classes['text'], className, getTextTypographyClassname(typographyType), {
        [classes['text--as-link']]: asLink,
      })}
      ref={ref}
      {...props}
    >
      {text}
    </p>
  )
);

Text.displayName = 'Text';
