import { FC, KeyboardEvent } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DateMenu } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DateMenu/DateMenu';
import { DatePicker } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/components/DatePicker/DatePicker';
import { DateProvider } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/context/DateContext';
import { FilterProperty } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { gray30 } from 'src/shared/components/theme/mui/colors/colors';

export interface DateProps {
  value: FilterProperty;
  onClose: () => void;
}

export const Date: FC<DateProps> = ({ onClose }) => {
  const stopPropagationForTab = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <DateProvider>
      <Grid
        sx={{ width: '650px' }}
        container
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'
        component={Box}
        onKeyDown={stopPropagationForTab}
      >
        <Grid item component={Box} sx={{ borderRight: `1px solid ${gray30}`, width: '170px' }}>
          <DateMenu />
        </Grid>
        <Grid item component={Box}>
          <DatePicker onClose={onClose} />
        </Grid>
      </Grid>
    </DateProvider>
  );
};
