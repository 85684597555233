import { useMemo } from 'react';

export const PERSISTENCE_FILTERS_DATABASE = 'PersistenceTalentFilters';

export class IndexedDb {
  private readonly database: string;
  private readonly objectStore: string;
  private readonly version: number;

  constructor(database: string, version = 1) {
    this.database = database;
    this.objectStore = database;
    this.version = version;
  }

  public get(key: IDBValidKey) {
    return this.transactionally((objectStore, resolve, reject) => {
      const request = objectStore.get(key);
      request.onsuccess = (e: any) => resolve(e.target.result);
      request.onerror = reject;
    }, 'readonly');
  }

  public put(key: IDBValidKey, value: any) {
    return this.transactionally((objectStore, resolve, reject) => {
      const request = objectStore.put(value, key);
      request.onsuccess = resolve;
      request.onerror = reject;
    }, 'readwrite');
  }

  public delete(key: IDBValidKey) {
    return this.transactionally((objectStore, resolve, reject) => {
      const request = objectStore.delete(key);
      request.onsuccess = resolve;
      request.onerror = reject;
    }, 'readwrite');
  }

  public getVersion() {
    return this.version;
  }

  private transactionally(
    callback: (objectStore: IDBObjectStore, resolve?: any, reject?: any) => any,
    mode: IDBTransactionMode
  ) {
    return new Promise((resolve, reject) => {
      this.getConnection(this.database, this.objectStore, this.version)
        .then(databaseConnection => {
          this.validateBeforeTransaction(databaseConnection, this.objectStore, reject);
          const transaction = databaseConnection.transaction(this.objectStore, mode);
          transaction.onerror = reject;
          const store = transaction.objectStore(this.objectStore);
          callback(store, resolve, reject);
          databaseConnection.close();
        })
        .catch(reject);
    });
  }

  private getConnection(
    databaseName: string,
    objectStoreName: string,
    version: number
  ): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const idbInstance = window.indexedDB;

      if (!idbInstance) {
        reject('No IndexedDB access');
      }

      const request: IDBOpenDBRequest = idbInstance.open(databaseName, version);

      request.onsuccess = () => resolve(request.result);
      request.onerror = (e: any) => reject(e.target.error.name);

      request.onupgradeneeded = (e: any) => {
        const databaseConnection = e.target.result;

        if (!databaseConnection.objectStoreNames.contains(objectStoreName)) {
          databaseConnection.createObjectStore(objectStoreName);
        }

        databaseConnection.close();

        /*
        As we just upgraded our database, we have to close the current connection and open another one.
        Otherwise, an active `versionchange` transaction might still be open and get/put/delete operations
        using the same connection can fail on the new transaction creation due to this condition:
        https://chromium.googlesource.com/chromium/blink/+/4de0246eaa03f94b1b63ae958f1abca0bfd6cc3b/Source/modules/indexeddb/IDBDatabase.cpp#271
        */
        const request: IDBOpenDBRequest = idbInstance.open(databaseName, version);

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = (e: any) => reject(e.target.error.name);

        request.onupgradeneeded = () =>
          reject(
            `
          An unexpected OnUpgradeNeeded event has been received,
          this should never happen as we just upgraded our database to the new version
          `
          );
      };
    });
  }

  private validateBeforeTransaction(db: IDBDatabase, storeName: string, reject: any) {
    if (!db) {
      reject('A connection to the IndexDB database has not been opened yet');
    }

    if (!db.objectStoreNames.contains(storeName)) {
      reject(`The object store with name ${storeName} has not been found in database ${db.name}`);
    }
  }
}

export function useIndexedDb(databaseName: string, version = 1): IndexedDb {
  return useMemo(() => new IndexedDb(databaseName, version), [databaseName, version]);
}
