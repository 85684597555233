import React, { FC } from 'react';
import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';

import { EmptyNotes } from 'src/components/Talent/components/TalentProfile/components/Notes/components/EmptyNotes/EmptyNotes';
import { NewNote } from 'src/components/Talent/components/TalentProfile/components/Notes/components/NewNote/NewNote';
import { Note as NoteComponent } from 'src/components/Talent/components/TalentProfile/components/Notes/components/Note/Note';
import { Note } from 'src/types/domain';

interface NotesProps {
  notes: Note[];
}

export const Notes: FC<NotesProps> = ({ notes }) => (
  <>
    <Stack direction='column' sx={{ height: '100%', width: '100%' }}>
      <NewNote />
      {notes.length === 0 && <EmptyNotes />}
      {notes.length > 0 && (
        <Divider sx={{ marginLeft: '24px', marginRight: '24px' }} variant='fullWidth' />
      )}
      {notes.length > 0 && (
        <Stack
          direction='column'
          sx={{ overflow: 'scroll', padding: '0px 16px 0px 24px', width: '100%' }}
        >
          {notes.map(note => (
            <NoteComponent key={uuidv4()} note={note} />
          ))}
        </Stack>
      )}
    </Stack>
  </>
);
