import { createElement, FC } from 'react';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as ChatQualified } from 'src/assets/ChatQualified.svg';
import { ReactComponent as ChatUnknown } from 'src/assets/ChatUnknown.svg';
import { ReactComponent as ChatUnqualified } from 'src/assets/ChatUnqualified.svg';
import { ReactComponent as NoteBadge } from 'src/assets/NoteBadge.svg';
import { ReactComponent as ChatStarted } from 'src/assets/StartedChat.svg';
import { ChatStatus, RecruiterInfo, Score } from 'src/types/domain';
import { formatDateTypeO } from 'src/utils/functions/dateFormatters';

interface OnHoldProps {
  state: boolean;
  since?: string | null;
  by?: RecruiterInfo | null;
}

export interface BadgesProps {
  score: Score;
  chatStatus: ChatStatus;
  onHold: OnHoldProps | null;
  hasNotes: boolean;
}

const onHoldWithTooltip = ({ by, since }: OnHoldProps) => (
  <Tooltip
    title={
      since
        ? `On hold since ${formatDateTypeO(new Date(since))} by ${by?.firstName} ${by?.lastName}`
        : 'On hold'
    }
    arrow
    placement='top'
  >
    <div style={{ display: 'flex' }}>
      {' '}
      <PauseCircleOutlineIcon color={'disabled'} />
    </div>
  </Tooltip>
);

const chatBadge = (chatStatus: ChatStatus, score: Score) => {
  if (!chatStatus || chatStatus === ChatStatus.NOT_STARTED) {
    return null;
  }

  const chatIcon = () => {
    if (chatStatus === ChatStatus.COMPLETED) {
      if (score === Score.QUALIFIED) {
        return { icon: ChatQualified, tooltip: 'Qualified' };
      } else if (score === Score.UNQUALIFIED) {
        return { icon: ChatUnqualified, tooltip: 'Unqualified' };
      }

      return { icon: ChatUnknown, tooltip: 'Unknown' };
    }

    return { icon: ChatStarted, tooltip: 'Chat started' };
  };

  return (
    <Tooltip title={chatIcon()?.tooltip} arrow placement='top'>
      <div style={{ display: 'flex' }}>{createElement(chatIcon().icon)}</div>
    </Tooltip>
  );
};

const noteBadge = () => (
  <Tooltip title={'Has note(s)'} arrow placement='top'>
    <div style={{ display: 'flex' }}>
      <NoteBadge />
    </div>
  </Tooltip>
);

export const Badges: FC<BadgesProps> = ({ chatStatus, score, onHold, hasNotes }) => (
  <>
    {chatBadge(chatStatus, score)}
    {hasNotes && noteBadge()}
    {onHold?.state && onHoldWithTooltip(onHold)}
  </>
);
