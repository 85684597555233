const toolTipBackgroundColor = 'rgba(0, 0, 0, 0.75)';

export const MuiTooltip = {
  styleOverrides: {
    arrow: {
      color: toolTipBackgroundColor,
    },
    tooltip: {
      backgroundColor: toolTipBackgroundColor,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.563rem',
      maxWidth: 'none',
      padding: '0.625rem',
    },
  },
};
