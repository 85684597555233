import { FunctionComponent, memo } from 'react';

import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { Talent } from 'src/types/domain';

import { Badges } from './components/Badges/Badges';
import { Status } from './components/Status/Status';
import { ItemDivider, ItemWrapper } from './styles';

import classes from './Item.module.css';

export interface ItemProps {
  talent: Talent;
  isSelectedItem: boolean;
  isLast: boolean;
}

const ItemComponent: FunctionComponent<ItemProps> = ({ talent, isSelectedItem, isLast }) => (
  <ItemWrapper isLast={isLast} isSelectedItem={isSelectedItem}>
    <div className={classes.item_data_container}>
      <div className={classes.item_text_container}>
        <Text
          data-testid='text-name'
          text={talent.firstName + ' ' + talent.lastName}
          color={'var(--dark-gray)'}
          style={{
            fontWeight: isSelectedItem ? '600' : '400',
            letterSpacing: !isSelectedItem ? '0.01em' : '0.0rem',
            paddingRight: '24px',
          }}
          typographyType={TextTypographyType.SH_2}
        />
        {talent.currentEmployer?.title && (
          <Text
            data-testid='text-current-employer'
            text={talent.currentEmployer.title}
            color={'var(--gray-7)'}
            typographyType={TextTypographyType.BODY_2}
            style={{ paddingRight: '24px' }}
          />
        )}
      </div>
      <div className={classes.item_info_container}>
        <Badges
          data-testid='badges'
          chatStatus={talent.chatStatus}
          score={talent.score}
          hasNotes={talent.hasNotes}
          onHold={{
            by: talent.onHoldBy,
            since: talent.onHoldSince,
            state: talent.onHold,
          }}
        />
        <Status
          status={talent.currentStatus?.status?.status}
          ts={talent.currentStatus?.status?.ts}
        />
      </div>
    </div>
    <ItemDivider isSelectedItem={isSelectedItem} />
  </ItemWrapper>
);

export const Item = memo(ItemComponent);
