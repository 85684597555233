import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { extendedGraphqlApiWithGetCandidate } from 'src/store/api/graphql/talents/getCandidate';
import {
  extendedGraphqlApiWithGetTalents,
  GetTalentsVariables,
} from 'src/store/api/graphql/talents/getTalents';
import { updateTalentList } from 'src/store/api/graphql/talents/utils/updateTalentList';
import { reset } from 'src/store/slices/talentNotes/talentNotes';
import { RootState } from 'src/store/store';
import { parseSearchParamsFilter } from 'src/utils/functions/parseSearchParamsFilter';

export const pessimisticNewNote = (
  variables: { sqlId: number; opportunityId: string; message: string },
  {
    dispatch,
    queryFulfilled,
    state,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    queryFulfilled: Promise<any>;
    state: RootState;
  }
) => {
  queryFulfilled
    .then(data => {
      const jobId = state.routerParameters.jobId;

      const searchParams = new URLSearchParams(state.router.location?.search);
      const endpointSelectParams: GetTalentsVariables = parseSearchParamsFilter(
        jobId,
        searchParams
      );

      const talentsResponse =
        extendedGraphqlApiWithGetTalents.endpoints.getTalents.select(endpointSelectParams)(
          state
        ).data;

      const indexOfOldItem = talentsResponse?.items.findIndex(item => item.id === variables.sqlId);
      const newNote = data.data.data.addNote;

      dispatch(reset());

      dispatch(
        extendedGraphqlApiWithGetCandidate.util.updateQueryData(
          'getCandidate',
          { id: variables.sqlId },
          draft => {
            const updatedCandidate = { ...draft };

            updatedCandidate.notes = [newNote, ...updatedCandidate.notes];

            return updatedCandidate;
          }
        )
      );

      updateTalentList({
        dispatch,
        hasNotes: true,
        indexOfOldItem,
        selectParams: endpointSelectParams,
      });
    })
    .catch(() => {
      //no reason to handle this since this is a pessimistic update
    });
};
