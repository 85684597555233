import { createElement, FC } from 'react';

import {
  Date,
  DateProps,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/components/Date/Date';
import {
  FilterProperty,
  FilterTypes,
} from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';

import { Binary, BinaryProps } from './components/Binary/Binary';

interface SelectionProps {
  value: FilterProperty;
  onClose: () => void;
}

const filterMapper: Record<FilterTypes, FC<BinaryProps | DateProps>> = {
  [FilterTypes.BOOLEAN]: Binary,
  [FilterTypes.DATE]: Date,
};

export const Selection: FC<SelectionProps> = ({ value, onClose }) =>
  createElement(filterMapper[value.type], { onClose, value });
