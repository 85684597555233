import { useEffect, useMemo, useState } from 'react';
import BusinessOutlined from '@mui/icons-material/BusinessOutlined';
import { titleCase } from 'title-case';

import { bakedDomains } from 'src/components/Talent/components/TalentProfile/components/Details/utils/bakedDomains';
import { Text } from 'src/shared/components';
import { TextTypographyType } from 'src/shared/components/api';
import { WorkExperience } from 'src/types/domain';

import { ImageWithFallback } from '../../../../../ImageWithFallback/ImageWithFallback';

import { getWorkDuration } from './utils/getWorkDuration';

import classes from './Item.module.css';

export interface ItemProps {
  item: WorkExperience;
}

export const Item = ({ item }: ItemProps): JSX.Element => {
  const [imageSource, setImageSource] = useState<string | undefined>('');

  useEffect(() => {
    if (item.company && bakedDomains[item.company]) {
      setImageSource(`//logo.clearbit.com/${bakedDomains[item.company]}?size=38`);
    }
  }, [item.company]);

  const workDuration = useMemo(
    () =>
      getWorkDuration({
        endYearMonth: item.end,
        isCurrentEmployer: item.isCurrent,
        startYearMonth: item.start,
      }),
    [item.start, item.end, item.isCurrent]
  );

  return (
    <div className={classes.container}>
      <div className={classes.icon_container}>
        <ImageWithFallback
          imageSource={imageSource}
          fallbackIcon={<BusinessOutlined fontSize='small' />}
        />
      </div>
      <div className={classes.text_container}>
        <Text
          typographyType={TextTypographyType.BODY_2}
          color={'var(--seal-blue)'}
          text={titleCase(item.company)}
        />
        <Text
          typographyType={TextTypographyType.BODY_6}
          color={'var(--seal-blue)'}
          text={titleCase(item.title)}
        />
        <Text
          typographyType={TextTypographyType.BODY_6}
          color={'var(--seal-blue)'}
          text={workDuration}
        />
      </div>
    </div>
  );
};
