export const searchParamsToObject = (searchParams: URLSearchParams) => {
  const searchParamsAsObject: Record<string, string[]> = {};

  for (const key of searchParams.keys()) {
    if (searchParams.getAll(key).length > 1) {
      searchParamsAsObject[key] = searchParams.getAll(key);
    } else {
      const data = searchParams.get(key);
      searchParamsAsObject[key] = data ? [data] : [];
    }
  }

  return searchParamsAsObject;
};
