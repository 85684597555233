export const TalentStatusesCurrentlyUsed = [
  'EVALUATED',
  'OTHER_ADVANCED',
  'OTHER_REJECTED',
  'CHAT_INCOMPLETE',
  'CHAT_COMPLETED',
  'SUBMITTED_TO_CLIENT',
  'UNQUALIFIED',
  'INTERVIEW_TO_BE_SCHEDULED',
  'REJECTED_BY_CLIENT',
] as const;

export const AdvanceReasons = [
  'INDUSTRY_EXPERIENCE',
  'FUNCTIONAL_EXPERTISE',
  'EDUCATIONAL_BACKGROUND',
  'OTHER',
] as const;

export const RejectReasons = [
  'WRONG_LOCATION',
  'COMPENSATION_MISMATCH',
  'CRIMINAL_HISTORY',
  'INCOMPLETE_PROFILE',
  'JOB_HOPPER',
  'MISSING_CERTIFICATIONS',
  'MISSING_RELEVANT_EXPERIENCE',
  'NOT_AUTHORIZED_TO_WORK',
  'TOO_SENIOR',
  'PREVIOUSLY_ENCOUNTERED',
  'SHIFT_MISMATCH',
  'TOO_JUNIOR',
  'UNABLE_TO_CONTACT',
] as const;

export type TalentStatusCurrentlyUsed = typeof TalentStatusesCurrentlyUsed[number];
export type AdvanceReason = typeof AdvanceReasons[number];
export type RejectReason = typeof RejectReasons[number];
export type Reason = AdvanceReason | RejectReason;

export type RecruiterFeedback = {
  notes: string;
  rejectionReason: RejectReason | 'OTHER' | null;
  advancementReason: AdvanceReason | null;
};

export enum RejectReasonLabel {
  PREVIOUSLY_ENCOUNTERED = 'Previously encountered',
  MISSING_CERTIFICATIONS = 'Lacks req. certification, education, license',
  TOO_JUNIOR = 'Too junior/doesn’t meet age req.',
  MISSING_RELEVANT_EXPERIENCE = 'Lacks required experience',
  COMPENSATION_MISMATCH = 'Compensation mismatch',
  TOO_SENIOR = 'Over qualified',
  UNABLE_TO_CONTACT = 'Unable to contact',
  NOT_AUTHORIZED_TO_WORK = 'No work authorization',
  WRONG_LOCATION = 'Applied to wrong location',
  INCOMPLETE_PROFILE = 'Incomplete profile',
  CRIMINAL_HISTORY = 'Criminal history',
  JOB_HOPPER = 'Job hopper',
  SHIFT_MISMATCH = 'Shift mismatch',
  // old reasons texts
  INSUFFICIENT_EDUCATION = 'Insufficient education',
  JOB_TENURE_CONCERNS = 'Job tenure concerns',
  JOB_TYPE_MISMATCH = 'Job type mismatch',
  NO_INDUSTRY_EXPERIENCE = 'No industry experience',
  ROLE_CLOSED = 'Role closed',
  ROLE_ON_HOLD = 'Role on hold',
  ROLE_FILLED = 'Role filled',
}

export type StatusRecord<T> = {
  id: number;
  status: T;
};

export type TalentStatusRecord = {
  ts: number | null;
  status: TalentStatusCurrentlyUsed;
};

export interface School {
  location: string;
  name: string;
  type: string;
}
export interface Education {
  major: string;
  source: string;
  graduation: string;
  gpa: string;
  degree: string;
  school: School;
}

export interface WorkExperience {
  neoId: string;
  title: string;
  start: string;
  end: string;
  company: string;
  description: string;
  isCurrent: boolean;
}

export enum ChatStatus {
  STARTED = 'STARTED',
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
}

export interface ChatSummary {
  neoId: string;
  type: string;
  name: string;
  value: string | null;
  shortLabel: string;
  questionsTabSection: QuestionLabels | null;
  values: string[] | null;
  knockout: boolean | null;
}

export interface RecruiterActionResult {
  actionDate: string;
  recruiterInfo: RecruiterInfo;
}

export interface RecruiterInfo {
  firstName?: string;
  lastName?: string;
}

export interface Status {
  created: string;
  recruiterInfo?: RecruiterInfo;
  status?: TalentStatusCurrentlyUsed;
}

export interface Note {
  recruiterInfo: {
    firstName: string;
    lastName: string;
  };
  message: string;
  created: string;
}

export interface Candidate {
  id: number;
  chatStatus: ChatStatus;
  neoId: string;
  onHold: boolean;
  onHoldSince: string | null;
  onHoldBy: RecruiterInfo | null;
  feedback: RecruiterFeedback;
  initialStatus: Status;
  statuses: Status[];
  notes: Note[];
  zohoInfo: {
    resumeLink: string | null;
    summary: string | null;
  };
  chatSummaries: ChatSummary[];
  job: {
    hasChat: boolean;
  };
  person: {
    name: {
      firstName: string;
      lastName: string;
    };
    defaultProfile: {
      network: string;
      url: string;
    } | null;
    primaryPhone: string | null;
    address: { address: string } | null;
    currentWorkExperience: WorkExperience | null;
    workExperience: WorkExperience[];
    education: Education[];
    email: string | null;
  };
}

export interface Employment {
  title: 'string';
}

export enum Score {
  QUALIFIED = 'QUALIFIED',
  UNQUALIFIED = 'UNQUALIFIED',
  UNKNOWN = 'UNKNOWN',
}

export interface Talent {
  id: number;
  neoId: string;
  firstName: string;
  lastName: string;
  chatStatus: ChatStatus;
  score: Score;
  currentEmployer: Employment | null;
  hasNotes: boolean;
  onHold: boolean;
  onHoldSince: string | null;
  onHoldBy: RecruiterInfo | null;
  currentStatus: {
    id: number | null;
    status: TalentStatusRecord;
  };
}

export interface DateRange {
  startDate: number; // eposch millis
  endDate: number; // eposch millis
}

export enum QuestionLabels {
  KNOCK_OUTS = 'Must-haves',
  NICE_TO_HAVE = 'Nice-to-haves',
  ADDITIONAL_INFO = 'Additional info',
}

export interface ResumeSammary {
  summary: string;
}
