import { FC } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';

import { blue60 } from 'src/shared/components/theme/mui/colors/colors';

interface ItemProps {
  label: string;
  filterValue: string;
  isThisActive: boolean;
  onClick: (filterValue: string) => void;
}

export const Item: FC<ItemProps> = ({ label, filterValue, isThisActive, onClick }) => (
  <MenuItem
    key={label}
    divider={true}
    onClick={() => {
      onClick(filterValue);
    }}
  >
    <FormControlLabel
      onClick={e => {
        e.preventDefault();
      }}
      control={
        <Checkbox
          onClick={e => {
            e.preventDefault();
          }}
          size='medium'
          checked={isThisActive}
          sx={{ '&.Mui-checked': { color: blue60 } }}
        />
      }
      label={label}
    />
  </MenuItem>
);
