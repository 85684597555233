import styled from 'styled-components';

export const TalentBasicInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 50%;
  height: 40px;
`;

export const WorkHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const TalentInfoSectionSeparator = styled.div`
  align-self: center;

  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 18px;

  background: var(--gray);
`;

export const DetailsColumn = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  gap: 15px;
`;
