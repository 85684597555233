import { createSlice } from '@reduxjs/toolkit';

import { NotificationsState } from 'src/store/types/types';

import { addNotificationAction } from './actions/addNotification';
import { removeNotificationAction } from './actions/removeNotification';
import { resetAction } from './actions/reset';

export const notificationsInitialState: NotificationsState = {
  notifications: [],
};

export const notifications = createSlice({
  initialState: notificationsInitialState,
  name: 'notifications',
  reducers: {
    addNotification: addNotificationAction,
    removeNotification: removeNotificationAction,
    reset: resetAction,
  },
});

export const { reset, removeNotification, addNotification } = notifications.actions;

export default notifications.reducer;
