import { FC, useState } from 'react';
import Menu from '@mui/material/Menu';

import { Main } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Main/Main';
import { Selection } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/components/Selection/Selection';
import { FilterProperty } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/types/types';
import { filteredFilterMenuConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/utils/filterMenuConfiguration';
import { useGetArchivedJobsQuery } from 'src/store/api/graphql/jobs/getArchivedJobs';
import { useGetLiveJobsQuery } from 'src/store/api/graphql/jobs/getLiveJobs';
import { useAppSelector } from 'src/store/hooks/hooks';

interface FilterMenuProps {
  anchorPoint: EventTarget & HTMLButtonElement;
  onClose: () => void;
}

export const FilterMenu: FC<FilterMenuProps> = ({ onClose, anchorPoint }) => {
  const open = Boolean(anchorPoint);
  const [value, setValue] = useState<FilterProperty | null>(null);

  const selectValue = (e: FilterProperty) => {
    setValue(e);
  };

  const selectedJobId = useAppSelector(state => state.routerParameters.jobId);

  const { job: liveJob } = useGetLiveJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const { job: archivedJob } = useGetArchivedJobsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      job: data?.find(job => selectedJobId && job.id === +selectedJobId),
    }),
  });
  const selectedJob = liveJob || archivedJob;

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorPoint}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: '230px',
          transform: 'translateX(-2px) translateY(8px)',
        },
      }}
    >
      {!value ? (
        <Main
          config={filteredFilterMenuConfiguration(selectedJob?.hasChat)}
          selectValue={selectValue}
        />
      ) : (
        <Selection value={value} onClose={onClose} />
      )}
    </Menu>
  );
};
