import styled from 'styled-components';

export const TalentInfoSectionSeparator = styled.div`
  align-self: center;

  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 18px;

  background: var(--gray);
`;

export const StyledDownloadButton = styled.button`
  cursor: pointer;

  gap: 10px;

  max-width: 182px;
  max-height: 30px;
  padding: 3px 10px;
  padding-right: 12.5px;

  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  color: var(--blumine);

  background: transparent;
  border: 1px solid var(--blumine);
  border-radius: 5px;

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const ResumeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
