import { Chip } from 'src/components/Talent/components/TalentList/components/Header/components/Chips/types/types';
import { filterMenuConfiguration } from 'src/components/Talent/components/TalentList/components/Header/components/Filter/components/FilterMenu/utils/filterMenuConfiguration';

export const searchParamsToChipConfig = (searchParams: URLSearchParams): Chip[] =>
  filterMenuConfiguration.values.reduce((prev, value): Chip[] => {
    const values = searchParams.getAll(value.header.filterLabel);

    if (values.length === 0) {
      return prev;
    }

    prev.push({
      config: value,
      label: value.header.label,
      values,
    });
    return prev;
  }, [] as Chip[]);
