import classes from '../Status.module.css';

const inReview = {
  color: classes.blue,
  text: 'In review',
  title: 'In review',
};
const advanced = {
  color: classes.green,
  text: 'Advanced',
  title: 'Advanced',
};
const rejected = {
  color: classes.red,
  text: 'Rejected',
  title: 'Rejected',
};
const chatIncomplete = {
  color: classes.gray,
  text: 'Chat started',
  title: 'Chat started',
};
export const defaultStatus = {
  color: classes.orange,
  text: 'Other',
  title: 'Unknown',
};

export const statusMapper = {
  CHAT_COMPLETED: defaultStatus,
  CHAT_INCOMPLETE: chatIncomplete,
  EVALUATED: inReview,
  INTERVIEW_TO_BE_SCHEDULED: advanced,
  OTHER_ADVANCED: advanced,
  OTHER_REJECTED: rejected,
  REJECTED_BY_CLIENT: rejected,
  SUBMITTED_TO_CLIENT: inReview,
  UNQUALIFIED: defaultStatus,
};
