import { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';

export const resetRange = (
  dates: string[],
  setRange: Dispatch<SetStateAction<DateRange | undefined>>
) => {
  try {
    const startDate = new Date(+dates[0]);
    const endDate = new Date(+dates[1]);

    if (!startDate.getTime() || !endDate.getTime()) {
      throw new Error('invalid dates');
    }

    setRange({
      from: startDate,
      to: endDate,
    });
  } catch (_) {
    setRange({
      from: undefined,
      to: undefined,
    });
  }
};
